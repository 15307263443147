import React      from 'react';
import PropTypes  from 'prop-types';
import Dialog     from '@material-ui/core/Dialog';
import Grid       from '@material-ui/core/Grid';
import Divider    from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import IconButton from 'components/atoms/IconButton';

import 'styles/components/atoms/popups.css';

function Popups ({ children, open, title, titleAlign, onClose, closeButton }) {

	const closeButtonIcon = closeButton && 
		<IconButton 
			icon      = 'Close'
			size      = { 35 }
			color     = 'secondary'
			className = 'popups-medium-close'
			onClick   = { onClose }
		/>;

	return (
		<Dialog open = { open } maxWidth = 'sm' fullWidth className = 'popups-medium'>	
			<Grid className = 'popups-title-container' >
				<Typography variant = 'h3' align = { titleAlign }> {title} </Typography>
				{closeButtonIcon}
			</Grid>
			<Divider className = 'popups-divider'/>
			<Grid className = 'popups-main-container'>
				{children}
			</Grid>
		</Dialog>
	);
}

Popups.defaultProps = {
	titleAlign  : 'left',
	size        : 'medium',
	closeButton : false,
};

Popups.propTypes = {
	open        : PropTypes.bool.isRequired,
	title       : PropTypes.string.isRequired,
	onClose     : PropTypes.func,
	size        : PropTypes.oneOf (['small', 'medium']),
	closeButton : PropTypes.bool,
	titleAlign  : PropTypes.oneOf(['left', 'center', 'right'])
};

export default Popups;
