import { Container, transports, format } from 'winston';

const { simple, colorize, combine, timestamp, label, printf } = format;

const myFormat = printf(({ level, message, label, timestamp, ...metaData }) => {
	let args = metaData[Symbol.for('splat')];
	let stringify = Array.isArray (args) && args.map (item => `${JSON.stringify (item)}`).join ('\n');

	return `${level}: [${label}] ${JSON.stringify(message)} ${stringify} @ ${timestamp}`;
});

export const logContainer = (file) => {
	const container = new Container();

	container.add (file, {
		level  : process.env.NODE_ENV === 'development' ? 'debug' : 'info',
		format : combine(
			label({ label: file }),
			timestamp(),
			colorize(),
			simple(),
			myFormat
		),
		transports  : [
			new transports.Console(),
		],
	});

	return container.get (file);
};
