import React              from 'react';
import { useTheme }       from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid               from '@material-ui/core/Grid';

import Button             from 'components/atoms/Button';
import LMBiampLogo        from 'assets/icons/light/biamp-logo.png';
import DMBiampLogo        from 'assets/icons/dark/biamp-logo.png';
import LMLogout           from 'assets/icons/light/logout.svg';
import DMLogout           from 'assets/icons/dark/logout.svg';
import LMDownload         from 'assets/icons/light/download.svg';
import DMDownload         from 'assets/icons/dark/download.svg';

import 'styles/components/molecules/eventLandingTopBar.css';

function EventLandingTopBar ({ onLeave, isPast }) {

	const theme = useTheme ().palette.type;
	const { t } = useTranslation ();

	const logo     = theme === 'light' ? LMBiampLogo : DMBiampLogo;
	const logout   = theme === 'light' ? LMLogout : DMLogout;
	const download = theme === 'light' ? LMDownload : DMDownload;

	const sideButtons = () => {
		if (!isPast) {
			return (
				<Button size = 'large' color = 'inherit' variant = 'contained' className = 'p-l' onClick = { onLeave }> 
					<img src = { logout } className = 'mr-s'/> 
					{t('button:action.leave_event')}
				</Button>
			);
		}
		return (
			<>
				<Button size = 'large' color = 'secondary' variant = 'outlined' className = 'ml-xl mr-xl' onClick = { onLeave }> 
					{t('button:action.back_to_event')}
				</Button>
				<Button size = 'large' color = 'primary' variant = 'outlined' style = {{display : 'none'}}> 
					<img src = { download } className = 'mr-s'/> 
					{t('button:action.download_playlist')}
				</Button>
			</>
		);
	};

	return (
		<Grid className = 'eventLandingTopBar-container' container alignItems = 'center' id = "eventLandingTopBar">
			<Grid item xs = { 6 }>
				<img src = { logo } width = { 82 } height = { 32 }/>
			</Grid>
			<Grid item xs = { 6 } container justify = 'flex-end'>
				{sideButtons ()}	
			</Grid>
		</Grid>
	);
}

export default EventLandingTopBar;
