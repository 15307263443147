import React               from 'react';
import { useTranslation }  from 'react-i18next';
import Grid                from '@material-ui/core/Grid';
import Typography          from '@material-ui/core/Typography';

import Button              from 'components/atoms/Button';

import 'styles/components/molecules/pollSubmitModal.css';

function PollSubmitModal ({ title, message, onClose }) {

	const { t } = useTranslation ();

	return (
		<Grid className = 'sideAlert-backdrop'>
			<Grid className = 'alert-container p-xxl sideAlert'>
				<Grid className = 'mt-xl' container justify = 'center' alignItems = 'baseline'>
					<span className = 'poll-icon-big first'>
						<span className = 'poll-icon-col first'>
						</span>
					</span>
					<span className = 'poll-icon-big second'>
						<span className = 'poll-icon-col second'>
						</span>
					</span>
					<span className = 'poll-icon-big third'>
						<span className = 'poll-icon-col third'>
						</span>
					</span>
				</Grid>
				 <Typography variant = 'h3' align = 'center' className = 'mt-xl'> { title } </Typography>
				 <Typography variant = 'h2' color = 'secondary' className = 'mt-m' align = 'center'> {message} </Typography>
				 <Grid container justify = 'center' className = 'mt-xxl'>
					 <Button variant = 'outlined' color = 'secondary' onClick = { onClose } className = 'pollSubmitModal-close-btn'> {t('button:action.close')} </Button>
				 </Grid>
			</Grid>
		</Grid>
	);
}

export default PollSubmitModal;
