import { createSlice } from '@reduxjs/toolkit';
import has             from 'lodash/has';
import findIndex       from 'lodash/findIndex';

const initialState = {
	/*active poll*/
	poll : {
		answers           : [],
		results           : [],
		options           : [],
		createdTS         : '',
		hash              : '',
		pollType          : '',  /*multiple_answer, mcq, yesno*/
		publishedTS       : '',
		publisher         : '',
		question          : '',
		state             : '',  /*done, published*/
		viewType          : '',  /*horizontal-bar or pie*/
	},

	submitted         : '',    /*if poll is answered or not*/
	resultShared      : false, /*if poll result is shared or not*/
	previousPolls     : [],    /*List of previous completed polls*/ 
};

const changeResultKeys = (results) => {
	return results?.map (result => ({
		index : result.index,
		vcId  : result.vc_id,
		ts    : result.ts
	})) || [];
};

export const changePollKeys = (polls) => {
	return polls?.map (poll => ({
		answers           : poll.answers,
		createdTS         : poll.create_ts,
		endTs             : poll.end_ts,
		hash              : poll.hash,
		options           : poll.options,
		pollType          : poll.pollType,
		publishedTS       : poll.publish_ts,
		publisher         : poll.publisher,
		question          : poll.question,
		results           : changeResultKeys (poll.results),
		state             : poll.state,
		viewType          : poll.view_type
	})) || [];
};

export const showPollResult = (pollStore, pollSettings) => {
	/*This fun will decide when to show the results to PA*/
	/*
		  1. Don't show the results, when the resultShared is false.
		  2. Don't show the results, if the showResultsOn settings is set to 'DISPLAY' only.
		  3. Don't show the results, if user has not submitted the answer and showResPriorSub is set to false.
	 * */
	if (!pollStore || !pollSettings) {
		return;
	}
	if (!pollStore.resultShared) {
		return false;
	}
	if (pollSettings.showResultsOn === 'DISPLAY') {
		return false;
	}
	if (pollStore.poll.state !== 'done' && !pollStore.submitted && !pollSettings.showResPriorSub) {
		return false;
	}
	return true;
};

const slice = createSlice ({
	name : 'polls',
	initialState,
	reducers : {
		addNewPollingData (state, action) {
			let poll =  action.payload;
			if (!poll) {
				return;
			}
			state.poll  = changePollKeys ([poll])[0];
		},

		/*for updating any key of the global poll state*/
		updatePollState (state, action) {
			if (!action.payload) {
				return;
			}
			let keys = Object.keys (action.payload);
			keys.forEach (key => {
				if (!has (state, key)) { //don't update those values whose keys are not present in state
					return;
				}
				state[key] = action.payload[key];
			});
		},

		/*for updating any key of the active poll state*/
		updateActivePollState (state, action) {
			if (!action.payload) {
				return;
			}
			let keys = Object.keys (action.payload);
			keys.forEach (key => {
				if (!has (state.poll, key)) {
					return;
				}	
				state.poll[key] = action.payload[key];
			});
		},

		updatePreviousPolls (state, action) {
			let pollList = action.payload;
			if (!Array.isArray (pollList) || !pollList.length) {
				return;
			}
			pollList = changePollKeys (pollList.reverse());
			state.previousPolls = [...pollList, ...state.previousPolls];
		},

		updatePollResult (state, action) {
			let result = action.payload;
			if (!result || !result.hash || !result.answer) {
				return;
			}
			if (state.poll.hash !== result.hash) {
				return;
			}
			let resultAnswer = changeResultKeys (result.answer);
			state.poll.results = [...state.poll.results, ...resultAnswer];
		},

		/*this fun will handle share poll results and unshare poll results*/
		handlePollShareResult (state, action) {
			if (!action.payload || !action.payload.hash) {
				return;
			}
			/*result shared or unshared for an active poll*/
			if (state.poll.hash === action.payload.hash && state.poll.state === 'published') {
				state.resultShared = action.payload.share_result;
				return;
			}
			/*result shared for an ended poll*/	
			if (action.payload.active_poll && action.payload.share_result) {
				let poll = action.payload.active_poll;

				state.poll         = changePollKeys ([poll])[0];
				state.resultShared = true;
				return;
			}
			/*result unshared for an ended poll*/
			if (!action.payload.active_poll && !action.payload.share_result) {
				state.poll         = initialState.poll;
				state.resultShared = false;
				return;
			}
		},

		clearPollingData (state, action) {
			state.poll         = initialState.poll;
			state.resultShared = initialState.resultShared;
			let _index = findIndex(state.previousPolls, {hash : action.payload?.hash});
			if (_index < 0) {
				let _poll = changePollKeys ([action.payload])[0];
				state.previousPolls = [...state.previousPolls, _poll];
				return;
			}
			state.previousPolls.splice(_index, 1, action.payload);
		},

		clearPollingStore () {
			return initialState;
		}
	}
});

export const { 
	addNewPollingData,
	clearPollingData,
	updatePollState,
	updateActivePollState,
	updatePreviousPolls,
	updatePollResult,
	handlePollShareResult,
	clearPollingStore,
} = slice.actions;

export default slice.reducer;
