import React, { useState }          from 'react';
import { useTranslation }           from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Grid                         from '@material-ui/core/Grid';
import FormLabel                    from '@material-ui/core/FormLabel';
import Button                       from '@material-ui/core/Button';
import TextField                    from '@material-ui/core/TextField';

import Popups                       from 'components/atoms/Popups';
import Profile                      from 'components/molecules/Profile';
import { updateName, updateImage }  from 'redux/slices/login';
import { storageKey }               from 'common/config';
import localStore                   from 'libs/localStore';

const i18nNamespaces = ["login"];

function ChangeUserInfoModal ({ open, onClose }) {

	const dispatch       = useDispatch ();
	const loginStore     = useSelector (state => state.login);
	const { t }          = useTranslation (i18nNamespaces);

	const [name, setName]     = useState (loginStore.name);
	const [errors, setErrors] = useState ({ name : false });

	const handleTextField = (event) => {
		const { value } = event.currentTarget;
		setName (value);
		setErrors ({name : false});
	};

	const onSaveSettings = () => {
		if (!name || name.trim () === "") {
			setErrors ({ ...errors, name : true});
			return;
		}

		let profile = {
			id : localStore.get(storageKey.profile).id,  //uuid wont be changed, on saving profile
			name,
			image : loginStore.image,
		};

		localStore.set(storageKey.profile, profile);
		dispatch (updateName (name));
		onClose ();
	};

	const onCancel = () => {
		/*
		 *Incase user dont save profile in case of save settings, update the redux store with previous value i.e localStore
		 * */
		let profile =  localStore.get (storageKey.profile);
		onClose ();
		setName (profile.name);
		dispatch (updateImage (profile.image));
		setErrors ({name : false});
	};

	return (
		<Popups open = { open } title = { t('settings:modal.change_user_info') } titleAlign = 'center'>
			  <Grid className = 'login-image-grid'>
				<Profile />
			</Grid>
			<Grid className = 'mb-xxl'>
				<FormLabel required color = 'secondary'> {t('input.name_header')} </FormLabel>
				<Grid className = 'mt-s'>
					<TextField
						variant     = 'outlined'
						color       = 'primary'
						placeholder = { t('input.name') }
						onChange    = { handleTextField }
						fullWidth   = { true }
						value       = { name }
						name        = 'name'
						error       = { errors.name }
						inputProps  = {{
							maxLength : 256
						}}
					/>
				</Grid>
			</Grid>
			<Grid container justify = 'flex-end'>
				<Button variant = 'outlined' color = 'secondary' className = 'ml-m mr-m' onClick = { onCancel }> {t('button:action.cancel')} </Button>
				<Button variant = 'contained' color = 'primary' onClick = { onSaveSettings }> {t('button:action.save_changes')} </Button>
			</Grid>
		</Popups>
	);
}

export default ChangeUserInfoModal;
