import React , { useState, useEffect }           from 'react';
import PropTypes                                 from 'prop-types';
import { useSelector, useDispatch }              from 'react-redux';
import { useTranslation }                        from 'react-i18next';
import Grid                                      from '@material-ui/core/Grid';
import Tabs                                      from '@material-ui/core/Tabs';
import Tab                                       from '@material-ui/core/Tab';
import Typography                                from '@material-ui/core/Typography';
import Divider                                   from '@material-ui/core/Divider';

import PollCard                                  from 'components/molecules/PollCard';
import PollModal                                 from 'components/molecules/PollModal';
import PollSubmitModal                           from 'components/molecules/PollSubmitModal';
import Count                                     from 'components/atoms/Count';
import { updateActivePollState, updatePollState} from 'redux/slices/polling';
import { updatePollResult, showPollResult }      from 'redux/slices/polling';
import { clearPollingStore }                     from 'redux/slices/polling';
import { clearBadge }                            from 'redux/slices/common';
import useRequest                                from 'common/request';
import { logContainer }                          from 'libs/logger';

import 'styles/pages/polls.css';

const i18nNamespaces = ["polls"];

const log = logContainer ('pages/Polls');

function Polls ({isPast, pastPolls}) {  /*Used in case of past events*/

	const { t }         = useTranslation (i18nNamespaces);
	const dispatch      = useDispatch ();
	const request       = useRequest ();
	const pollStore     = useSelector (state => state.polling);
	const settingsStore = useSelector (state => state.settings);
	const loginStore    = useSelector (state => state.login);
	const userStore     = useSelector (state => state.user);

	const { poll, previousPolls } = pollStore;
	const pollSettings = settingsStore.polling;

	const [allPolls, setAllPolls]           = useState ([]);
	const [showPoll, setShowPoll]           = useState (null);
	const [currentTab, setCurrentTab  ]     = useState (0);
	const [submitAlert, setSubmitAlert]     = useState (false);
	const [submitLoading, setSubmitLoading] = useState (false);

	useEffect (() => {
		if (!showPoll) {
			return;
		}
		if (showPoll.hash === poll.hash) {
			setShowPoll (poll);
		}
	}, [poll]);

	useEffect (() => {
		if (isPast) {
			setAllPolls ([...pastPolls]);
			return;
		}
		if (!poll.state) {
			setAllPolls ([...pollStore.previousPolls]);
			return;
		}
		setAllPolls ([poll, ...pollStore.previousPolls]);
		dispatch (clearBadge ('poll'));
	}, [pollStore]);

	useEffect (() => {
		if (location.state?.pastEvent) {
			return () => dispatch (clearPollingStore ());
		}
		dispatch (clearBadge ('poll'));
	}, []);

	const handleTabChange = (event, value) => {
		setCurrentTab (value);
	};

	const getTotalVotes = (poll) => {
		const totalResults = poll.results?.length || 0;
		const answers      = {};

		for (let i = 0; i < totalResults; i++) {

			const index = poll.results[i]['index'];

			if (!answers[index]) answers[index] = 0;
			answers[index] += 1;
		}
		return answers;
	};

	const getVotes = (_poll) => {
		const totalResults = _poll.results.length || 0;
		const answers      = getTotalVotes (_poll);
		let votes          = {};

		votes = _poll.options.reduce((percentage, c, i) => {

			percentage[i] = answers[i] ? Number((( answers[i] / totalResults ) * 100).toFixed(2)) : 0;
			return percentage;
		}, {});
		return votes;
	};

	const onPollSubmit = async (index) => {
		setSubmitLoading (true);
		let payload = {
			hash  : poll.hash,
			user  : loginStore.name,
			index,
		};
		let result;
		try {
			result = await request.submitPollAnswer (payload);
			log.info ({submitRes : result}, 'poll answer submitted');
		} 
		catch (err) {
			log.error ('Error sending poll Answer : ', err);
			setSubmitLoading (false);
			return;
		}
		dispatch (updatePollResult (result));
		dispatch (updatePollState ({submitted : true}));
		setSubmitLoading (false);
		handleSubmitAlert ();
	};

	const handleGraphChange = (view) => {
		if (showPoll) {
			let _showPoll =  {...showPoll, viewType : view};
			setShowPoll (_showPoll);
			return;
		}
		dispatch (updateActivePollState ({viewType : view}));
	};

	const handleShareResult = () => {
		return showPollResult (pollStore, pollSettings);
	};

	const handleSubmitAlert = () => {
		setSubmitAlert (!submitAlert);
	};

	const handleViewPoll = (pollHash) => {
		if (!pollHash) {
			setShowPoll (null);
			return;
		}
		let _poll = allPolls.find (poll => poll.hash === pollHash);
		if (!_poll) {
			return;
		}
		setShowPoll (_poll);
	};

	const renderPollModal = () => {
		if (!showPoll) {
			return;
		}
		let poll =  showPoll;
		return (
			<PollModal 
				pollType      = { poll.pollType }
				question      = { poll.question }
				options       = { poll.options }
				state         = { poll.state }
				isSubmitted   = { poll.state === 'done' ?  true : pollStore.submitted }
				votes         = { getVotes (poll) }
				results       = { poll.results }
				submitPoll    = { onPollSubmit }
				viewType      = { poll.viewType }
				resultShared  = { poll.state === 'done' ? true : handleShareResult () }
				onGraphChange = { handleGraphChange }
				currentUser   = { userStore.currentUser?.vcId }
				onClose       = { handleViewPoll }
				loading       = { submitLoading }
			/>
		);
	};

	const rendetTab = () => {
		switch (currentTab) {
			case 0 :
				return renderActiveTab ();
			case 1 :
				return renderClosedTab ();
			default :
				return;
		}
	};

	const renderActiveTab = () => {
		if (!poll.state) {
			return;
		}

		if (pollStore.submitted || poll.state === 'done') {
			return (
				<PollCard 
					question     = { poll.question }
					results      = { poll.results }
					state        = { pollStore.state }
					key          = { poll.hash }
					hash         = { poll.hash }
					onViewResult = { handleViewPoll }
					state        = { poll.state === 'done' ? 'closed' : 'live' }
					resultShared = { handleShareResult () }
				/>
			);
		}

		return (
			<PollModal 
				pollType      = { poll.pollType }
				question      = { poll.question }
				options       = { poll.options }
				state         = { poll.state }
				isSubmitted   = { poll.state === 'done' ?  true : pollStore.submitted }
				votes         = { getVotes (poll) }
				results       = { poll.results }
				submitPoll    = { onPollSubmit }
				viewType      = { poll.viewType }
				resultShared  = { poll.state === 'done' ? true : handleShareResult () }
				onGraphChange = { handleGraphChange }
				currentUser   = { userStore.currentUser?.vcId }
				onClose       = { handleViewPoll }
			/>
		);
	};

	const renderClosedTab = () => {
		return previousPolls.map (poll => (
			<PollCard 
				question     = { poll.question }
				results      = { poll.results }
				state        = { pollStore.state }
				key          = { poll.hash }
				hash         = { poll.hash }
				onViewResult = { handleViewPoll }
				state        = { poll.state === 'done' ? 'closed' : 'live' } 
				resultShared = { true }
			/>
		));
	};

	const pollSubmitAlert = submitAlert && 
		<PollSubmitModal 
			title   = { t('modal:title.submit_answer') }
			message = { !handleShareResult() ? t('modal:body.submit_answer_msg') : '' }
			onClose = { handleSubmitAlert }
		/>;

	if (isPast) {
		return (
			<Grid className = 'polls'>
				<Grid className = 'p-xxl' container>
					<Typography variant = 'h4'> {t('header.polls')} </Typography>
					<Count number = { pastPolls.length } variant = 'circular' color = 'secondary' className = 'ml-m mr-m'/>
				</Grid>
				<Divider />
				<Grid className = 'polls-container isPast'>
					{pastPolls.map (poll => (
						<PollCard 
							question     = { poll.question }
							results      = { poll.results }
							state        = { pollStore.state }
							key          = { poll.hash }
							hash         = { poll.hash }
							onViewResult = { handleViewPoll }
							state        = { poll.state === 'done' ? 'closed' : 'live' } 
							resultShared = { true }
						/>
					))}
				</Grid>
				{renderPollModal ()}
			</Grid>
		);
	}

	return (
		<Grid className = 'polls'>
			<Grid className = 'p-xxl' container>
				<Typography variant = 'h4'> {t('header.polls')} </Typography>
			</Grid>
			<Grid>
				<Tabs value = { currentTab } onChange = { handleTabChange } color = 'primary'>
					<Tab label={ t('body.active_counter', {counter : poll.state ? 1 : 0}) } />
					<Tab label={ t('body.closed_counter', {counter : previousPolls.length}) } />
				</Tabs>
			</Grid>
			<Grid className = 'polls-container'>
				{rendetTab ()}
			</Grid>
			{renderPollModal ()}
			{pollSubmitAlert}
		</Grid>
	);
}

Polls.propTypes = {
	isPast    : PropTypes.bool,
	pastPolls : PropTypes.array,
};

export default Polls;
