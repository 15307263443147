/*Note:--- 
 * this colors are same as defined in css varibles, 
 * this will be only used for defining colors for pallete of MUI, as it doesn't support css variables as of now.
 * To use colors anywhere else we will be using css variables
 * */

const colors = {

	white                 : '#FFFFFF',
	dodgerBlue            : '#3F8CFF',                  /*App main primary color for light and dark mode*/
	dodgerBlue20          : '#3F8CFF33',                /*Tints-20 of primary*/
	blueBlue              : '#1863D3',                  /*Main secondary color for light and dark mode*/
	black                 : '#1C1C1C',  
	greyBlue              : '#8191A7',                  /*For small subtext*/
	lightGreyBlue         : '#AFBAC9',                  /*For category text*/
	lightGreyBlue20       : '#AFBAC933',                /*Text of inactive buttons*/
	blueBlue5             : '#1863D30D',                /*Grey as circle for bg for buttons*/
	blueBlue60            : '#1863d399',
	lightPeriwinkle       : '#DFE3E9',                  /*Bg color for inactive buttons*/ 
	veryLightPink75       : '#CECECEBF',                /*Grey BG behind pop ups*/  
   
	/*Dark Mode*/
 
	dodgerBlue32          : '#3F8CFF52',                /*Tints-32 of primary*/
	white40               : '#FFFFFF66',                /*Grey color used for small subtext*/
	grey                  : '#868686',                  /*Grey color used for category text*/
	darkGrey              : '#686868',                  /*Dark grey For text of inactive buttons*/
	white10               : '#FFFFFF1A',                /*Grey as circle BG for buttons*/
	greyLight             : '#272727',                  /*BG color for inactive buttons / Grey as circle BG for buttons*/
	black75               : '#000000BF',                /*Grey BG behind popups*/
	blackLight            : '#151515',                  /*dark grey color BG colors*/
	whiteLight            : '#F8F8F8',                  /*Colors not defined but used in many screesn so defining here*/
	black30               : '#0000004D',                /*Not defined but used on many screens*/
	darkBlack             : '#282727',                  /*Not defined but used for disabled icons in DM*/
	brownishGrey          : '#707070',
	brownishGrey40        : '#70707066',                /*used for divider color*/
	darkRed               : '#CC3D40',                  /*error color for dark mode*/
   
	/*Status Color*/
 
	lightRed              : '#FE4D4F',                  /*Upcoming soon*/
	kermitGreen           : '#5CA700',                  /*Ongoing*/
	mango                 : '#FFA238',                  /*Upcoming*/
	blueGrey              : '#909191',                  /*completed-light-mode*/
	blueGreyDark          : '#5B5B5B',                  /*completed-dark-mode*/
 
	/*Pie chart*/

	paleTeal              : '#83D49D',                  /*option 1*/
	paleVoilet            : '#C9ADFF',                  /*option 2*/
	robinSEggBlue         : '#A2E9FF',                  /*option 3*/
	palePeach             : '#FFE5B4',                  /*option 4*/
};

export const graphColor = [
	colors.paleTeal, 
	colors.paleVoilet, 
	colors.robinSEggBlue, 
	colors.palePeach, 
	colors.grey, 
	colors.dodgerBlue, 
	colors.mango, 
	colors.kermitGreen, 
	colors.lightRed, 
	colors.lightGreyBlue20
];

export default colors;
