import { useState, useEffect } from 'react';
import { useTranslation }      from 'react-i18next';
import { toast }               from 'react-hot-toast';
     
import { sendRequest }         from 'libs/socket';
import { getRequestErrorMsg }  from 'common/error';

const i18nNamespaces = ['error'];

const useRequest = () => {

	const { t } = useTranslation (i18nNamespaces);

	const [errMessage, setErrMessage] = useState ('');

	useEffect(() => {
		/*component to show error message;*/
		if (errMessage) {
			toast.error(errMessage);
			setErrMessage ('');
		}
	}, [errMessage]);

	const request = {};

	request.sendComment = async (data) => {
		try {
			let result = await sendRequest('comment', 'send-comment', data);
			return result;
		}
		catch (err) {
			let msg = t(getRequestErrorMsg ('send-comment', err.name));
			setErrMessage (msg);
			throw err;
		}
	};

	request.editComment = async (data) => {
		try {
			let result = await sendRequest ('comment', 'edit-comment', data);
			return result;
		}
		catch (err) {
			let msg = t(getRequestErrorMsg ('edit-comment', err.name));
			setErrMessage (msg);
			throw err;
		}
	};

	request.cancelComment = async (data) => {
		try {
			let result = await sendRequest ('comment', 'cancel-comment', data);
			return result;
		}
		catch (err) {
			let msg = t(getRequestErrorMsg ('cancel-comment', err.name));
			setErrMessage (msg);
			throw err;
		}
	};

	request.submitPollAnswer = async (data) => {
		try {
			let result = await sendRequest('polling', 'poll-submit-answer', data);
			return result;
		}
		catch (err) {
			let msg = t(getRequestErrorMsg ('poll-submit-answer', err.name));
			setErrMessage (msg);
			throw err;
		}
	};

	request.sendSpeakRequest = async (data) => {
		try {
			let result = await sendRequest ('atom', 'request-to-speak', data);
			return result;
		}
		catch (err) {
			let msg = t(getRequestErrorMsg ('request-to-speak', err.name));
			setErrMessage (msg);
			throw err;
		}		
	};

	request.endSpeakRequest = async (data) => {
		try {
			let result = await sendRequest ('atom', 'request-to-end', data);
			return result;
		}
		catch (err) {
			let msg = t(getRequestErrorMsg ('request-to-end', err.name));
			setErrMessage (msg);
			throw err;
		}
	};

	request.cancelSpeakRequest = async (data) => {
		try {
			let result = await sendRequest ('atom', 'request-to-cancel', data);
			return result;
		}
		catch (err) {
			let msg = t(getRequestErrorMsg ('request-to-cancel', err.name));
			setErrMessage (msg);
			throw err;
		}
	};

	request.shareMic = async (data) => {
		try {
			let result =  await sendRequest ('atom', 'share-mic', data);
			return result;
		}
		catch (err) {
			let msg = t(getRequestErrorMsg('share-mic', err.name));
			setErrMessage (msg);
			throw err;
		}
	};

	request.shareCamera = async (data) => {
		try {
			let result = await sendRequest ('atom', 'share-camera', data);
			return result;
		}
		catch (err) {
			let msg = t(getRequestErrorMsg ('share-camera', err.name));
			setErrMessage (msg);
			throw err;
		}
	};

	request.shareContent = async (data) => {
		try {
			let result = await sendRequest ('atom', 'share-content', data);
			return result;
		}
		catch (err) {
			let msg = t(getRequestErrorMsg ('share-content', err.name));
			setErrMessage (msg);
			throw err;
		}
	};

	request.getAvatar = async (vcIds) => {
		let data = {
			type : 'avatar',
			list : vcIds,
		};
		try {
			let result = await sendRequest ('controller.assets', 'get-avatar', data);
			return result;
		}
		catch (err) {
			let msg = t(getRequestErrorMsg ('get-avatar', err.name));
			setErrMessage (msg);
			throw err;
		}
	};

	if (process.env.NODE_ENV === 'development')
		window.request = request;

	return request;
};

export default useRequest;
