import React                         from 'react';
import { useTranslation }            from 'react-i18next';
import { VictoryPie, VictoryTooltip} from 'victory';
import Typography                    from '@material-ui/core/Typography';
import Grid                          from '@material-ui/core/Grid';
import { useTheme }                  from '@material-ui/core/styles';

import 'styles/components/atoms/pie.css';

const i18nNamespaces = ["polls"];

function PieGraph ({ colors, total, data, options, selected}) {

	const theme = useTheme ().palette.type;
	const { t } = useTranslation (i18nNamespaces);

	const getText = (obj) => {
		return `${obj.datum?.y}% \n ${obj.datum?.label}`;
	};

	const getSelected = (index) => {
		let _selected = selected?.find (op => op.index === index);
		return _selected;
	};

	const tooltip = 
		<VictoryTooltip
			orientation        = "top"
			cornerRadius       = { 8 }
			flyoutStyle        = {{ fill: "var(--black-light)", stroke : '#313131'}}
			pointerOrientation = "bottom"
			flyoutPadding      = { 20 }
			text               = { getText }
			style              = { [
				{fill : 'var(--white)', stroke : 'var(--white)', strokeWidth : '0.1%', fontFamily : 'var(--bold)', fontSize : '20px'},
				{fill : 'var(--white-60)', stroke : 'var(--white-60)', fontFamily : 'var(--regular)', fontSize : '14px', dy : '20'}
			] }
		/>;

	return (
		<Grid className = 'pie parent'>
			<Grid className = 'pie-graph'>
				<VictoryPie
					colorScale     = { colors }
					responsive     = { true }
					data           = { data }
					innerRadius    = { 100 } labelRadius = { 100 }
					labelComponent = { tooltip }
					labels         = { () => "" }
				/>
				<Grid className = 'pie-label'>
					<Typography variant = 'h5' align = 'center' className = 'pie-total'> {total} </Typography>
					<Typography variant = 'subtitle1' color = 'secondary' align = 'center'> {t('body.total_votes_pie')} </Typography>
				</Grid>
			</Grid>
			<Grid className = 'pie-legends'>
				{options.map ((opt, index) => (
					<Grid container key = { index } className = 'pie-legend-opt' wrap = 'nowrap'>
						<Grid item xs = { 10 } container wrap = 'nowrap'>
							<span className = 'pie-legend-icon' style = {{backgroundColor : colors[index]}}> </span>
							<Typography variant = 'subtitle1' color = { getSelected (index) ? 'primary' : theme === 'light' ? 'secondary' : 'textPrimary' }> {opt} </Typography>
						</Grid>
						<Grid item xs = { 2 }>
							<Typography variant = 'subtitle1' color = { getSelected (index) ? 'primary' : theme === 'light' ? 'secondary' : 'textPrimary' } align = 'right'> {data[index]?.y}% </Typography>
						</Grid>
					</Grid>
				))}			
			</Grid>
		</Grid>
	);
}
	
export default PieGraph;
