import React                from 'react';
import { useTranslation }   from 'react-i18next';
import PropTypes            from 'prop-types';
import Grid                 from '@material-ui/core/Grid';
import Typography           from '@material-ui/core/Typography';

import Button               from 'components/atoms/Button';

import 'styles/components/molecules/pollCard.css';

const i18nNamespaces = ["polls"];

function PollCard ({ question, onViewResult, hash, resultShared }) {
	
	const { t } =  useTranslation (i18nNamespaces);

	return (
		<Grid className = 'pollCard parent'>
			{!resultShared && <Typography variant = 'h1' className = 'pollCard-result-pending'> Results pending </Typography>}
			<Grid className = 'mt-15'>
				<Typography variant = 'subtitle1' className = 'pollCard-question'> {question} </Typography>
			</Grid>
			{resultShared && 
			<Grid className = 'mt-xl' container justify = 'flex-end'>
				<Button variant = 'outlined' color = 'primary' onClick = { () => onViewResult (hash) }> {t('button:action.view_result')} </Button> 
			</Grid>
			}
		</Grid>
	);
}	

PollCard.propTypes = {
	hash         : PropTypes.string.isRequired,
	question     : PropTypes.string.isRequired,
	onViewResult : PropTypes.func.isRequired, 
};

export default PollCard;
