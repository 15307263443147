import React                           from 'react';
import { useTranslation }              from 'react-i18next';
import { Toaster, resolveValue, toast} from 'react-hot-toast';
import { TOAST_TIMEOUT }               from 'common/config';

import 'styles/components/atoms/toast.css';

function Toast () {

	const { t } = useTranslation ();

	const toastOptions = {
		duration : TOAST_TIMEOUT * 1000,
		position : 'top-center',
	};

	const containerStyle = {
		position : 'absolute',
		top      : '10%',
	};

	const onClose = (toastId) => {
		toast.dismiss (toastId);
	};

	const renderType = (tst) => {
		let toastType = tst.type;

		return (
			<div style ={{ opacity: tst.visible ? 1 : 0}} className = { `toast-container ${toastType}` }>
				<div className = { `toast-msg ${toastType}` }>
					{ resolveValue(tst.message, tst) }
				</div>
				<div className = { `toast-action ${toastType}` }>
					<button className = { `toast-action-btn ${toastType}` } onClick = { () => onClose (tst.id) }> {t('profile_photo:header.close')} </button>
				</div>
			</div>
		);
	};

	return (
		<Toaster 
			toastOptions   = { toastOptions }
			containerStyle = { containerStyle }
		>
			{(t) => (
				renderType (t)
			)}
		</Toaster>
	);
}

export default Toast;
