import React                       from 'react';
import { useTranslation }          from 'react-i18next'; 
import { useTheme }                from '@material-ui/core/styles';
import Typography                  from '@material-ui/core/Typography';

import IconButton                  from 'components/atoms/IconButton';

const i18nNamespaces = ["profile_photo"];

function TakePhoto ({ onCapture }) {

	const { t }    = useTranslation (i18nNamespaces);
	const theme    = useTheme().palette.type;

	const handleCaptureModal = () => {
		onCapture ();
	};

	return (
		<React.Fragment>
			<IconButton
				color    = 'secondary'
				icon     = 'Capture'
				onClick  = { handleCaptureModal }
				size     = { 45 }
			/>
			<Typography variant = 'h2' color = { theme === 'light' ? 'primary' : 'textPrimary' } align = 'center' className = 'mt-s'>
				{t('body.camera')}
			</Typography>
		</React.Fragment>
	);
}

export default TakePhoto;
