import React, { useEffect, useState }   from 'react';
import { useHistory }                   from 'react-router-dom';
import { useTranslation }               from 'react-i18next';
import { useDispatch }                  from 'react-redux';
import { toast }                        from 'react-hot-toast';
import Grid                             from '@material-ui/core/Grid';
import Typography                       from '@material-ui/core/Typography';
              
import LandingLeftBar                   from 'components/molecules/LandingLeftBar';
import PinInput                         from 'components/molecules/PinInput';
import EventModal                       from 'components/molecules/EventModal';
import PinModal                         from 'components/molecules/PinModal';
import Button                           from 'components/atoms/Button';
import { usePageLoader }                from 'components/atoms/PageLoader';
import useApis                          from 'common/apis';
import path                             from 'navigations/path';
import { logContainer }                 from 'libs/logger';
import { addCurrentEvent }              from 'redux/slices/event';

import 'styles/pages/landing.css';

const log = logContainer('pages/onlineLanding');

const i18nNamespaces = ['landing'];

function OnlineLanding () {

	const { t }    = useTranslation (i18nNamespaces);
	const redirect = useHistory ();
	const dispatch = useDispatch ();
	const apis     = useApis ();

	const eventCodeLength = 6;

	const [eventCode, setEventCode]   = useState ('');
	const [error, setError]           = useState ('');
	const [loading, setLoading]       = useState (false);
	const [eventInfo, setEventInfo]   = useState (null);
	const [eventModal, setEventModal] = useState (false);
	const [pinModal, setPinModal]     = useState (false);
	const [cmmsInfo, setCmmsInfo]     = useState (null);

	useEffect (() => {
		log.info ('Online landing page');
	}, []);

	const handlePinChange = (value) => {
		setEventCode (value);
		setError ('');
	};

	const onPinSubmit = async() => {
		if (!eventCode || eventCode.length !== eventCodeLength) {
			return;
		}
		let cmmsInfo;
		setLoading (true);
		try {
			cmmsInfo  = await apis.getCMMSInfo (eventCode);
			log.info ({cmmsInfo}, 'cmms info get ok'); 
		}
		catch (err) {
			log.error ({err}, 'error getting cmms info');
			setLoading (false);
			return;
		}
		let eventInfo;
		try {
			eventInfo = await apis.getOnlineEventInfo ({host : cmmsInfo.host, port : cmmsInfo.port, proto : cmmsInfo.proto}, eventCode);
			log.info ({eventInfo}, 'eventInfo get ok');
		} 
		catch (err) {
			log.error ({err}, 'error getting event info');
			setLoading (false);
			if (err?.response?.data?.status_code === 401) {
				setError (t('online:body.invalid_code'));
				return;
			}
			toast.error (err?.name);
			return;
		}
		setLoading (false);
		setCmmsInfo (cmmsInfo);
		setEventInfo (eventInfo.event_data);
		handleEventModal ();
	};

	const handleEventModal = () => {
		setEventModal (!eventModal);
	}; 

	const handlePinModal = () => {
		setPinModal (!pinModal);
	};

	const onJoinEvent = () => {
		if (eventInfo.state === 'stopped') {
			redirect.push (path.pastLanding, {eventId : eventInfo.id});
			return;
		}
		if (!eventInfo.attendeePin.length) {
			dispatch(addCurrentEvent(eventInfo));
			redirect.push (path.connect, {isOnline : true});
			return;
		}
		dispatch(addCurrentEvent(eventInfo));
		setPinModal (true);
	};

	const showEventModal = eventModal &&
		<EventModal
			eventInfo   = { eventInfo }
			open        = { true }
			handleClose = { handleEventModal }
			enterEvent  = { onJoinEvent }
		/>;

	return (
		<Grid className = 'landing page-parent' container>
			<LandingLeftBar />
			<Grid className = 'landing-right-container'>
				<Grid className = 'landing-top-bar' container alignItems = 'center'>
					<Typography variant = 'h5'> {t('body.all_events_list')} </Typography>
				</Grid>
				<Grid className = 'landing-main-container online'>
					<Grid className = 'landing-pin-container'>
						<Grid>
							<Typography variant='h3' align = 'center'> {t('landing:body.online_event_code')} </Typography>
						</Grid>
						<Grid className = 'mt-xxl'>
							<PinInput
								length    = { eventCodeLength }
								onChange  = { handlePinChange }
								error     = { error }
								inputType = 'alpha_num'
							/>
						</Grid>
						<Grid container className = 'mt-xxl2'>
							<Grid item xs = { 12 } container justify = 'center'>
								<Button variant = 'contained' color = 'primary' loading = { loading } disabled = { eventCode.length !== eventCodeLength } onClick = { onPinSubmit }> {t('button:action.enter_event')} </Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{showEventModal}
			{pinModal ? 
				<PinModal 
					open     = { pinModal }
					onClose  = { handlePinModal }
					cmmsInfo = { cmmsInfo }
				/> 
				: null}
		</Grid>
	);
}

export default OnlineLanding;