import React , { useEffect, useState }                    from 'react';
import moment                                             from 'moment';
import { useSelector, useDispatch }                       from 'react-redux';
import { useHistory }                                     from 'react-router-dom';
import { useTranslation }                                 from 'react-i18next';
import Grid                                               from '@material-ui/core/Grid';

import EventLandingTopBar                                 from 'components/molecules/EventLandingTopBar';
import EventLandingSideBar                                from 'components/molecules/EventLandingSideBar';
import EventLandingBottomBar                              from 'components/molecules/EventLandingBottomBar';
import VideoPlayer                                        from 'components/molecules/VideoPlayer';
import SpeakRequestModal                                  from 'components/molecules/SpeakRequestModal';
import GoLiveApprovedModal                                from 'components/molecules/GoLiveApprovedModal';
import Walkthrough                                        from 'components/molecules/Walkthrough';
import Alert, { useAlert }                                from 'components/atoms/Alert';
import Notification                                       from 'components/atoms/Notification';
import Comments                                           from 'pages/Comments';
import Polls                                              from 'pages/Polls';
import SentRequest                                        from 'pages/SentRequest';
import { Terminate }                                      from 'libs/socket';
import { logContainer }                                   from 'libs/logger';
import LocalStore                                         from 'libs/localStore';
import { storageKey, isOnline }                           from 'common/config';
import UserMedia                                          from 'common/userMedia';
import useRequest                                         from 'common/request';
import path                                               from 'navigations/path';
import { clearCommentStore }                              from 'redux/slices/comment';
import { clearPollingStore }                              from 'redux/slices/polling';
import { clearSettingsStore }                             from 'redux/slices/settings'; 
import { handleRequestSpeak }                             from 'redux/slices/webrtc';
import { isMicProcessingSelector, isMicPendingSelector }  from 'redux/slices/mic'; 
import { addNewNotify, addNewBadge }                      from 'redux/slices/common';

import 'styles/pages/eventLanding.css';

const log = logContainer ('pages/EventLanding');

const i18nNamespaces = ['in_event'];

function EventLanding () {

	const redirect      = useHistory ();
	const { okAlert }   = useAlert ();
	const dispatch      = useDispatch ();
	const request       = useRequest ();
	const { t }         = useTranslation (i18nNamespaces);            

	const eventStore    = useSelector (state => state.event);
	const settingsStore = useSelector (state => state.settings);
	const micStore      = useSelector (state => state.mic);
	const userStore     = useSelector (state => state.user);
	const commonStore   = useSelector (state => state.common);

	const micSettings     = settingsStore.mic;
	const { participant } = userStore;
	const { notify }      = commonStore; 

	const isMicReqPending = isMicPendingSelector (micStore);
	const isMicProcessing = isMicProcessingSelector (micStore);
	const isMicAllowed    = micSettings.allowRequests; 

	const { live } =  eventStore;

	const [leaveModal, setLeaveModal]     = useState (false); 
	const [leaveLoading, setLeaveLoading] = useState (false);
	const [selectedTab, setSelectedTab]   = useState (''); /*enum : ['comment', 'poll', 'sent']*/
	const [requestModal, setRequestModal] = useState ({open : false, title : '', desc : '', approved : false});
	const [cancelMic, setCancelMic]       = useState (false);
	const [goLiveModal, setGoLiveModal]   = useState (false);
	const [askLoading, setAskLoading]     = useState (false);
	const [onboarding, setOnboarding]     = useState (false);

	useEffect (() => {
		/*Onoarding logic*/
		let isOnboarded = LocalStore.get (storageKey.onboarded);
		if (isOnboarded) {
			return;
		}
		setOnboarding (true);
	}, []);

	useEffect (() => {
		let requestAgain = redirect.location.state?.requestAgain;
		if (!requestAgain) {
			return;
		}
		onSubmitMicRequest ();
	}, []);

	useEffect (() => {
		if (micStore.state === 'call-idle') {
			setGoLiveModal (false);
			return;
		}
		if (micStore.state === 'call-approved' && !micSettings.autoApprove) {
			handleReqModal(true);
			return;
		}
		if (micStore.state === 'call-active') {
			handleReqModal ();
			handleGoLiveModal ();
		}
	}, [micStore.state]);

	useEffect (() => {
		let queuePos = micStore.position;
		if (queuePos === null) {
			return;
		}
		let ordinalStr = moment.localeData().ordinal(queuePos + 1);
		let text = t("alert:desc.req_approved_pos", { position: queuePos, ordinal: ordinalStr });
		if (queuePos === 0) {
			text = t("alert:desc.req_approved_next");
		}
		dispatch (addNewNotify({open : true, title : t('alert:title.go_live_req'), desc : text, tab : 'sent'}));
	}, [micStore.position]);

	const handleLeaveEvent = () => {
		setLeaveModal (!leaveModal);
	};

	const leaveEvent = async () => {
		try {
			setLeaveLoading (!leaveLoading);
			await Terminate ();
		}
		catch (err) {
			log.error ({err}, 'error terminating connection');
			setLeaveLoading (!leaveLoading);
			return;
		}
		/*Clear all resources in redux*/
		dispatch (clearCommentStore  ());
		dispatch (clearPollingStore  ());
		dispatch (clearSettingsStore ());

		setLeaveLoading (!leaveLoading);
		if (isOnline) {
			redirect.push (path.onlineLanding);
			return;
		}
		redirect.push(path.landing);
	};

	const onSubmitMicRequest = async (perms) => {
		/*
		Perms : audio and video permission
		*/
		if (!perms) {
			perms = await checkPermission ();
		}
		let userMediaConstraints = {};
		if (perms.audio) {
			userMediaConstraints.audio = true;
		}
		if (perms.video) {
			userMediaConstraints.video = { width: 640, height: 480, framerate: 10 };
		}
		setAskLoading (true);
		try {
			await dispatch(handleRequestSpeak({constraints : userMediaConstraints, sendSpeakReq : request.sendSpeakRequest})).unwrap();
		} 
		catch (error) {
			log.error ('Error sending speak req : ', error);
			setAskLoading(false);
			return;
		}
		if (!micSettings.autoApprove) {
			handleReqModal(false);
			dispatch (addNewBadge ('sent'));
		}
		setAskLoading(false);
	};

	const onCancelMicRequest = () => {
		handleReqModal ();
		setSelectedTab ('sent');
		handleCancelMic ();
	};

	const handleCancelMic = () => {
		setCancelMic (!cancelMic);
	}; 

	const handleAskToSpeak = async () => {
		let perms = await checkPermission ();
		if (!perms) {
			return;
		}

		if (!micSettings.autoApprove) {
			okAlert ({
				title          : t('modal:title.submit_req'),
				message        : t('modal:body.submit_req'),
				confirmBtn     : t('button:action.continue'),
				dismissBtn     : t('button:action.cancel'),
				onConfirm      : () => onSubmitMicRequest (perms),
			});
			return;
		}
		
		okAlert ({
			title      : t('modal:title.submit_req'),
			message    : t('modal:body.submit_req_auto'),
			confirmBtn : t('button:action.continue'),
			dismissBtn : t('button:action.cancel'),
			onConfirm  : () => onSubmitMicRequest (perms),
		});
	};

	const checkPermission = async () => {
		/*Check for mic and camera permissions*/
		let permsManager;
		try {
			permsManager = await UserMedia.checkPermissions ();
		}
		catch (err) {
			log.error ({err}, 'error getting user device permissions');
			return;
		}

		if (!permsManager.audio.permission && !permsManager.video.permission) {
			try {
				await UserMedia.askPermissions ({audio : true, video : true});		
				return {audio : true, video : true};
			}
			catch (err) {
				log.error ({err}, 'error getting userMedia');
				okAlert ({
					title      : err.errorTitle,
					message    : err.errorMsg,
					confirmBtn : 'OK', 
				});
				return;
			}
		}

		if (!permsManager.audio.permission) {
			try {
				await UserMedia.askPermissions ({audio : true});		
				return {audio : true};
			}
			catch (err) {
				log.error ({err}, 'error getting userMedia');
				okAlert ({
					title      : err.errorTitle,
					message    : err.errorMsg,
					confirmBtn : 'OK', 
				});
				return;
			}
		}

		if (permsManager.audio.permission && permsManager.video.permission) {
			return { video: true, audio: true };
		}

		if (permsManager.audio.permission) {
			return { video : false, audio: true };
		}
	};

	const handleSelectedTab = (sel) => {
		if (selectedTab === sel) {
			setSelectedTab ('');
			return;
		}
		setSelectedTab (sel);
	};

	const handleReqModal = (isApproved) => {
		if (isApproved === undefined) {
			setRequestModal ({open : false, approve : false, title : '', desc : ''});
			return;
		}
		if (isApproved === false) {
			setRequestModal ({open : true, approve : false, title : t('go_live:body.request_in_progress'), desc : t('go_live:body.request_in_progress_subtext')});
			return;
		}
		setRequestModal ({open : true, approved : true, title : t('go_live:body.request_approved'), desc : t('go_live:body.request_approved_subtext')});
	};

	const handleGoLiveModal = () => {
		setGoLiveModal (!goLiveModal);
	}; 

	const onReqTimeout = () => {
		handleGoLiveModal ();
		okAlert ({
			title          : t('modal:title.req_timeout'),
			message        : t('modal:body.req_timeout'),
			dismissBtn     : t('button:action.request_again'),
			confirmBtn     : t('button:action.back_to_event'),
			onDismiss      : onSubmitMicRequest,
		});
	};

	const onViewNotify = () => {
		setSelectedTab (notify.tab);
	};

	const onSkipOnboarding = () => {
		setOnboarding (false);
		LocalStore.set (storageKey.onboarded, true);
		redirect.push (path.connect);
	};

	const onNextOnboarding = () => {
		setOnboarding (false);
		redirect.push (path.liveCall, {isOnbaording : true});
	};

	const walkthrough = onboarding && <Walkthrough onSkip = { onSkipOnboarding } onNext = { onNextOnboarding } onboardingStep = { 1 }/>;

	const speakRequestAlert = requestModal.open && 
		<SpeakRequestModal
			open            = { requestModal.open }
			title           = { requestModal.title }
			desc            = { requestModal.desc }
			approved        = { requestModal.approved }
			onClose         = { handleReqModal }
			onCancelRequest = { onCancelMicRequest }
		/>;

	const goLiveApproved = goLiveModal && 
		<GoLiveApprovedModal 
			open         = { goLiveModal }
			onClose      = { handleGoLiveModal }
			onReqTimeout = { onReqTimeout }
		/>;

	const leaveAlert = leaveModal && 
		<Alert 
			title          = { t('alert:title.leave_event') }
			message        = { t('alert:desc.leave_event') }
			confirmBtn     = { t('button:action.leave_event') }
			dismissBtn     = { t('button:action.cancel') }
			onConfirm      = { leaveEvent }
			open           = { leaveModal }
			onDismiss      = { handleLeaveEvent }
			loading        = { leaveLoading }
			confirmColor   = 'inherit'
			confirmVariant = 'contained'
			dismissColor   = 'secondary'
		/>;

	const notification = notify.open  && 
		<Notification 
			open    = { notify.open }
			title   = { notify.title }
			desc    = { notify.desc }
			onView  = { onViewNotify }
		/>;

	const sideBarContent = () => {
		switch (selectedTab) {
			case 'comment' :
				return <Comments />;
			case 'poll' :
				return <Polls />;
			case 'sent' :
				return <SentRequest cancelMic = { cancelMic } onMicCanceled = { handleCancelMic }/>;
			default :
				return;
		}
	};

	return (
		<Grid className = 'eventLanding page-parent'>
			<EventLandingTopBar 
				onLeave = { handleLeaveEvent }
			/>
			<Grid className = 'eventLanding-main-container'>
				<Grid className = { `eventLanding-video-container ${!selectedTab ? 'fullwidth' : ''}` }>
					<VideoPlayer />
				</Grid>
				<Grid className = { `eventLanding-side-container ${selectedTab ? 'open' : ''}` }>
					<EventLandingSideBar open = { selectedTab ? true : false }>
						{sideBarContent ()}
					</EventLandingSideBar>
				</Grid>
			</Grid>
			<EventLandingBottomBar 
				live              = { live }
				askToSpeak        = { handleAskToSpeak }
				isMicProcessing   = { isMicProcessing }
				isMicReqPending   = { isMicReqPending }
				handleSelectedTab = { handleSelectedTab }
				selectedTab       = { selectedTab }
				participant       = { Object.keys(participant).length + 1 }
				askLoading        = { askLoading }
				isMicAllowed      = { isMicAllowed }
			/>
			{walkthrough}
			{leaveAlert}
			{notification}
			{speakRequestAlert}
			{goLiveApproved}
		</Grid>
	);
}

export default EventLanding;