import React              from 'react';
import moment             from 'moment';
import { useTranslation } from 'react-i18next';
import Divider            from "@material-ui/core/Divider";
import Avatar             from "@material-ui/core/Avatar";
import AvatarGroup        from "@material-ui/lab/AvatarGroup";
import Typography         from "@material-ui/core/Typography";
import Grid               from "@material-ui/core/Grid";
import { useTheme }       from '@material-ui/core/styles';

import Popups             from 'components/atoms/Popups'; 
import Button             from 'components/atoms/Button';
import LMUserProfile      from 'assets/icons/light/userProfile.svg';
import DMUserProfile      from 'assets/icons/dark/userProfile.svg';

import 'styles/components/molecules/eventInfoModal.css';

const i18nNamespaces = ['landing'];

function EventModal ({ open, handleClose, eventInfo, enterEvent}) {

	const { t }  = useTranslation (i18nNamespaces); 
	const theme  = useTheme().palette.type;

	let presenterText, presenterCount = eventInfo.presenterDetails?.length;

	if (presenterCount === 1) {
		presenterText = t("modal:body.by", { presenter : eventInfo.presenter });
	} else if (presenterCount === 2) {
		presenterText = t("modal:body.by_duo", { presenter : eventInfo.presenterDetails[0].name, coPresenter : eventInfo.presenterDetails[1].name });
	} else {
		presenterText = t("modal:body.by_multiple", { presenter : eventInfo.presenterDetails[0].name, count : eventInfo.presenterDetails.length - 1 });
	}

	const getStartTime = () => {
		let timeString = '';
		let currentDate = new Date ();
		let scheduledAt = new Date (eventInfo.scheduledDate);
		let timeDiff = scheduledAt.getTime () - currentDate.getTime ();

		switch (eventInfo.state) {
			case 'created' :
				timeString =  t('tag.starting', {timeString : moment (eventInfo.scheduledDate).fromNow ()});
				break;
			case 'stopped' :
				timeString =  t('tag.ended', {timeString : moment (eventInfo.endedAt).fromNow ()});
				break;
			case 'started' :
				timeString =  t('tag.started', {timeString : moment (eventInfo.startedAt).fromNow ()});
				break;
			default : 
				return;
		}

		if (eventInfo.state === 'created' && timeDiff < 0) {
			timeString =  t('tag.not_started');
		}

		return timeString;
	};

	const getStatus = () => {
		switch (eventInfo.state) {
			case 'created' : {
				const currentDate = new Date ();
				const scheduledAt = new Date (eventInfo.scheduledDate);
				const timeDiff = scheduledAt.getTime () - currentDate.getTime ();
				if (timeDiff < 0) {
					return 'NOT_STARTED'; // #5b5b5b
				} else {
					return timeDiff < 60 * 60 * 1000
						? 'UPCOMING_SOON' // #fe4d4f
						: 'UPCOMING'; // #ffa238
				}
			}
			case 'started' : {
				return 'ONGOING'; // #5ca700
			}
			case 'stopped' : {
				return 'COMPLETED'; // #5b5b5b
			}
		}
	};

	return (
		<Popups open = { open } title = { t('event_info:header.event_info') }>
			<Grid>
				<Typography variant = 'h3'> {eventInfo.name} </Typography>
				<Typography variant = 'h2' color = 'secondary' className = 'mt-xs'> {eventInfo.description} </Typography>
			</Grid>
			<Divider className = 'mt-xl mb-xl' />
			<Grid>
				<Typography variant = 'h2'> {moment (eventInfo.scheduledDate).format ('LL')}|{moment (eventInfo.scheduledDate).format ('h:mm a')} </Typography>
				<Typography variant = 'h2' className = { `eventCard-timeago ${getStatus().toLowerCase()}` }> {getStartTime ()} </Typography>
			</Grid>
			<Divider className = 'mt-xl mb-xl' />
			<Grid>
				<Typography variant='body2' color='secondary' className='mt-xs eventInfoModal-presenter'> {t('landing:body.presenter')} </Typography>
			</Grid>
			<Grid container alignItems = 'center' className = 'mt-m'>
				<AvatarGroup max = { 3 }>
					{eventInfo.presenterDetails.map ((presenter, i) => (
						<Avatar 
							src       = { presenter.url ? presenter.url : theme === 'light' ? LMUserProfile : DMUserProfile } 
							key       = { i } 
							className = 'eventCard-presenter-avatar'
						/>
					))}
				</AvatarGroup>
				<Grid className = 'ml-m'>
					<Typography variant = 'subtitle1'> {presenterText}</Typography>
				</Grid>
			</Grid>
			<Divider className = 'mt-xl mb-xl' />
			<Grid container className = 'mt-xxl'>
				<Grid item xs = { 6 }>
					<Button variant = 'outlined' color = 'secondary' onClick = { handleClose }>{t('button:action.cancel')}</Button>
				</Grid>
				<Grid item xs = { 6 } container justify = 'flex-end'>
					<Button variant = 'contained' color = 'primary' disabled = { eventInfo.state !== 'started' } onClick = { enterEvent }>{t('button:action.enter_event')}</Button>
				</Grid>
			</Grid>
		</Popups>
	);
}

export default EventModal;