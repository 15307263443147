import React, { useState, useEffect }                  from 'react';
import { useHistory, useLocation }                     from 'react-router-dom';
import { useTranslation }                              from 'react-i18next';
import { useDispatch, useSelector }                    from 'react-redux';
import Grid                                            from '@material-ui/core/Grid';
import FormLabel                                       from '@material-ui/core/FormLabel';
import Button                                          from '@material-ui/core/Button';
import Typography                                      from '@material-ui/core/Typography';
import TextField                                       from '@material-ui/core/TextField';
                 
import { updateName, updateLoginStatus }               from 'redux/slices/login';
import path                                            from 'navigations/path';
import Profile                                         from 'components/molecules/Profile';
import LanguageSelect                                  from 'components/molecules/Language';                                         
import utils                                           from 'common/utils';
import { storageKey, isOnline }                        from 'common/config';
import localStore                                      from 'libs/localStore';
import Logo                                            from 'assets/images/logo.png';
import Logo2x                                          from 'assets/images/logo@2x.png';
import Logo3x                                          from 'assets/images/logo@3x.png';

import 'styles/pages/login.css';

const i18nNamespaces = ["locale"];

function Login () {

	const redirect       = useHistory ();
	const location       = useLocation ();
	const dispatch       = useDispatch ();
	const loginStore     = useSelector (state => state.login);
	const { t, i18n }    = useTranslation (i18nNamespaces);

	const langCode       = location.state?.langCode || i18n.getCurrentLocale ();

	const { name } = loginStore;

	const [errors, setErrors] = useState ({ name : false });

	useEffect (() => {
		let profile = localStore.get(storageKey.profile);
		if (profile) {
			if (isOnline) {
				redirect.push (path.onlineLanding);
				return;
			}
			redirect.push (path.landing);
			return;
		}
	}, []);

	const handleTextField = (event) => {
		let { value } = event.currentTarget;
		dispatch (updateName (value));
		setErrors ({ ...errors, name : false });
	};

	const handleKeyPress = (event) => {
		if (event.key !== 'Enter') {
			return;
		}
		onSubmit ();
	};

	const onSubmit = () => {
		if (!name || name.trim () === "") {
			setErrors ({ ...errors, name : true});
			return;
		}
		/*
		 * Generate and save a unique id for each user
		 * to be used for web-socket connection.
		 * It will be the same till the user logged out
		 */

		let uuid = utils.uniqueId (32);

		let profile = {
			id : uuid,
			name,
			image : loginStore.image,
		};
		
		localStore.set(storageKey.profile, profile);
		localStore.set(storageKey.onboarded, false);
		i18n.changeLanguage (langCode);
		dispatch (updateName (name));
		dispatch (updateLoginStatus (true));
		if (isOnline) {
			redirect.push (path.onlineLanding);
			return;
		}
		redirect.push (path.landing);
	};

	return (
		<Grid className = 'login page-parent'> 
			<Grid container className = 'page-container'>
				<Grid container direction = 'column' item sm = { 5 } className = 'login-image-container'>
					<Grid item sm = { 5 }>
						<img
							src       = { Logo }
							srcSet    = { `${Logo2x}, ${Logo3x}` }
							height    = { 31 }
							className = 'login-image-info'
						/>
					</Grid>
					<Grid item sm = { 7 } className = 'login-image-info'>
						<Typography variant = 'h4' className = 'login-image-title'> {t('login:title.participant_app')} </Typography>
						<Typography variant = 'h5' className = 'login-image-main-text'> {t('login:body.info_text')} </Typography>
					</Grid>
				</Grid>
				<Grid container item direction = 'column' sm = { 7 } className = 'login-right-container'>
					<Grid container item sm = { 3 } justify = 'flex-end' className = 'login-top-container'>
						<LanguageSelect />
					</Grid>
					<Grid item sm = { 9 } className = 'login-form-container'>
						<Grid>
							<Typography variant = 'h3' className = "login-welcome-text" align = 'center'> 
								{t('login:body.welcome')}
							</Typography>
							<Typography variant = 'h2' color = 'secondary' align = 'center' className = 'mt-xs'> 
								{t('login:body.display_name')}
							</Typography>
						</Grid>
						<Grid className = 'login-image-grid'>
							<Profile />
						</Grid>
						<Grid className = 'mb-xl'>
							<FormLabel required color = 'secondary'> {t('login:input.name_header')} </FormLabel>
							<Grid className = 'mt-s'>
								<TextField 
									variant     = 'outlined' 
									color       = 'primary' 
									placeholder = { t('login:input.name') }
									onChange    = { handleTextField }
									fullWidth   = { true }
									value       = { name }
									name        = 'name'
									error       = { errors.name }
									onKeyPress  = { handleKeyPress }
									inputProps  = {{
										maxLength : 256
									}}
								/> 
							</Grid>
						</Grid>
						<Button variant = 'outlined' color = 'primary' size = 'large' fullWidth disabled = { !name ? true : false } onClick = { onSubmit }> 
							{t('button:action.continue')}
						</Button> 
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Login;
