import pallete     from './pallete';
import typography  from './typography';
import colors      from './colors';

/*
 * This is a global theme used by MUI, 
 * any changes here will be applied to all the MUI components globally.
 * Go through the documentation and components api of MUI for reference.
 * */

const theme = (mode) => {
	
	/*
	 *Mode -> Theme mode, can be of light or dark
	 *
	 * */

	return {

		palette : {
			type : mode,
			...pallete[mode],
		},

		typography : {
			...typography,
		},

		overrides : {
			MuiButton : {
				root : {
					padding       : '15px',
					textTransform : 'none',
					minWidth      : '90px',
					borderRadius  : '8px',
					boxShadow     : 'none',
					'&$disabled'  : {
						backgroundColor : mode === 'light' ? 'var(--light-periwinkle)' : 'var(--grey-light)',
						color           : mode === 'light' ? 'var(--light-grey-blue)': 'var(--dark-grey)',
						border          : 'none !important',
					}
				},
				containedPrimary : {
					height    : '40px',				
					boxShadow : 'none', 
					'&:hover' : {
						boxShadow : 'none' 
					},
					'&$disabled'  : {
						backgroundColor : mode === 'light' ? 'var(--light-periwinkle)' : 'var(--grey-light)',
						color           : mode === 'light' ? 'var(--light-grey-blue)': 'var(--dark-grey)',
						border          : 'none !important',
					}
				},
				outlinedPrimary : {
					height          : '40px',
					border          : `1px solid ${mode === 'light' ? 'var(--blue-blue)' : 'var(--dodger-blue)'}`,
					color           : mode === 'light' ? 'var(--blue-blue)' : 'var(--white-light)',
					backgroundColor : mode === 'light' ? 'var(--dodger-blue-20)' : 'var(--dodger-blue-32)',
					'&:hover'       : {
						backgroundColor : mode === 'light' ? 'var(--dodger-blue-20)' : 'var(--dodger-blue-32)',
						border          : `1px solid ${mode === 'light' ? 'var(--blue-blue)' : 'var(--dodger-blue)'}`,
						color           : mode === 'light' ? 'var(--blue-blue)' : 'var(--white-light)',
					}
				},
				outlinedSecondary : {
					borderRadius : '8px',
					border       : `solid 1px ${mode === 'light' ? 'var(--greyblue)': '#444342'}`,
					color        : mode === 'light' ? 'var(--greyblue)' : 'var(--white-light)',
					height       : '40px',
					'&:hover'       : {
						border          : `solid 1px ${mode === 'light' ? 'var(--greyblue)': '#444342'}`,
						color           : mode === 'light' ? 'var(--greyblue)' : 'var(--white-light)',
						backgroundColor : 'transparent'
					}
				},
				outlined : {
					'&.MuiButton-colorInherit' : {
						color  : 'var(--dark-red)',
						height : '40px',
						border : '1px solid var(--dark-red)',
						boxShadow : 'none',
						'&:hover' : {
							color  : 'var(--dark-red)',
							height : '40px',
							border : '1px solid var(--dark-red)',
							boxShadow : 'none',
						}
					},
				},
				contained : {
					'&.MuiButton-colorInherit' : {
						color           : 'var(--white)',
						backgroundColor : 'var(--dark-red)',
						height          : '40px',
						boxShadow       : 'none',
						'&:hover' : {
							color           : 'var(--white)',
							height          : '40px',
							backgroundColor : 'var(--dark-red)',
							boxShadow       : 'none',
						},
						'&$disabled'  : {
							backgroundColor : mode === 'light' ? 'var(--light-periwinkle)' : 'var(--grey-light)',
							color           : mode === 'light' ? 'var(--light-grey-blue)': 'var(--dark-grey)',
							border          : 'none !important',
						}
					},
				},
				containedSizeLarge : {
					height   : '45px !important',
					fontSize : '14px',
				},
				outlinedSizeLarge : {
					height     : '45px',
					fontSize   : '14px',
					fontFamily : 'var(--bold)',
				},
				containedSizeSmall : {
					height   : '35px',
					fontSize : '14px',
				},
				outlinedSizeSmall : {
					height   : '35px',
					fontSize : '14px',
				},
			},

			MuiIconButton : {
				root : {
					position     : 'inherit', 
					padding      : '0px',
					'&$disabled' : {
						backgroundColor : mode === 'light' ? 'var(--light-periwinkle)' : 'var(--dark-black)',
					}
				},
				colorPrimary : {
					backgroundColor : mode === 'light' ? 'var(--blue-blue)' : 'var(--dodger-blue)',
					'&:hover' : {
						backgroundColor : mode === 'light' ? 'var(--blue-blue)' : 'var(--dodger-blue)',
					}
				},
				colorSecondary : {
					backgroundColor : mode === 'light' ? 'var(--blue-blue-5)' : 'var(--grey-light)',
					'&:hover' : {
						backgroundColor : mode === 'light' ? 'var(--blue-blue-5)' : 'var(--grey-light)',
					}
				},
				colorInherit : {
					backgroundColor : 'var(--dark-red)',
					'&:hover' : {
						backgroundColor : 'var(--dark-red)',
					}
				},
			},

			MuiFormLabel : {
				root : {
					fontFamily    : 'var(--bold)',
					fontSize      : '12px',
					fontStretch   : 'normal',
					fontStyle     : 'normal',
					lineHeight    : 1.42,
					letterSpacing : 'normal',
					textAlign     : 'left',
					color         : mode === 'light' ? 'var(--greyblue)' : 'var(--grey)',
				}
			},

			MuiOutlinedInput : {
				root : {
					borderRadius : '8px',
					height       : '45px',
				},
				input : {
					padding       : '13px 16px',
					fontSize      : '14px',
					fontStretch   : 'normal',
					fontStyle     : 'normal',
					lineHeight    : '1.36',
					letterSpacing : 'normal',
					color         : mode === 'light' ? 'var(--black)' : 'var(--white-light)',
					textAlign     : 'left',
					fontFamily    : 'var(--semibold)',
					'&::placeholder' : {
						color   : mode === 'light' ? 'var(--light-grey-blue)' : 'var(--white-40)',
						opacity : 1
					},
				},
				multiline : {
					height   : 'inherit',
					padding  : '15.5px 10.6px 15.5px 15px',
				},
			},

			MuiTextField : {
				root : {
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							border : `solid 1px ${mode === 'light' ? 'var(--blue-blue-20)' : 'var(--white-40)'}`,
						},
						'&:hover fieldset': {
							border : `solid 1px ${mode === 'light' ? 'var(--blue-blue-20)' : 'var(--white-40)'}`,
						},
						'&.Mui-focused fieldset': {
							border : `solid 1px ${mode === 'light' ? 'var(--blue-blue-60)' : 'var(--white)'}`,
						},
						caretColor : 'var(--blue-blue)',
					}		
				}
			},

			MuiTypography : {
				colorPrimary : {
					color : mode === 'light' ? 'var(--blue-blue)' : 'var(--dodger-blue)' ,
				},
				colorSecondary : {
					color : mode === 'light' ? 'var(--greyblue)' : 'var(--white-40)'//small subtext
				},
				colorTextPrimary : {
					color : mode === 'light' ? 'var(--black)' : 'var(--white)' //default typography variant
				},
				colorTextSecondary : {
					color : mode === 'light' ? 'var(--light-grey-blue)' : 'var(--grey)', //	category subtext
				},
				colorInherit : {
					color : mode === 'light' ? 'var(--dark-grey)' : 'var(--white-40)',  //disabled color
				},
				colorError : {
					color : 'var(light-red)'
				}
			},

			MuiList : {
				root : {
					'&:last-child' : {
						borderBottom : 'none',
					}
				},
				padding : {
					paddingTop    : '0px',
					paddingBottom : '0px',
					paddingLeft   : '15px',
					paddingRight  : '10px',
				},
			},

			MuiListItem : {
				root : {
					color : mode === 'light' ? 'var(--black)' : 'var(--white)', //default typography variant
					fontSize        : '14px',
					fontWeight      : 'normal',
					fontStretch     : 'normal',
					fontStyle       : 'normal',
					lineHeight      : '1.36',
					letterSpacing   : 'normal',
					cursor          : 'pointer',
					borderBottom    : `solid 1px ${mode === 'light' ? 'var(--light-grey-blue-40)' : 'var(--brownish-grey-40)'}`,
					'&$selected' : {
						color           : mode === 'light' ? 'var(--blue-blue)' : 'var(--dodger-blue)',
						background      : 'none',
						fontFamily      : 'var(--semibold)',
						'&:hover' : {
							background : 'none',
						}
					},
					'&:hover' : {
						background : 'none',
					}
				}
			},

			MuiTabs : {
				root : {
					borderBottom : mode === 'light' ? 'solid 1px var(--light-grey-blue-40)' : 'solid 1px var(--brownish-grey-40)',
				},
				indicator : {
					backgroundColor : mode === 'light' ? 'var(--blue-blue)' : 'var(--dodger-blue)'
				},
			},

			MuiTab : {
				root : {
					textTransform : 'none',
					color         : mode === 'light' ? 'var(--greyblue) !important' : '#535353 !important',
					fontFamily    : 'var(--regular)',
					padding       : '10px 30px',
					minWidth      : 'auto !important',
					fontWeight    : 'normal',
					'&$selected'  : {
						borderRadius    : '6px',
						color           : mode === 'light' ? 'var(--black) !important' : 'var(--white) !important',
						fontFamily      : 'var(--bold)'
					}
				}
			},

			MuiMenuItem : {
				root : {
					fontFamily     : 'var(--semibold) !important',
					fontSize       : '12px',
					fontStretch    : 'normal',
					fontStyle      : 'normal',
					lineHeight     : '1.83',
					letterSpacing  : 'normal',
					color          :  mode === 'light' ? 'var(--greyblue)' : 'var(--white-light)',
					paddingTop     : '15px',
					paddingBottom  : '15px',
					paddingLeft    : '0px',
					paddingRight   : '0px',
					borderBottom   : `solid 1px ${mode === 'light' ? '#46435F26' : '#313131'}`,
					whiteSpace     : 'break-spaces',
					wordBreak      : 'break-all',
					'&:hover' : {
						background : 'none',
					},
					'&:last-child' : {
						borderBottom : 'none',
					}
				}
			},

			MuiDivider : {
				root : {
					backgroundColor : mode === 'light' ? 'var(--light-grey-blue-40)' : 'var(--brownish-grey-40)'
				}
			},

			MuiCheckbox :  {
				root : {
					backgroundColor : 'inherit',
					'&:hover': {
						backgroundColor: 'transparent',
					},
				}
			},

			MuiDialog : {
				paper : {
					boxShadow       : mode === 'light' ? `0 3px 15px 0 var(--blue-blue-5)` : 'none',
					border          : mode === 'light' ? 'solid 1px var(--blue-blue-5)' : 'solid 1px #313131',
					backgroundColor : mode === 'light' ? 'var(--white)' : 'var(--black-light)',
					borderRadius    : '15px',
					maxHeight       : '75%', 
					boxSizing       : 'border-box',
				},
				paperScrollPaper : {
					maxHeight : '85%', 
				}
			},

			MuiCircularProgress : {
				circle : {
					strokeWidth : 1
				}
			},

			MuiLink : {
				button : {
					fontSize   : '14px',
					fontFamily : 'var(--semibold)',
				}
			},

			MuiSlider : {
				rail : {
					backgroundColor : mode === 'light' ? '#46435F80' : '#5A5A5A'
				},
				thumb : {
					width  : '13px',
					height : '13px',
				},
				track : {
					height : '4px',
				}
			},

			MuiAvatar : {
				colorDefault : {
					width           : '30px',
					height          : '30px',
					fontFamily      : 'var(--semibold)',
					fontSize        : '12px',
					backgroundColor : mode === 'light' ? 'var(--greyblue)' : 'var(--brownish-grey)',
					color           : mode === 'light' ? 'var(--black)' : 'var(--white)',
				}
			},

			MuiMobileStepper : {
				root : {
					padding        : '0px',
					justifyContent : 'center',
				},
				dots : {
					alignItems : 'center',
				},
				dot : {
					height     : '4px',
					width      : '4px',
					margin     : '0px 10px 0px 0px',
					'&:last-child' : {
						margin : '0px',
					} 
				},
				positionBottom : {
					position : 'inherit',
				},
				dotActive : {
					height          : '10px',
					width           : '10px',
					border          : mode === 'light' ? '1px solid var(--blue-blue)' : '1px solid var(--dodger-blue)',
					boxSizing       : 'border-box',
					padding         : '2px',
					background      : 'transparent',
					backgroundColor : 'none',
					'&::after' : {
						content         : '" "',
						width           : '4px',
						height          : '4px',
						backgroundColor : mode === 'light' ? colors.blueBlue : colors.dodgerBlue,
						display         : 'block',
						borderRadius    : '100%',
					}
				}
			},

			MuiTooltip : {
				tooltip : {
					fontSize   : '11px',
					fontFamily : 'var(--regular)',
				},
			}
		},

		...defaultProps,
	};
};

const defaultProps = {
	props: {
		MuiTypography: {
			color: 'textPrimary',
		},
		MuiButton : {
			color : 'primary'
		},
	},	
};

export default theme;
