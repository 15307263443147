import React, { useState, useEffect } from 'react';
import { Redirect, Route }            from 'react-router-dom';
import { useDispatch, useSelector }   from 'react-redux'; 

import { update }                     from 'redux/slices/login'; 
import { storageKey }                 from 'common/config';
import paths                          from 'navigations/path';
import localStore                     from 'libs/localStore';

function PrivateRoute ({ component : Component, ...props }) {

	const dispatch   = useDispatch ();
	const loginStore = useSelector (state => state.login);

	const [loading, setLoading]                 = useState (true);
	const [isAuthenticated, setIsAuthenticated] = useState (false);

	useEffect (() => {
		/*For normal navigations*/

		if (loginStore.loggedIn) {
			setLoading (false);
			setIsAuthenticated (true);	
			return;
		}

		let profile             = localStore.get(storageKey.profile);
		let { name, image, id } = profile;

		if (!name || !id) {
			setLoading (false);
			return;
		}

		/*When the user directly loads the protected routes, we will check with the localstorage, if name exists or not*/

		let data = {
			name     : name,
			image    : image ? image : '',
			loggedIn : true,
		}; 
		dispatch (update (data));
		setLoading (false);
		setIsAuthenticated (true);
	}, []);

	if (loading) {

		/*TODO 
		 *Add some loader page here
		 * */
		return (
			<div></div>
		);
	}

	const render = (props) => {
		if (isAuthenticated) {
			return (
				<Component 
					{ ...props } 
				/>
			);
		}
		return (
			<Redirect 
				to = {{
					pathname : paths.login,
					state    : { from : props.location }
				}}
			/>
		);
	};

	return (
		<Route { ...props } render = { render } />
	);
}

export default PrivateRoute;
