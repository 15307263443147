import React, { useEffect, useState, useRef }                             from 'react';
import { useDispatch, useSelector, useStore }                             from 'react-redux';
import { useHistory }                                                     from 'react-router-dom';
import { useTranslation }                                                 from 'react-i18next';
import Grid                                                               from '@material-ui/core/Grid';
import Typography                                                         from '@material-ui/core/Typography';
import { toast }                                                          from 'react-hot-toast';

import { clearEventStore }                                                from 'redux/slices/event';
import { addCurrentUser, initAllUser, updateAvatar, clearUserStore }      from 'redux/slices/user';
import { removeAttendee }                                                 from 'redux/slices/user';
import { initCommentResource, updateApprovedComment }                     from 'redux/slices/comment'; 
import { updateRejectedComment, clearCommentStore }                       from 'redux/slices/comment'; 
import { initCommentSettings, initPollSettings, initMicSettings }         from 'redux/slices/settings';
import { updateSettings, clearSettingsStore }                             from 'redux/slices/settings';
import { addNewPollingData, updatePreviousPolls }                         from 'redux/slices/polling';
import { clearPollingData, updatePollState, clearPollingStore }           from 'redux/slices/polling';
import { updatePollResult, handlePollShareResult }                        from 'redux/slices/polling';
import { initMicResource, clearMicStore}                                  from 'redux/slices/mic';
import { updateRequestPosition, approveRequest }                          from 'redux/slices/mic';
import { handleOffer, handleIce }                                         from 'redux/slices/webrtc';
import { handlePermit, handleReqCancel,handleReqEnd }                     from 'redux/slices/webrtc';
import { updateLayout, initLayoutResource, resetLayoutStore }             from 'redux/slices/layout';
import { addNewNotify, addNewBadge }                                      from 'redux/slices/common';
import useApis                                                            from 'common/apis';
import utils                                                              from 'common/utils';
import useAvatarUtils                                                     from 'common/avatar';
import { storageKey, isOnline }                                           from 'common/config';
import { getApiErrorMsg, isNetworkError, defaultError, networkError }     from 'common/error';
import { logContainer }                                                   from 'libs/logger';
import { Create, authenticate, Terminate }                                from 'libs/socket'; 
import localStore                                                         from 'libs/localStore';
import { connectMediaServer, leaveMediaServer }                           from 'libs/stella/stellaConnect';
import Logo                                                               from 'assets/images/logo-full.png';
import { usePageLoader }                                                  from 'components/atoms/PageLoader';
import path                                                               from 'navigations/path';
import { updateActivePollState } from '../redux/slices/polling';

const log = logContainer('pages/Connect');

const RECONNECTION_INTERVAL = 3000;
const RECONNECTION_RETRY_THRESHOLD = 20;

function Connect () {

	const { t }        = useTranslation ();
	const redirect     = useHistory ();
	const store        = useStore ();
	const apis         = useApis ();
	const dispatch     = useDispatch ();
	const avatarUtils  = useAvatarUtils ();
	const eventStore   = useSelector (state => state.event);
	const loginStore   = useSelector (state => state.login);
	const {pageLoader} = usePageLoader ();

	const { live } = eventStore;

	const { name, image } = loginStore;   

	const [loadingMsg, setLoadingMessage] = useState ('');
	const isConnected                     = useRef (false);
	const retryCount                      = useRef (0);

	useEffect (() => {
		let isOnboarded = localStore.get (storageKey.onboarded);
		if (!isOnboarded) {
			redirect.push (path.eventLanding);
			return;
		}
		startDaemon ();
		return () => {
			isConnected.current = true;
		};
	}, []);

	const startDaemon = async() =>{
		if (isConnected.current) {
			return;
		}

		while (!isConnected.current) {
			log.debug ('trying to reconnect...');
			try {
				retryCount.current = retryCount.current+1;
				await init();
				isConnected.current = true;
				pageLoader(false);
			}
			catch (e) {
				if (retryCount.current > RECONNECTION_RETRY_THRESHOLD) {
					clearEventResource ();
					dispatch (resetLayoutStore ());
					if (typeof Terminate ===  'function') { //Terminate will only call when the socket is connected
						Terminate ();
					}
					isConnected.current = true; // Stop retrying break while loop
					if (isOnline) {
						redirect.push (path.onlineLanding);
					}
					else {
						redirect.push(path.landing);
					}
				} 
				else {
					await utils.sleep(RECONNECTION_INTERVAL);
				}
			}
		}
	};

	const init = async () => {
		try {
			setLoadingMessage ('Fetching the atom details...');

			if (isOnline) {
				log.info ('Joining event as online participant');
				let cmmsInfo    = await apis.getCMMSInfo(live.code);
				let sessionInfo = await apis.getOnlineSessionInfo(cmmsInfo, live.code);

				await switchConnect (sessionInfo, cmmsInfo);
				await connectToMediaServer (isOnline);
				return;
			}

			let details = await apis.getAtomInfo ();	
			log.info ({atom : details}, 'atom details get ok');

			let atomState = details.state;
			if (atomState.current === 'idle') {
				log.error ('There is no event running on this atom');
				return;
			}

			if (atomState.current === 'errored') {
				log.error ('Atom is in errored state');
				return;
			}

			let sessionDetails = details.session;
			setLoadingMessage ('Connecting to event...');

			await connectToSession (sessionDetails);
			await connectToMediaServer ();
		}
		catch (err) {
			log.error (err, 'error in connecting to event');
			let msg = defaultError;

			if (isNetworkError (err)) {
				msg = networkError;	
			}
			if (err && err.error && err.error) {
				let errName = err.error.name;
				msg = getApiErrorMsg('event-pa-join-code' , errName);
			}
			if (err && err.response && err.response.data) {
				let errName = err.response.data.name;
				msg = getApiErrorMsg('event-pa-join-code' , errName);
			}
			if (retryCount.current === RECONNECTION_RETRY_THRESHOLD) {
				toast.error (t(msg));
				setLoadingMessage ('');
			}
			throw err;
		}
	};

	const connectToSession = async (sessionDetails) => {
		log.debug ({sessionDetails}, 'session details get ok');
		let imgInfo = utils.getImageInfo (image);
		let profile = localStore.get (storageKey.profile);

		let payload = {
			uuid    : profile.id,
			eventId : live.id,
			user    : name,
			avatar  : {data : imgInfo.data, mime : imgInfo.meme, contentType : imgInfo.contentType},
		};
		let socketUrl = `${sessionDetails.proto}://${utils.hostname ()}:${sessionDetails.port}/bws/session/${payload.eventId}`;
		let options = {
			type : 'local',
			socketUrl,
			onReq,
			onInfo,
			onClose,
		};

		log.info ({payload, options}, 'connection params for websocket');
		await Create (payload, options);
	};

	const switchConnect = async (sessionInfo, cmmsInfo) => {
		log.debug ({sessionInfo, cmmsInfo}, 'session details get ok');
		let imgInfo     = utils.getImageInfo (image);
		let profile     = localStore.get (storageKey.profile);

		const payload   = {
			uuid     : profile.id,
			event_id : sessionInfo.token,
			user     : name,
			avatar   : {data : imgInfo.data, mime : imgInfo.meme, contentType : imgInfo.contentType},
		};

		let  socketUrl = `${sessionInfo.session.proto}://${cmmsInfo.host}:${sessionInfo.session.port}/session?token=${sessionInfo.token}`;
		const options = {
			type         : 'online',
			pingInterval : 10000,
			socketUrl    : socketUrl,
			onInfo,
			onReq,
			onClose,
		};

		await Create(payload, options);
	};

	const connectToMediaServer = async (isOnline = false) => {
		let stellaConfig = live.stellaConfig;
		log.debug ({stellaConfig}, 'stellaConfig get ok');
		await connectMediaServer (stellaConfig, isOnline);
		redirect.push (path.eventLanding);	
	};

	const socketReAuth  = async () => {
		let profile = localStore.get (storageKey.profile);
		let imgInfo = utils.getImageInfo (image);
		let payload = {
			eventId : live.id,
			uuid    : profile.id,
			user    : name,
			avatar  : {data : imgInfo.data, mime : imgInfo.meme, contentType : imgInfo.contentType},
		};

		await authenticate (payload);
	};

	const onInfo = async (from, to, id, data) => {
		log.info (from, to, id, data);
		let avatar = store.getState().user.avatar;  //get the current snapshot of the avatar from userstore.

		switch (id) {

			case 'session-info' : {
				if (!checkFromMaster(from)) {
					return;
				}
				initCurrentUser (data.user);
				dispatch (initAllUser (data.attendees));
				break;
			}

			case 'resource-init' : {
				if (!checkFromMaster(from)) {
					return;
				}
				if (!data.info) {
					return;
				}

				switch (data.name) {
					
					case 'atom' : 
						dispatch (initMicResource ({data}));
						dispatch (initMicSettings(data.info?.settings));
						break;

					case 'comment' :
						dispatch (initCommentResource (data.info));
						dispatch (initCommentSettings (data.info.settings));
						initCommentAvatar (data.info);
						break;

					case 'polling' :  
						dispatch (addNewPollingData (data.info.active_poll));
						dispatch (updatePollState ({ resultShared : data.info.share_result, submitted : data.info.my_state}));
						dispatch (initPollSettings (data.info.settings));
						dispatch (updatePreviousPolls (data.info.poll_list));
						if (data.info.active_poll && !data.info.my_state) {
							dispatch(addNewBadge('poll'));
							dispatch(addNewNotify({ open: true, title: t('modal:title.poll_launched'), tab: 'poll', desc: t('modal:body.poll_launched') }));
						}
						break;

					case 'layout' : {
						dispatch (initLayoutResource(data.info));
						let _streams = data.info?.streams;
						if (_streams['participant-audio']) {
							let vcId = _streams['participant-audio'].custom?.vc_id;
							if (vcId)
								newJohnnyAvatar([{ vc_id: vcId }]);
						}
						break;
					}
				}
				break;
			}

			case 'approve-comment' : {
				let vcIds = data
					.filter (user => !avatar[user.vc_id])
					.map (user => ({ vc_id : user.vc_id }));

				newJohnnyAvatar (vcIds);
				dispatch (updateApprovedComment (data));
				dispatch (addNewBadge ('comment'));
				break;
			}

			case 'reject-comment' :
				dispatch (updateRejectedComment (data));
				break;

			case 'send-comment' :
				if (!avatar[data.vc_id]) {
					newJohnnyAvatar ([{vc_id : data.vc_id}]);
				}
				if (data.auto_approve) {
					dispatch (addNewBadge ('comment'));
					dispatch (updateApprovedComment ([data]));
				}
				break;

			case 'new-johnny' :
				dispatch (initAllUser (data));
				newJohnnyAvatar (data);
				break;

			case 'johnny-go-went-gone' :
				dispatch (removeAttendee (data));
				break;

			case 'poll-publish' :
				dispatch (addNewPollingData (data.active_poll));
				dispatch (updatePollState ({ resultShared : data.share_result, submitted : false}));
				dispatch (addNewNotify ({open : true, title : t('modal:title.poll_launched'), tab : 'poll', desc : t('modal:body.poll_launched')}));
				dispatch (addNewBadge ('poll'));
				break;

			case 'poll-end' :
				dispatch (clearPollingData (data));
				dispatch (addNewNotify ({open : true, title : t('modal:title.poll_ended'), tab : 'poll', desc : t('modal:body.poll_ended')}));
				break;

			case 'poll-result-show' : 
				dispatch (handlePollShareResult (data));
				if (data.share_result) {
					dispatch (addNewNotify({ open: true, title : t('modal:title.poll_shared'), tab: 'poll', desc : t('modal:body.poll_shared')}));
					dispatch (addNewBadge ('poll'));
				}
				break;

			case 'poll-change-view' : 
				dispatch (updateActivePollState ({viewType : data.poll_view_type}));
				break;

			case 'poll-answer' : 
				dispatch (updatePollResult (data));
				break;

			case 'event-stopped' : 
				onEventEnd ();
				break;

			case 'speak-request-position' : {
				dispatch(updateRequestPosition(data.position));
				break;
			}

			case 'speak-request-approve' : {
				dispatch(approveRequest());
				break;
			}

			case 'speak-request-cancel' :
				dispatch (handleReqCancel ());
				break;

			case 'speak-request-end' :
				await dispatch (handleReqEnd()).unwrap();
				if (data?.is_timed_out) {
					/*
					 * TODO
					 * This may change in future, showing alert for request again as of now
					 * */
					//redirect.push (path.eventLanding);
				} 
				else {
					toast.success (t('modal:desc.mod_end_req'));
					redirect.push (path.eventLanding);
				}
				break;

			case 'webrtc-ice-atom' : 
				dispatch(handleIce({ data }));
				break;

			case 'set-layout' :
				dispatch (updateLayout (data));
				break;

			case 'server-disconnected' : {
				if (data.error === 'END_ON_STELLA_DISCONNECT') {
					toast.error(t('notify:error.stella_connection_lost'));
				}
				break;
			}

			case 'trunk-connected' :
			case 'master-atom-connected' : {
				if (data.reauthenticate) {
					clearEventResource ();
					socketReAuth ().then(() => pageLoader(false));
				}
				break;
			}
			case 'trunk-disconnected' :
				if (live.atomSelf === live.atomMaster) {
					return;
				}
			// eslint-disable-next-line: no-fallthrough
			case 'master-atom-disconnected' : {
				pageLoader (true, {loaderText : t('in_event:connect.reconnect'), showElements : ['eventLandingTopBar']});
				break;
			}

			case 'update-settings' :
				dispatch (updateSettings ({data, settings : to}));
				break;
		}
	};

	const onReq = async (from, to, id, data) => {
		log.info ({ to : to,  data : data }, "new request recieved");
		try {
			let response  = null;
			switch (id) {
				case 'webrtc-offer' : {
					try {
						let answer = await dispatch (handleOffer({ data })).unwrap();
						response = {
							type : 'ANSWER',
							data : {
								sdp: { 
									type: 'answer', 
									...answer 
								},
							},
						};
						return response;
					}
					catch(err) {
						log.error ({err}, 'error in web-rtc offer');
						throw err;
					}
				}
				case 'permit-to-speak' :
					await dispatch(handlePermit({ autoMicConf : data.auto_approve_conf })).unwrap();
					break;
			}
		}
		catch (err) {
			log.error("on Req", id, err);
			throw err;
		}
	};

	const onClose = (error) => {
		log.error ({error}, 'websocket connection closed');
		try {
			// Check if its a normal close or abnormal close 
			// if its abnormal close then we need start daemon to try reconnecting event
			if (error.status === 1006) { // 1006 is the code for abnormal close
				isConnected.current = false;
				startDaemon ();
				leaveMediaServer();
				pageLoader (true, {loaderText : t('in_event:connect.reconnect')});
				clearEventResource();
			}
		}
		catch (err) {
			log.error ({err});
			return;
		}
	};

	/*this fun needs all init comment resource and current avatars list from store*/
	const initCommentAvatar = async (comments) => {
		let avatar = store.getState().user.avatar;
		let newAvatars;
		try {
			newAvatars = await avatarUtils.initCommentAvatar (comments, avatar);
			log.info ('new avatars for comments fetched ok');
		}
		catch (err) {
			log.error (err, 'error fetching avatars for comments');
			return;
		}
		if (!newAvatars) {
			return;
		}
		dispatch (updateAvatar (newAvatars));
	};

	/*this func needs array of users with atleast vc_id key*/
	const newJohnnyAvatar = async (users) => {
		let avatar = store.getState().user.avatar;
		let newAvatars;
		try {
			newAvatars = await avatarUtils.newJohnnyAvatar (users, avatar);
			log.info ('new avatars for johnny fetched ok', {users});
		}
		catch (err) {
			log.error ({err}, 'error fetching avatars for comments');
			return;
		}
		if (!newAvatars) {
			return;
		}
		dispatch (updateAvatar (newAvatars));
	};

	const initCurrentUser = (user) => {
		let avatar = store.getState().user.avatar;
		let vcId   = user.vc_id;

		if (avatar[vcId] && avatar[vcId].checksum === user.checksum) {
			dispatch (addCurrentUser (user));
			return;
		}
		
		let userImage      = store.getState().login.image;
		let imgInfo        = utils.getImageInfo (userImage);
		let newAvatars  = {
			[vcId] : {
				data        : imgInfo.data, 
				mime        : imgInfo.meme, 
				contentType : imgInfo.contentType, 
				checksum    : user.checksum
			}
		};

		dispatch (addCurrentUser (user));
		dispatch (updateAvatar (newAvatars));
	};

	// check if message is from master atom or not
	const checkFromMaster = (from) => {       
		let event = eventStore;
		if (event.isSlaveAtom && event.isOnline) {
			let masterAtom = event.masterAtom;
			let fromMsgArr = from.split (':');

			let msgEntity = fromMsgArr[1];

			if (!msgEntity)
				return false;

			let fromAtom = msgEntity.split ('.')[0];
			if (fromAtom !== masterAtom)
				return false;
		}
		return true;
	};

	const onEventEnd = () => {
		clearEventResource ();	
		dispatch (clearEventStore    ());
		Terminate ();
		toast.success (t('alert:title.event_ended'));
		if (isOnline) {
			redirect.push (path.onlineLanding);
			return;
		}
		redirect.push (path.landing);
	};

	const clearEventResource = () => {
		dispatch (clearUserStore     ());
		dispatch (clearSettingsStore ());
		dispatch (clearCommentStore  ());
		dispatch (clearPollingStore  ());	
		dispatch (clearMicStore      ());
	};

	return (
		<Grid className = 'connect page-parent'>
			<Grid container className = 'connect page-container' justify = 'center' alignItems = 'center'>
				<Grid item xs = { 12 } container justify = 'center'>
					<img src = { Logo } height = { 300 } width =  { 300 }/>
				</Grid>
				<Typography variant = 'h4'> {loadingMsg} </Typography>
			</Grid>
		</Grid>
	);
}

export default Connect;