// create the image with a src of the base64 string
const createImage = (url) =>
	new Promise ((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', error => reject(error));
		image.setAttribute ('crossOrigin', 'anonymous');
		image.src = url;
	});

export const getCroppedImg = async (imageSrc, crop) => {
	const image  = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const ctx    = canvas.getContext('2d');

	/* setting canvas width & height allows us to 
    resize from the original image resolution */
	canvas.width  = 300;
	canvas.height = 300;

	ctx.drawImage(
		image,
		crop.x,
		crop.y,
		crop.width,
		crop.height,
		0,
		0,
		canvas.width,
		canvas.height
	);

	return canvas.toDataURL('image/jpeg');
};
