import React              from 'react';
import { useTranslation } from 'react-i18next';
import Draggable          from 'react-draggable';
import Grid               from '@material-ui/core/Grid';
import { useTheme }       from '@material-ui/core/styles';
       
import IconButton         from 'components/atoms/IconButton';
import DMDrag             from 'assets/icons/dark/drag.svg';
import LMDrag             from 'assets/icons/light/drag.svg';

import 'styles/components/molecules/liveControl.css';

function LiveControl ({audioEnabled, videoEnabled, screenSharing, toggleAudio, toggleVideo, handleShareScreen, handleExitLive, screenDisabled, videoDisabled }) {

	const theme = useTheme().palette.type;
	const { t } = useTranslation ();

	return (
		<Draggable handle = '.liveControl-drag'>
			<Grid className = 'liveControl' id = 'liveCall_liveControl'>
				<Grid>
					<img src = { theme === 'light' ? LMDrag : DMDrag } className = 'liveControl-drag'/>
				</Grid>
				<Grid>
					<IconButton 
						color     = { audioEnabled ? 'primary' : 'secondary' }
						icon      = { audioEnabled ? 'MicBig' : 'MicBigDisabled' }
						onClick   = { toggleAudio }
						size      = { 50 }
						id        = 'liveCall_mute'
					/>
				</Grid>
				<Grid>
					<IconButton 
						color     = { videoEnabled ? 'primary' : 'secondary' }
						icon      = { videoEnabled ? 'Video' : 'VideoDisabled' }
						onClick   = { toggleVideo }
						size      = { 50 }
						id        = 'liveCall_video'
						disabled  = { videoDisabled }
						tooltip   = { videoDisabled ? t('alert:live_call.video_disabled') : null }
						placement = 'top'
					/>
				</Grid>
				<Grid>
					<IconButton 
						color     = { screenSharing ? 'primary' : 'secondary' }
						icon      = { screenSharing ? 'ScreenDisabled' : 'ScreenShare' }
						onClick   = { handleShareScreen }
						size      = { 50 }
						id        = 'liveCall_share'
						disabled  = { screenDisabled }
						tooltip   = { screenDisabled ? t('alert:live_call.content_disabled') : null }
						placement = 'top'
					/>
				</Grid>
				<Grid>
					<IconButton 
						color   = 'inherit'
						icon    = "CallEnd"
						onClick = { handleExitLive }
						size    = { 50 }
						id      = 'liveCall_exit'
					/>
				</Grid>
			</Grid>
		</Draggable>
	);
}

export default LiveControl;
