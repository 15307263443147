import React, { useState }          from 'react';
import PropTypes                    from 'prop-types';
import { useTranslation }           from 'react-i18next';
import { useSelector }              from 'react-redux';
import TextField                    from '@material-ui/core/TextField';
import InputAdornment               from '@material-ui/core/InputAdornment';

import Button                       from 'components/atoms/Button';

import 'styles/components/molecules/commentTextBox.css';

const i18nNamespaces = ['in_event'];

function CommentTextBox ({ sendComment }) {

	const { t } = useTranslation (i18nNamespaces);

	const settingsStore = useSelector (state => state.settings);

	const commentSettings = settingsStore.comment;

	const [comment, setComment] = useState ('');
	const [error, setError] = useState (false);

	const handleCommentChange = (event) => {
		let {value} = event.currentTarget;
		setComment (value);
	};

	const onSendComment = () => {
		if (!comment || comment.trim () === '') {
			setError (true);
			return;
		}
		sendComment (comment);
		setComment ('');
	};

	const handleKeyPress = (event) => {
		if (event.keyCode === 13) {
			onSendComment ();
		}
	};

	const placeholder = () => {
		if (!commentSettings.allowComments) {
			return t('comment.send_message_disabled');
		}
		if (commentSettings.autoApproveComments) {
			return  t('comment.send_message_write');		
		}
		return `${t('comment.send_message_write')}\n${t('comment.send_message_moderator')}`; 
	};

	const sendButton = 
		<InputAdornment position = 'end' onClick = { onSendComment }> 
			<Button color = 'primary' disabled = { comment ? false : true } variant = 'contained' size = 'small' className = 'commentTextBox-send-btn'> Send </Button> 
		</InputAdornment>;

	return (
		<TextField 
			className   = 'commentTextBox'
			placeholder = { placeholder () }
			fullWidth   = { true }
			variant     = 'outlined'
			multiline   = { true }
			error       = { error }
			value       = { comment }
			rows        = { 2 }
			onChange    = { handleCommentChange }
			onKeyUp     = { handleKeyPress }
			disabled    = { !commentSettings.allowComments }
			InputProps  = {{
				endAdornment : sendButton,
			}}
			inputProps  = {{
				maxLength : 256
			}}
		/>
	);
}

CommentTextBox.propTypes = {
	sendComment : PropTypes.func.isRequired,
};

export default CommentTextBox;
