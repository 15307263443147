import React              from 'react';
import moment             from 'moment';
import PropTypes          from 'prop-types';
import { useSelector }    from 'react-redux';
import { useTranslation } from 'react-i18next';

import DMClock            from 'assets/icons/dark/clock.svg';
import DMUserProfile      from 'assets/icons/dark/userProfile.svg';

function CommentDisplay ({ comments }) {
	const localize = useTranslation().t;
	const userStore    = useSelector (state => state.user);
	const loginStore   = useSelector (state => state.login);

	const getAvatar = (vcId) => {
		/* const getAvatar: (vcId: any) => any  object*/
		let avatar =  userStore.avatar;
		let imgInfo = avatar[vcId];
		if (imgInfo) {
			return getImgSrc (imgInfo);
		}
		let currentUser = userStore.currentUser;
		if (currentUser.vcId === vcId) {
			return loginStore.image;
		}
	};

	const getImgSrc = (imgInfo) => {
		if (!imgInfo || !imgInfo.data) {
			return;
		}
		return `data:image/jpeg;base64,${imgInfo.data}`;
	};

	return (
		<>
			<div className = 'commentDisplay-header'>
				{ localize ('display:comment.live_comments') }
			</div>
			<div className = 'commentDisplay-container'>
				{comments.map (comment => (
					<div
						key = { comment.uuid }
						className = 'commentDisplay-commentCard'
					>
						<div className = 'commentDisplay-commentCard-top-container'>
							<div className = 'commentDisplay-commentCard-name-avatar-container'>
								<img
									src = { getAvatar(comment.vcId) || DMUserProfile }
									className = 'commentDisplay-commentCard-avatar'
								/>
								<div className = 'commentDisplay-commentCard-name'> {comment.user} </div>
							</div>
							<div>
								<div className = 'commentDisplay-commentCard-time'>
									<img
										src = { DMClock }
										className = 'commentDisplay-commentCard-clock'
										alt = "clock"
									/>
									{moment(comment.ts).format('h:mm a')}
								</div>
							</div>
						</div>
						<div className = 'commentDisplay-commentCard-comment'>{comment.comment} </div>
					</div>
				))}
			</div>
		</>
	);
}

CommentDisplay.propTypes = {
	comments : PropTypes.array.isRequired,
};

export default CommentDisplay;