import React       from 'react';
import PropTypes   from 'prop-types';
import Grid        from '@material-ui/core/Grid';
import Drawer      from '@material-ui/core/Drawer';

import 'styles/components/molecules/eventLandingSideBar.css';

function EventLandingSideBar ({ open, children }) {

	return (
		<Drawer
			variant    = "persistent"
			anchor     = 'right'
			open       = { open }
			className  = 'eventLandingSideBar'
			ModalProps = {{
				keepMounted: true
			}}
		>
			<Grid className = 'eventLandingSideBar-container'>
				{children}
			</Grid>
		</Drawer>
	);
}

EventLandingSideBar.propTypes = {
	open : PropTypes.bool.isRequired,
};

export default EventLandingSideBar;
