import React, { useState }    from 'react';
import PropTypes              from 'prop-types';
import { useTranslation }     from 'react-i18next';
import Grid                   from '@material-ui/core/Grid';
import Dialog                 from '@material-ui/core/Dialog';
import Typography             from '@material-ui/core/Typography';

import IconButton             from 'components/atoms/IconButton';
import Pie                    from 'components/atoms/Pie';
import Bars                   from 'components/atoms/Bars';          
import Button                 from 'components/atoms/Button';
import { graphColor }         from 'styles/theme/colors';

import 'styles/components/molecules/pollModal.css';

const i18nNamespaces = ["polls"];

function PollModal ({ question, options, loading, submitPoll, pollType, results, votes, state, isSubmitted, viewType, onGraphChange, resultShared, currentUser, onClose}) {
	
	const { t }       = useTranslation (i18nNamespaces);

	const [selected, setSelected] = useState ([]);

	const handlePollSubmit = (index, isSelected) => {
		if (state === 'published' && isSubmitted) {
			return;
		}
		/*Check if pole is in done state or already submitted*/
		if (state === 'done') {
			return;
		}

		if (pollType === 'multiple_answer') {
			isSelected ? setSelected ([...selected, index]) : setSelected (selected.filter (s => s !== index));
			return;
		}

		if (pollType === 'mcq' || pollType === 'yesno') {
			isSelected ? setSelected ([index]) : setSelected ([]);
			return;
		}
	};

	const graphIcon = () => {
		/*PA can only submit answer in bar graph*/
		if (!resultShared || !isSubmitted) {
			return;
		}
		return (
			<>
				<span className = { `pollModal-graph-icon ${viewType === 'horizontal-bar' ? 'selected' : ''}` }>
					<IconButton 
						size    = { 30 }
						icon    = { viewType === 'horizontal-bar' ? 'BarSelected' : 'Bar' }
						onClick = { () => onGraphChange ('horizontal-bar') }
					/>
				</span>
				<span className = { `pollModal-graph-icon ${viewType === 'pie' ? 'selected' : ''}` }>
					<IconButton 
						size    = { 30 }
						icon    = { viewType === 'pie' ? 'PieSelected' : 'Pie' }
						onClick = { () => onGraphChange ('pie') }
					/>
				</span>
			</>
		);
	};

	const renderGraph = () => {
		if (!isSubmitted || !resultShared || viewType === 'horizontal-bar') {
			return barGraph;
		}
		return pieGraph;
	};

	const submittedOption = () => {
		if (!isSubmitted) {
			return;
		}
		let _selected = results.filter (result => result.vcId === currentUser);
		return _selected;
	};

	const getSelected = (index) =>{
		let _sel = selected.findIndex (opt => opt === index);
		if (_sel < 0) 
			return false;
		return true;
	};

	const pieData =  Object.keys (votes).map (v => {return {x : v, y : votes[v], label : options[v]};});

	const pieGraph =
		<Pie data    = { pieData }
			colors   = { graphColor }
			total    = { results.length }
			options  = { options }
			selected = { submittedOption () }
		/>;

	const barGraph = options.map ((opt, index) => (
		<Bars 
			title            = { opt }
			color            = { graphColor[index] }
			key              = { index }
			index            = { index }
			onClick          = { handlePollSubmit }
			percentage       = { votes[index] }
			isSelected       = { selected.includes(index) }
			multiResponse    = { pollType === 'multiple_answer' && state === 'published' }
			handlePollSubmit = { handlePollSubmit } 
			isSubmitted      = { isSubmitted }
			resultShared     = { resultShared }
			selected         = { submittedOption () }        
			checkboxOptSel   = { getSelected(index) }
		/>
	));

	const submit = state === 'published' && !isSubmitted && 
	<Button 
		variant    = 'outlined'
		color      = 'primary'
		fullWidth  = { true }
		className  = 'mt-xxl' 
		loading    = { loading } 
		disabled   = { selected.length ? false : true } 
		onClick    = { () => submitPoll (selected) }
	> {t('body.submit')} 
	</Button>;

	if (!isSubmitted) {
		return (
			<Grid className = 'mt-xl'>
				<Typography variant = 'subtitle1' className = 'pollCard-question'> { question }  </Typography>
				<Grid className = 'pollModal-graph-container'>
					{renderGraph ()}
				</Grid>
				{submit}
			</Grid>
		);
	}

	return (
		<Dialog open = { true } className = 'pollModal'>	
			<Typography variant = 'body2' color = 'secondary' className = 'pollModal-question'> {t('modal.question')} </Typography>
			<Typography variant = 'h3' className = 'pollModal-question-body'> { question }  </Typography>
			{resultShared ? 
				<Grid>
					<Grid>
						<Typography  variant = 'subtitle1' color = 'secondary'> {t('body.total_votes', {counter:results.length})} </Typography>
					</Grid>
					<Grid container justify = 'center' alignItems = 'center'>
						<Grid item xs = { 7 }>
							<Typography  variant = 'subtitle1' color = 'secondary'> {viewType === 'pie' ? t('body.view_pie_chart') : t('body.view_bar_chart')} </Typography>
						</Grid>
						<Grid item xs = { 5 } container justify = 'flex-end'>
							{graphIcon ()}
						</Grid>
					</Grid>
				</Grid> 
				: null}
			<Grid className = 'pollModal-graph-container'>
				{renderGraph ()}
			</Grid>
			<Grid container justify = 'flex-end' className = 'mt-xxl'>
				<Button variant = 'outlined' color = 'primary' onClick = { () => onClose () }> {t('button:action.back_to_event')} </Button>
			</Grid> 
		</Dialog>
	);
}

PollModal.propTypes = {
	question      : PropTypes.string.isRequired,
	options       : PropTypes.array.isRequired,
	state         : PropTypes.oneOf (['published', 'done']).isRequired,
	pollType      : PropTypes.oneOf (['multiple_answer', 'mcq', 'yesno']),
	viewType      : PropTypes.oneOf (['horizontal-bar', 'pie']),
	submitPoll    : PropTypes.func.isRequired,
	results       : PropTypes.array.isRequired,
	resultShared  : PropTypes.bool.isRequired,
	onGraphChange : PropTypes.func.isRequired,
};

export default PollModal;
