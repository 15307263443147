/*
 *
 *App typography schems: https://app.zeplin.io/project/60d09d7507da39161fa0e7ee/screen/60d09ec9a7090a116eef289f
 * */

const typography = {

	/*Large Page Headers*/

	h5 : {
		fontFamily    : 'var(--bold)',
		fontSize      : '24px',
		fontStretch   : 'normal',
		fontStyle     : 'normal',
		lineHeight    : 1.38,
		letterSpacing : 'normal',
		textAlign     : 'left',
		wordBreak     : 'break-word',
	},

	/*Sub Headings 1*/

	h4 : {
		fontFamily     : 'var(--bold)',
		fontSize       : '18px',
		fontStretch    : 'normal',
		fontStyle      : 'normal',
		lineHeight     : 1.24,
		letterSpacing  : 'normal',
		textAlign      : 'left',
		wordBreak      : 'break-word',
	},

	/*Sub headings 2*/	

	h3 : {
		fontFamily     : 'var(--bold)',
		fontSize       : '16px',
		fontStretch    : 'normal',
		fontStyle      : 'normal',
		lineHeight     : 1.4,
		letterSpacing  : 'normal',
		textAlign      : 'left',
		wordBreak      : 'break-word',
	},

	/*Subtext*/

	h2 : {
		fontFamily     : 'var(--regular)',
		fontSize       : '14px',
		fontWeight     : 'normal',
		fontStretch    : 'normal',
		fontStyle      : 'normal',
		lineHeight     : 1.6,
		letterSpacing  : 'normal',
		textAlign      : 'left',
		wordBreak      : 'break-word',
	},

	/*Subtext 2*/

	h1 : {
		fontFamily     : 'var(--regular)',
		fontSize       : '13px',
		fontWeight     : 'normal',
		fontStretch    : 'normal',
		fontStyle      : 'normal',
		lineHeight     : 1.38,
		letterSpacing  : 'normal',
		textAlign      : 'left',
		wordBreak      : 'break-word',
	},

	/*Body Copy*/

	body2 : {
		fontFamily     : 'var(--semibold)',
		fontSize       : '12px',
		fontWeight     : 'normal',
		fontStretch    : 'normal',
		fontStyle      : 'normal',
		lineHeight     : 1.42,
		letterSpacing  : 'normal',
		textAlign      : 'left',
		wordBreak      : 'break-word',
	},

	/*Button Text*/

	button : {
		fontFamily     : 'var(--semibold)',
		fontSize       : '14px',
		fontStretch    : 'normal',
		fontStyle      : 'normal',
		lineHeight     : 1.36,
		letterSpacing  : 'normal',
		textAlign      : 'center',
	},

	/*variant if h2 with semibold font family*/

	subtitle1 : {
		fontFamily     : 'var(--semibold)',
		fontSize       : '14px',
		fontStretch    : 'normal',
		fontStyle      : 'normal',
		lineHeight     : 1.6,
		letterSpacing  : 'normal',
		wordBreak      : 'break-word',
	}
};

export default typography;
