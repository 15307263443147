import axios from 'axios';
import i18n  from 'libs/i18n';
import utils from 'common/utils';

import { storageKey }   from 'common/config';
import LocalStore       from 'libs/localStore';

const xhr = {};

xhr.CancelToken = axios.CancelToken;

const defaultOptions = {
	timeout      : 60000,
	headers      : { 
		'Content-Type' : 'application/json',
		'Accept-Language': i18n ? i18n.languages[0] : 'en',
		'cm-uid' : LocalStore.get(storageKey.profile)?.id,
		'cm-app' : 'web-pa'
	},
	responseType : 'json',
	baseURL      : `https://${utils.hostname()}`
};

let _axios = axios.create (defaultOptions);

xhr.updateHeaders = function () {
	let newLang = i18n.languages[0];
	// eslint-disable-next-line
	console.info("XHR header updated with language: ", newLang);
	_axios.defaults.headers['Accept-Language'] = newLang;
};

xhr.get = function (url, options) {

	options = options || {};
	// eslint-disable-next-line
	return new Promise (async (resolve, reject) => {

		try {
			let _config = {
				url    : url,
				method : 'get',
				...options
			};
		
			let response = await _axios.request (_config);
			resolve (response.data);
		}
		catch (err) {
			reject (err);
		}

	});
};

xhr.download = function (url, options) {

	options = options || {};

	// eslint-disable-next-line
	return new Promise (async (resolve, reject) => {

		try {
			let _config = {
				url    : url,
				method : 'get',
				...options
			};
		
			let response = await _axios.request (_config);
			resolve (response);
		}
		catch (err) {
			reject (err);
		}

	});
};

xhr.post = function (url, postData, options) {
	options = options || {};

	// eslint-disable-next-line
	return new Promise (async (resolve, reject) => {

		try {
			let _config = {
				url              : url,
				method           : 'post',
				data             : postData,
				...options
			};
		
			let response = await _axios.request (_config);
			resolve (response);
		}
		catch (err) {
			reject (err);
		}

	});
};

xhr.delete = function (url, options) {
	options = options || {};

	// eslint-disable-next-line
	return new Promise (async (resolve, reject) => {

		try {
			let _config = {
				url    : url,
				method : 'delete',
				...options
			};
		
			let response = await _axios.request (_config);
			resolve (response.data);
		}
		catch (err) {
			reject (err);
		}

	});
};

xhr.put = function (url, putData, options) {
	options = options || {};

	// eslint-disable-next-line
	return new Promise (async (resolve, reject) => {

		try {
			let _config = {
				url              : url,
				method           : 'put',
				data             : putData,
				...options
			};
			let response = await _axios.request (_config);
			resolve (response.data);
		}
		catch (err) {
			reject (err);
		}

	});
};

export default xhr;
