/*eslint no-console: "off"*/ 

/**
 * This slice contains all the handler functions for actions related to
 * mic/speak request
 *
 * TODO: Auto Approve comments
 */
import { createAsyncThunk }             from '@reduxjs/toolkit';
import {
	sentRequest, sending, clearRequest, 
	permitRequest,isMicIdleSelector, 
	isMicActiveSelector,
	isMicProcessingSelector, 
	processingRequest,
}                                       from 'redux/slices/mic';

import WebrtcController                 from 'libs/webrtc/webrtcController';
import { sendInfo }                     from 'libs/socket';
import { getIdentity }                  from 'libs/stella/stellaConnect';
import userMedia                        from 'common/userMedia';

export const handleRequestSpeak = createAsyncThunk (
	'webrtc/requestSpeak',
	async (payload, { dispatch, getState }) => {
		let response;
		try{
			if (!getState().settings.mic?.allowRequests) {
				throw "Speak requests are disabled in the event.";
			}
			let _constraints = payload.constraints;
			let sendSpeakReq = payload.sendSpeakReq;
			await dispatch(initPeer(_constraints));
			dispatch (sending (true));
			let identity =  getIdentity ();
			response = await sendSpeakReq ({owtUserId : identity?.owt?.userId});
			dispatch (sentRequest ({ rtc_vcid: response.rtc_recv_vcid }));
		}
		catch (err) {
			dispatch (clearRequest (response));
			throw err;
		}
	}
);

export const initPeer = createAsyncThunk (
	'webrtc/start',
	async(payload, { dispatch, getState }) => {
		const sendIceCandidate = (ice) => {
			const micState = getState().mic;
			let atomId = 'user:' + micState.requestMeta?.rtc_vcid + '.atom';
			let data = {
				type: 'ICE',
				data: ice
			};
			sendInfo(atomId, 'webrtc-ice-pa', data);
		};
		await WebrtcController.openChannel({sendIceCandidate, constraints : payload});
	}
);

export const deinitPeer = createAsyncThunk (
	'webrtc/stop',
	async(payload, { dispatch, getState }) => {
		await WebrtcController.closeChannel();
	}
);

export const handleOffer = createAsyncThunk (
	'webrtc/handleOffer',
	async(payload, { dispatch, getState }) => {

		const { data } = payload;
		const micState = getState().mic;

		if (isMicIdleSelector(micState)) {
			throw ('request to speak confirmation is still pending');
		}

		if (isMicActiveSelector(micState)) {
			throw ('request already in active state');
		}

		if (!isMicProcessingSelector(micState))
			dispatch (processingRequest());

		let answer= await WebrtcController.handleOffer(data);

		return answer;
	}
);

export const handleIce = createAsyncThunk(
	'webrtc/handleIce',
	async(payload, { dispatch, getState }) => {

		const { data } = payload;
		const micState = getState().mic;

		if (isMicIdleSelector(micState))
			throw 'invalid state on handleIce';

		if (isMicActiveSelector(micState))
			throw 'invalid state on handleIce';

		if (!isMicProcessingSelector(micState))
			dispatch (processingRequest());

		await WebrtcController.handleIce (data);
	}
);

export const handleReqCancel = createAsyncThunk(
	'webrtc/handleReqCancel',
	async(payload, { dispatch, getState }) => {

		const micState = getState().mic;

		if (isMicIdleSelector(micState))
			throw 'invalid state on handleReqCancel';

		await dispatch(initPeer());
		dispatch (clearRequest());
		WebrtcController.closeChannel ();
		userMedia.clearUserMedia ();

		/*
		let msg = i18n.t("alert:desc.mod_cancel_req");
		let titl = '';

		if (data.reason_key === 'pa_failed_respond') {
			title = i18n.t('alert:title.request_timeout');
			msg = i18n.t('alert:desc.unknown_err') + " " + i18n.t("alert:desc.default");
		}

		Alert.alert (title, msg, [{
			text: i18n.t("alert:action.ok")
		}]);
		*/
	}
);

export const handleReqEnd = createAsyncThunk(
	'webrtc/handleReqEnd',
	async(payload, { dispatch, getState }) => {

		const micState = getState().mic;

		if (isMicIdleSelector(micState))
			throw 'invalid state on handleReqEnd';

		dispatch (deinitPeer());
		dispatch (clearRequest());
		/* TODO: auto approve
		dispatch (resourceAtomActions.update ({
			hasSpokenYet: false,
			position: 0
		}))
		*/

		/*
		Alert.alert (
			data.is_timed_out ? i18n.t("alert:title.request_timeout") : '',
			data.is_timed_out ? i18n.t("alert:desc.request_timeout") : i18n.t("alert:desc.mod_end_req"),
			[{ text: i18n.t("alert:action.ok")}]
		);
		*/
	}
);

export const handlePermit = createAsyncThunk(
	'webrtc/handlePermit',
	async(payload, { dispatch, getState }) => {
		const micState = getState().mic;

		/*
          * TODO: need access to app state
          *
                         let appState    = this.getState ().app.state;
                         if (appState === 'background')
                             throw 'The participant failed to respond.';
                             */
		if (isMicIdleSelector(micState)) {
			throw 'This request has been cancelled.';
		}

		if (isMicActiveSelector(micState)) {
			throw 'This request is already active.';
		}

		/* If it coems here, means the request is definitely in
          * "call-in-queue state */

		dispatch(permitRequest(payload));
	}
);
