import React, { useState, useEffect }             from 'react';
import { useSelector, useDispatch }               from 'react-redux';
import { useHistory, useLocation }                from 'react-router-dom';
import { useTranslation }                         from 'react-i18next';
import Grid                                       from '@material-ui/core/Grid';
import Typography                                 from '@material-ui/core/Typography';

import { logContainer }                           from 'libs/logger';
import WebrtcController                           from 'libs/webrtc/webrtcController';
import { publishStream, unpublishStream }         from 'libs/stella/stellaConnect';
import {publishScreenShare, unpublishScreenShare} from 'libs/stella/stellaConnect';
import { getScreenShareRef }                      from 'libs/stella/stellaConnect';
import LocalStore                                 from 'libs/localStore';
import { storageKey }                             from 'common/config';
import useRequest                                 from 'common/request';
import { clearRequest }                           from 'redux/slices/mic';
import path                                       from 'navigations/path';
import { useAlert }                               from 'components/atoms/Alert';
import LiveControl                                from 'components/molecules/LiveControl';
import UserMedia                                  from 'common/userMedia';
import Walkthrough                                from 'components/molecules/Walkthrough';
import MicrophoneIcon                             from 'assets/icons/dark/microphone.svg';

import 'styles/pages/liveCall.css';

const log = logContainer ('pages/LiveCall');

const i18nNamespaces = ['go_live'];

function LiveCall () {

	const { t }       = useTranslation (i18nNamespaces);
	const { okAlert } = useAlert ();
	const redirect    = useHistory ();
	const location    = useLocation ();
	const dispatch    = useDispatch ();
	const request     = useRequest ();

	const userStore     = useSelector (state => state.user);
	const settingsStore = useSelector (state => state.settings);

	const micSettings     = settingsStore.mic;

	const vcId        = userStore.currentUser.vcId;
	const displayName = userStore.currentUser.displayName;

	/**
	 * stream: Ref to MediaStream object being used in WebRTC
	 * videoEnabled: State for toggle video and stream's video track 'enabled' state
	 */
	const [stream, setStream]                 = useState (null);
	const [videoEnabled, setVideoEnabled]     = useState (false);
	const [audioEnabled, setAudioEnabled]     = useState (true);
	const [screenSharing, setScreenSharing]   = useState (false);
	const [onboarding, setOnboarding]         = useState (false);
	const [onboardingStep, setOnboardingStep] = useState (null);

	/**
	 * We ensure that we have a successfull webrtc connection established
	 * before we navigate to this screen. WebrtcController is a singleton
	 * with support for only one peer connection, we directly access the
	 * peer and the stream from the contoller after screen mount */
	useEffect (() => {
		let isOnboarding = location.state?.isOnbaording;
		if (isOnboarding) {
			setOnboarding (true);
			setOnboardingStep(2); //will start from step 2
			setAudioEnabled (false);
			return;
		}
		start();
		return () => stop ();
	}, []);

	useEffect (() => {
		if (!videoEnabled) {
			return;
		}
		playVideoStream (stream);
	}, [videoEnabled]);

	const start = async () => {
		if (!stream) {
			let custom = {
				vc_id       : vcId,
				displayName : displayName, 
			};
			log.debug('found stream and storing reference');
			try {
				let _stream = await WebrtcController.getStreamRef();
				setStream(_stream);
				await publishStream (_stream, custom);
			} 
			catch(err) {
				log.error({err}, 'error starting live call');
				/* 
				TODO
				Handle in case of error
				*/
			}
		}
	};

	const stop = () => {
		log.debug('stopping displaying stream');
		/* closeChannel() takes care of freeing up resources for stream as well */
		WebrtcController.closeChannel();
		unpublishStream();
		unpublishScreenShare();
	};

	const handleExitLive = () => {
		okAlert ({
			title          : t('modal:title.exit_live'),
			message        : t('modal:body.exit_live'),
			confirmBtn     : t('button:action.back_to_event'),
			dismissBtn     : t('button:action.cancel'),
			onConfirm      : onEnd,
		});
	};

	const handleShareScreen = () => {
		if (screenSharing) {
			stopScreenShare();
			return;
		}
		okAlert ({
			title          : t('modal:title.share_screen'),
			message        : t('modal:body.share_screen_msg'),
			confirmBtn     : t('button:action.share_screen'),
			dismissBtn     : t('button:action.cancel'),
			onConfirm      : toggleShareScreen,
		});
	};

	const onEnd = async () => {
		try {
			let payload = { target: vcId };
			await request.endSpeakRequest (payload);
			dispatch(clearRequest());
			redirect.push (path.eventLanding);
		}
		catch (err) {
			log.error ('Failed to cancel mic request', err);
		}
	};

	const toggleAudio = async() => {
		if (!(await checkPermission('audio'))) {
			return;
		}
		let data = {
			state : !audioEnabled,
		};
		try {
			let result = await request.shareMic(data);
			WebrtcController.toggleAudio(!audioEnabled);
			log.info ({mic : result}, 'share mic ok');
		}
		catch (err) {
			log.error ({err}, 'error in share mic');
			return;
		}
		setAudioEnabled (!audioEnabled);
	};

	const toggleVideo = async() => {
		if (!(await checkPermission('video'))) {
			return;
		}
		let data = {
			state : !videoEnabled,
		};
		try {
			let result = await request.shareCamera(data);
			WebrtcController.toggleVideo(!videoEnabled);
			log.info ({camera : result}, 'share camera ok');
		}
		catch (err) {
			log.error ({err}, 'error in share camera');
			return;
		}
		setVideoEnabled(!videoEnabled);
	};

	const stopScreenShare = async () => { 
		try {
			await unpublishScreenShare ();
		}
		catch (err) {
			log.error ({err}, 'error stoping screenshare');
			return;
		}
		setScreenSharing (false);
		return;
	};

	const toggleShareScreen = async() => {
		if (screenSharing) {
			stopScreenShare ();
			return;
		}	
		let data = {
			state : true,
		};
		try {
			let result = await request.shareContent(data);
			setScreenSharing(true);
			await publishScreenShare (stopScreenShare);
			log.info ({content: result}, 'share content ok');
		}
		catch (err) {
			log.error ({err}, 'error in sharing screenshare');
			setScreenSharing(false);
			return;
		}
		if (videoEnabled) {
			await toggleVideo ();
		}
		playVideoStream (getScreenShareRef());
	};

	const checkPermission = async (permType) => {
		/*Check for mic and camera permissions*/
		let permsManager;
		try {
			permsManager = await UserMedia.checkPermissions ();
		}
		catch (err) {
			log.error ({err}, 'error getting user device permissions');
			return;
		}

		if (!permsManager[permType].permission) {
			try {
				await UserMedia.askPermissions ({[permType] : true});		
			}
			catch (err) {
				log.error ({err}, 'error getting userMedia');
				okAlert ({
					title      : err.errorTitle,
					message    : err.errorMsg,
					confirmBtn : 'OK', 
				});
				return;
			}
		}
		return true;
	};

	const playVideoStream = (stream) => {
		if (!stream) {
			return;
		}
		var video = document.querySelector('video');
		if (!video) {
			return;
		}
		video.srcObject = stream;
		/*
		 *Don't play self audio in the video, otherwise it will echo
		 * */
		video.muted = true; 
		video.onloadedmetadata = function() {
			video.play();
		};
	};

	const getVideoDisabled = () => {
		if (screenSharing) {
			return true;
		}
		if (micSettings.allowParticipantVideo || videoEnabled) {
			return false;
		}
		return true;
	};

	const getScreenDisabled = () => {
		if (micSettings.allowParticipantContent || screenSharing) {
			return false;
		}
		return true;
	};

	const onSkipOnboarding = () => {
		setOnboarding (false);
		LocalStore.set (storageKey.onboarded, true);
		redirect.push (path.connect);
	};

	const onNextOnboarding = () => {
		setOnboardingStep (onboardingStep + 1);
	};

	const walkthrough = onboarding && <Walkthrough onSkip = { onSkipOnboarding } onNext = { onNextOnboarding } onboardingStep = { onboardingStep }/>;

	return (
		<Grid className = "liveCall page-parent">
			<Typography variant='body2' className = 'liveCall-statusbar'> 
				<span className = 'liveCall-live-icon'></span> {t('live_call:title.live')} 
			</Typography>
			<Grid className ='video-container'>
				{videoEnabled || screenSharing ? <video height = '100%' width = '100%' /> : null}
				{!videoEnabled && !screenSharing ? 
					<Grid className = 'liveCall-video-disabled'>
						<img src = { MicrophoneIcon }/>
						<Typography variant = 'h2' className = 'liveCall-video-disabled-text'> {t('live_call:body.live_presenter')} </Typography> 
					</Grid>
					: null}
				<LiveControl 
					audioEnabled      = { onboardingStep === 2 || audioEnabled }
					toggleAudio       = { toggleAudio }
					videoEnabled      = { onboardingStep === 3 || videoEnabled }
					toggleVideo       = { toggleVideo }
					screenSharing     = { onboardingStep === 4 || screenSharing }
					handleShareScreen = { handleShareScreen }
					handleExitLive    = { handleExitLive }
					screenDisabled    = { getScreenDisabled () }
					videoDisabled     = { getVideoDisabled () }
				/>
			</Grid>
			{walkthrough}
		</Grid>
	);
}

export default LiveCall;
