import React                     from 'react';
import { useTranslation }        from 'react-i18next';
import Grid                      from '@material-ui/core/Grid';
import Typography                from '@material-ui/core/Typography';
import Divider                   from '@material-ui/core/Divider';

import Count                     from 'components/atoms/Count';

import 'styles/pages/playlist.css';

const i18nNamespaces = ['playlist'];

function Playlist () {

	const { t } =  useTranslation (i18nNamespaces);

	return (
		<Grid className = 'playlist'>
			<Grid className = 'p-xxl' container>
				<Typography variant = 'h4'> {t('header.event_recordings')} </Typography>
				<Count number = { 0 } variant = 'elliptical' color = 'secondary' className = 'ml-m mr-m'/>
			</Grid>
			<Divider />
			<Grid className = 'playlist-container'>
				
			</Grid>
		</Grid>
	);
}

export default Playlist;