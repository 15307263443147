export const requestError = {

	'send-comment' : {
		'ERR_VALIDATION'             : 'error:send_comment.err_validation',
		'ERR_UNAUTHORISED'           : 'error:send_comment.err_unauthorised'
	},            

	'edit-comment' : {            
		'ERR_VALIDATION'             : 'error:edit_comment.err_validation',
		'ERR_UNAUTHORISED'           : 'error:edit_comment.err_unauthorised',
		'ERR_NOT_FOUND'              : 'error:edit_comment.err_not_found',
	},            

	'cancel-comment' : {           
		'ERR_VALIDATION'             : 'error:cancel_comment.err_validation',
		'ERR_UNAUTHORISED'           : 'error:cancel_comment.err_unauthorised',
		'ERR_NOT_FOUND'              : 'error:cancel_comment.err_not_found',
	},  

	'poll-submit-answer' : {  
		'ERR_NOT_FOUND'              : 'error:poll_submit_answer.err_not_found',
		'ERR_CONFLICT_SUBMITTED'     : 'error:poll_submit_answer.err_conflict_submitted',
	},

	'request-to-speak' : {
		'ERR_INVALID_STATE_ACTIVE'   : 'error:request_to_speak.err_invalid_state_active',
		'ERR_INVALID_STATE_NEW'      : 'error:request_to_speak.err_invalid_state_new',
		'ERR_INVALID_STATE_APPROVED' : 'error:request_to_speak.err_invalid_state_approved',
	},

	'request-to-end' : {
		'ERR_NOT_FOUND'              : 'error:request_to_end.err_not_found',
		'ERR_STELLA'                 : 'error:request_to_end.err_stella',		
	},

	'request-to-cancel' : {
		'ERR_NOT_FOUND'              : 'error:request_to_cancel.err_not_found',		
	},

	'share-mic' : {
		'ERR_CONFLICT'               : 'error:share_mic.err_conflict',
		'ERR_UNAUTHORISED'           : 'error:share_mic.err_unauthorised',
		'ERR_VALIDATION'             : 'error:share_mic.err_validation',
	},

	'share-camera' : {
		'ERR_CONFLICT'               : 'error:share_camera.err_conflict',
		'ERR_UNAUTHORISED'           : 'error:share_camera.err_unauthorised',
		'ERR_VALIDATION'             : 'error:share_camera.err_validation',
		'ERR_INVALID_STATE'          : 'error:share_camera.err_invalid_state',
	},

	'share-content' : {
		'ERR_CONFLICT'               : 'error:share_content.err_conflict',
		'ERR_UNAUTHORISED'           : 'error:share_content.err_unauthorised',
		'ERR_VALIDATION'             : 'error:share_content.err_validation',
		'ERR_INVALID_STATE'          : 'error:share_content.err_invalid_state',
	},

	'get-avatar' : {

	},
};

export const apiError = {

	'event-code' : {

	},

	'event-pa-join-code' : {
		'ERR_INTERNAL'             : 'error:event_pa_join_code.err_internal',
		'E_EVT_IN_CREATED_STATE'   : 'error:event_pa_join_code.e_evt_in_created_state',
		'E_EVT_IN_CANCELLED_STATE' : 'error:event_pa_join_code.e_evt_in_cancelled_state',
		'E_EVT_IN_STOPPED_STATE'   : 'error:event_pa_join_code.e_evt_in_stopped_state',
		'ERR_MASTER_DISCONN'       : 'error:event_pa_join_code.err_master_disconn',
		'ERR_MASTER_UNREACH'       : 'error:event_pa_join_code.err_master_unreach',
	},

	'event-verify-apin' : {

	},
};

/**
 * network error message
 */
export const networkError = 'error:apis.network_error';

/**
 * default error message
 */
export const defaultError = 'error:default.default_message';

/**
 * 
 * @param {string} apiName 
 * @param {string} errName 
 */

export const getApiErrorMsg = (apiName, errName) => {
	if (!apiName || !apiError[apiName] || !apiError[apiName][errName]) {
		return defaultError;
	}
	return `error:${apiError[apiName][errName]}`;
}; 

/**
 * 
 * @param {string} command 
 * @param {string} errName 
 */

export const getRequestErrorMsg = (command, errName) => {
	if (!command || !requestError[command] || !requestError[command][errName]) {
		return defaultError;
	}
	return `${requestError[command][errName]}`;
};

/**
 * 
 * @param {object} err 
 * @returns {boolean}
 */

export const isNetworkError = (err) =>{
	if (!err) {
		return false;
	}
	if (err.message !== 'Network Error') {
		return false;
	}
	return true;
};