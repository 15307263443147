import i18n                               from 'i18next';
import { initReactI18next }               from 'react-i18next';
import LanguageDetector                   from 'i18next-browser-languagedetector';
import moment                             from 'moment';

import xhr                                from 'libs/xhr';
import all                                from './locales/all';

import 'moment/locale/ar';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/el';
import 'moment/locale/en-au';
import 'moment/locale/en-gb';
import 'moment/locale/en-nz';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/he';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/nl';
import 'moment/locale/pt-br';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/sv';
import 'moment/locale/tr';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';

const momentLocaleMap = {
	en: 'en',
	ar: 'ar',
	da: 'da',
	de: 'de',
	el: 'el',
	'en-au': 'en-au',
	'en-gb': 'en-gb',
	'en-nz': 'en-nz',
	es: 'es',
	fi: 'fi',
	fr: 'fr',
	he: 'he',
	hu: 'hu',
	it: 'it',
	ja: 'ja',
	ko: 'ko',
	nl: 'nl',
	'pt-br': 'pt-br',
	'pt-pt': 'pt',
	ru: 'ru',
	sv: 'sv',
	tr: 'tr',
	'zh-cn': 'zh-cn',
	'zh-tw': 'zn-tw'
};

const resources = all;

const locales = Object.keys(resources);

const initMomentLocale = () =>  {
	let currentLang = getCurrentLocale().toLowerCase();
	/* TODO verify resources lang code vs moment locale code */
	moment.locale(momentLocaleMap[currentLang] || 'en');
};

const getCurrentLocale = () =>  {
	return i18n.languages.find((lng => locales.indexOf(lng) !== -1));
};

const getCurrentDir = () => {
	return i18n.dir ();
};

const updatei18nLayout = (lng) => {
	document.documentElement.setAttribute ('lang', lng);
	if (getCurrentDir () === 'rtl') {
		document.body.classList.add ("rtl");
		document.documentElement.setAttribute ('dir', 'rtl');
	}	
	else{
		document.body.classList.remove ("rtl");
		document.documentElement.removeAttribute ('dir', 'rtl');
	}
};

const onLanguageChanged = (lng) => {
	initMomentLocale (lng);
	updatei18nLayout (lng);
	xhr && xhr.updateHeaders ();
};

const getKeyforString = (string, lang = "en") => {
	let langLocale = resources[lang] || resources["en"];
	let keyStringMap = langLocale["const"]["mic"];
	let stringKeyMap = {};

	for (let key in keyStringMap) {
		stringKeyMap[keyStringMap[key]] = key;
	}

	return stringKeyMap[string];
};

i18n 
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		initImmediate: false,
		resources,
		fallbackLng: 'en',
		detection: {
			order: ['localStorage', 'navigator'],
		},
		debug: true,
		react: {
			wait: true,
			useSuspense: false,
		},
	})
	.then(() => {
		// Add event handlers and call handlers manually after init
		i18n.on("languageChanged", onLanguageChanged);
		onLanguageChanged(i18n.languages[0]);
	});


if (window) {
	window.i18n = i18n;
	window.moment = moment;
}

i18n.locales          = locales;
i18n.initMomentLocale = initMomentLocale;
i18n.getKeyforString  = getKeyforString;
i18n.updatei18nLayout = updatei18nLayout;
i18n.getCurrentLocale = getCurrentLocale;
i18n.getCurrentDir    = getCurrentDir; 

export default i18n;
