import map                          from 'lodash/map';
import uniq                         from 'lodash/uniq';
import keyBy                        from 'lodash/keyBy';

import useRequest                   from 'common/request';

const useAvatarUtils = () => {

	const request = useRequest ();

	const avatarUtils = {};

	avatarUtils.initCommentAvatar = async (commentRes, avatarsObj) => {
		let err;
		if (!commentRes || !avatarsObj) {
			err = 'invalid request. Either comment resource or avatar object is missing';
			throw err;
		}

		/*Taking vc_id from all the comments state array */                                                                                                                                                  
		let approved = commentRes.comments_approved ? map (commentRes.comments_approved, 'vc_id') : [];
		let rejected = commentRes.comments_rejected ? map (commentRes.comments_rejected, 'vc_id') : [];
		let pendings = commentRes.comments_pending  ? map (commentRes.comments_pending, 'vc_id')  : [];

		/*get all unique vc_ids*/
		let vcIds = uniq ([...approved, ...rejected, ...pendings]);

		/*Check if avatar already exist*/

		let _vcIdAvatar = []; //list of vcids for which avatar needs to be fetched;
		vcIds.forEach (vcId => {
			if (avatarsObj[vcId]) {
				return;
			}
			_vcIdAvatar.push (vcId);
		});

		if (!_vcIdAvatar.length) {
			return;
		}

		let avatars = await getAvatars (_vcIdAvatar);
		return avatars;
	};

	avatarUtils.newJohnnyAvatar = async (users, avatarsObj) => {
		let err;
		if (!Array.isArray(users) || !users.length || !avatarsObj) {
			err = 'invalid request. Either users or avatar object is missing';
			throw err;
		}
		let _vcIdAvatar = []; //list of vcids for which avatar needs to be fetched;
		users.forEach (user => {
		/*Check if avatar already exist and previous checksum and new checksum are same*/
			if (avatarsObj[user.vc_id] && avatarsObj[user.vc_id].checksum === user.checksum) {
				return;
			}
			_vcIdAvatar.push (user.vc_id);
		});

		if (!_vcIdAvatar.length){
			return;
		}

		let avatars = await getAvatars (_vcIdAvatar);
		return avatars;
	};

	const getAvatars = async (vcIds) => {
		if (!Array.isArray (vcIds) || !vcIds.length) {
			throw 'Invalid request: array of vcids is not provided';
		}

		/*get all unique vc_ids*/
		vcIds = uniq (vcIds);

		let avatars = await request.getAvatar (vcIds);
		avatars = keyBy (avatars, 'fileName');  //converting array to object with vcId key.
	
		/*Returning avatar object of vcId and image object*/
		let avatarObj = {};
		vcIds.map ((vcId) => {
			let img = avatars[vcId] || '';
			avatarObj[vcId] = img;
		});
		return avatarObj;
	};

	return avatarUtils;
};

export default useAvatarUtils;
