import React, { useEffect, useState }             from 'react';
import { useHistory, useLocation }                from 'react-router-dom';
import { useDispatch, useSelector }               from 'react-redux';
import Grid                                       from '@material-ui/core/Grid';

import Comments                                   from 'pages/Comments';
import Polls                                      from 'pages/Polls';
import Playlist                                   from 'pages/Playlist';
import EventLandingTopBar                         from 'components/molecules/EventLandingTopBar';
import EventLandingSideBar                        from 'components/molecules/EventLandingSideBar';
import EventLandingBottomBar                      from 'components/molecules/EventLandingBottomBar';
import PageLoader                                 from 'components/atoms/PageLoader';
import useApis                                    from 'common/apis';
import { changePollKeys, clearPollingStore }      from 'redux/slices/polling';
import { clearCommentStore }                      from 'redux/slices/comment';
import { clearEventStore, addPastEvent }          from 'redux/slices/event';
import { clearSettingsStore }                     from 'redux/slices/settings';
import { clearMicStore }                          from 'redux/slices/mic';
import { clearUserStore }                         from 'redux/slices/user';
import { logContainer }                           from 'libs/logger';

const log = logContainer ('pages/PastLanding');

function PastLanding () {

	const redirect   = useHistory ();
	const location   = useLocation ();
	const apis       = useApis ();
	const dispatch   = useDispatch ();

	const eventStore = useSelector (state => state.event);
	const { past } = eventStore;

	const [eventInfo, setEventInfo]     = useState (null);
	const [selectedTab, setSelectedTab] = useState (''); /*enum : ['comment', 'poll', 'playlist']*/

	useEffect (() => {
		getEventInfo ();
	}, []);

	const getEventInfo = async () => {
		let eventId =  location.state?.eventId;
		if (!eventId) {
			return;
		}
		if (past && past.eventData?._id === eventId) {
			setEventInfo (past);
			return;
		}
		let eventInfo;
		try {
			eventInfo = await apis.getEventDetails (eventId); 
			log.info ({eventInfo : eventInfo}, 'get event info for past event');
		}
		catch (err) {
			log.error ({err}, 'error getting past event info');
			return;
		}
		setEventInfo (eventInfo);
		dispatch (addPastEvent (eventInfo));
	};

	const clearStore = () => {
		dispatch (clearSettingsStore ());
		dispatch (clearUserStore     ());
		dispatch (clearEventStore    ());
		dispatch (clearCommentStore  ());
		dispatch (clearPollingStore  ());
		dispatch (clearMicStore      ());
	};

	const onBack = () => {
		clearStore ();
		redirect.goBack ();
	};

	const handleSelectedTab = (sel) => {
		if (selectedTab === sel) {
			setSelectedTab ('');
			return;
		}
		setSelectedTab (sel);
	};


	if (!eventInfo) {
		return <PageLoader open = { true }/>;
	}

	const comments  = eventInfo.commentData?.approved;
	const polls     = changePollKeys (eventInfo.pollData?.done);
	const eventData = eventInfo.eventData;
	const stats     = eventInfo.stats;

	const sideBarContent = () => {
		switch (selectedTab) {
			case 'comment' :
				return <Comments pastComments = { comments } isPast = { true }/>;
			case 'poll' :
				return <Polls pastPolls = { polls } isPast = { true }/>;
			case 'playlist' :
				return <Playlist />;
			default :
				return;
		}
	};

	return (
		<Grid className='eventLanding page-parent'>
			<EventLandingTopBar
				onLeave = { onBack }
				isPast  = { true }
			/>
			<Grid className='eventLanding-main-container'>
				<Grid className={ `eventLanding-video-container ${!selectedTab ? 'fullwidth' : ''}` }>
				</Grid>
				<Grid className={ `eventLanding-side-container ${selectedTab ? 'open' : ''}` }>
					<EventLandingSideBar open={ selectedTab ? true : false }>
						{sideBarContent()}
					</EventLandingSideBar>
				</Grid>
			</Grid>
			<EventLandingBottomBar
				live              = { eventData }
				handleSelectedTab = { handleSelectedTab }
				selectedTab       = { selectedTab }
				participant       = { stats.attendees }
				isPast            = { true }
			/>
		</Grid>
	);
}

export default PastLanding;
