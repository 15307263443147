import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme }       from '@material-ui/core/styles';
import TextField          from '@material-ui/core/TextField';

import InputAdornment     from '@material-ui/core/InputAdornment';
import LMSearch           from 'assets/icons/light/search.svg';
import DMSearch           from 'assets/icons/dark/search.svg';

import 'styles/components/atoms/searchField.css';

function SearchField ({ value, onChange}) {

	const theme = useTheme ().palette.type;
	const { t } = useTranslation ();

	return (
		<TextField 
			value       = { value }
			onChange    = { onChange }
			variant     = 'outlined'
			placeholder = { t('landing:body.search_event') }
			className   = 'searchField'
			InputProps  = {{
				endAdornment : <InputAdornment position="end"><img src = { theme === 'light' ? LMSearch : DMSearch }/> </InputAdornment>,
			}}
		/>
	);
}

SearchField.propTypes = {
	value    : PropTypes.string.isRequired,
	onChange : PropTypes.func.isRequired,
};

export default SearchField;
