import React               from 'react';
import moment              from 'moment';
import { useTranslation }  from 'react-i18next';
import PropTypes           from 'prop-types';
import Grid                from '@material-ui/core/Grid';
import Typography          from '@material-ui/core/Typography';

import Button              from 'components/atoms/Button';
import IconButton          from 'components/atoms/IconButton';

import 'styles/components/molecules/requestCard.css';

function RequestCard ({ type, title, time, uuid, status, content, cancelRequest, statusColor, position, viewRequest, editComment}) {

	const { t } = useTranslation ();

	const onReqCancel = () => {
		switch (type) {
			case 'COMMENT' : 
				cancelRequest (type.toLowerCase(), uuid);
				break;
			case 'MIC' :
				cancelRequest (type.toLowerCase ());
				break;
			default : 
				return;
		}
	};

	const showCancel = 
		<Button variant = 'outlined' color = 'secondary' onClick = { onReqCancel } size = 'small' className = 'ml-m requestCard-cancel-btn'>
			{t('button:action.cancel_request')}
		</Button>;

	const showPrimary = 
		<Button variant = 'outlined' color = 'primary' size = 'small' className = 'ml-m' onClick = { type === 'COMMENT' ? () => editComment (uuid) : viewRequest }>
			{type === 'COMMENT' ? t('button:action.edit') : t('button:action.view')}
		</Button>;

	return (
		<Grid className = "requestCard">
			<Grid container alignItems ='center'>
				<Grid item xs = { 8 } container alignItems = 'center'>
					<IconButton 
						icon  = { type === 'COMMENT' ? 'Comment' : 'Mic' }
						size  = { 30 }
						color = 'secondary'
					/>
					<Typography variant = 'subtitle1' className = 'requestCard-title ml-m mr-m'> {title} </Typography>
					<Typography variant = 'body2' color = 'inherit' className = 'requestCard-time'> {moment(time).fromNow()} </Typography>
				</Grid>
				<Grid item xs = { 4 } container justify = 'flex-end'>
					<Typography variant = 'body2' className = { `requestCard-status ${statusColor}` }> 
						<span className = 'requestCard-statusIcon'></span>
						{status} 
					</Typography>
				</Grid>
			</Grid>
			<Grid className = 'mt-xl'>
				<Typography variant = 'h2' color = 'secondary' className = 'requestCard-content'> {content} </Typography>
				{position ? <Typography variant = 'h1' color = 'secondary' className = 'requestCard-content'> {content} </Typography>: null}
			</Grid>
			<Grid className = 'mt-xxl' container item xs = { 12 } justify = 'flex-end'>
				{showCancel}
				{showPrimary}
			</Grid> 
		</Grid>
	);
}	

RequestCard.propTypes = {
	title         : PropTypes.string.isRequired,
	time          : PropTypes.any.isRequired,
	content       : PropTypes.string.isRequired,
	type          : PropTypes.oneOf (['MIC', 'COMMENT']).isRequired,
	status        : PropTypes.string.isRequired,
	statusColor   : PropTypes.oneOf (['pending', 'accepted']),
	isEditable    : PropTypes.bool,
	cancelRequest : PropTypes.func,
};

RequestCard.defaultProps = {
	isEditable : false,
};

export default RequestCard;
