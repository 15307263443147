import React              from 'react';
import PropTypes          from 'prop-types';
import { useTheme }       from '@material-ui/core/styles';
import Tooltip            from '@material-ui/core/Tooltip';
import IconButton         from '@material-ui/core/IconButton';
import Fade               from '@material-ui/core/Fade';
import Badge              from '@material-ui/core/Badge';

import LMCamera           from 'assets/icons/light/camera.svg';
import LMCapture          from 'assets/icons/light/capture.svg';
import LMClose            from 'assets/icons/light/close.svg';
import LMGallery          from 'assets/icons/light/gallery.svg';
import LMRemoveEnabled    from 'assets/icons/light/remove-enabled.svg';
import LMRemoveDisabled   from 'assets/icons/light/remove-disabled.svg';
import LMBack             from 'assets/icons/light/arrow-back.svg';
import LMSettings         from 'assets/icons/light/settings.svg';
import LMUp               from 'assets/icons/light/arrow-up.svg';
import LMRight            from 'assets/icons/light/arrow-right.svg';
import LMDown             from 'assets/icons/light/arrow-down.svg';
import LMScreenShare      from 'assets/icons/light/screenShare.svg';
import LMScreenDisabled   from 'assets/icons/light/screenShare-disabled.svg';
import LMMic              from 'assets/icons/light/mic.svg';
import LMMicBig           from 'assets/icons/light/mic-big.svg';
import LMMicBigDisabled   from 'assets/icons/light/mic-big-disabled.svg';
import LMVideo            from 'assets/icons/light/video.svg';
import LMVideoDisabled    from 'assets/icons/light/video-disabled.svg';
import LMCallEnd          from 'assets/icons/light/call-end.svg';
import LMInfo             from 'assets/icons/light/info.svg';
import LMExpand           from 'assets/icons/light/expand.svg';
import LMPie              from 'assets/icons/light/pie.svg';
import LMPieSelected      from 'assets/icons/light/pie-selected.svg';
import LMBar              from 'assets/icons/light/bar.svg';
import LMBarSelected      from 'assets/icons/light/bar-selected.svg';
import LMVolumeOn         from 'assets/icons/light/volume-on.svg';
import LMVolumeOff        from 'assets/icons/light/volume-off.svg';
import LMFullScreen       from 'assets/icons/light/fullscreen.svg';
import LMCloseFullScreen  from 'assets/icons/dark/close-fullscreen.svg';
import LMPlus             from 'assets/icons/light/plus-icon.svg';
import LMMinus            from 'assets/icons/light/minus-icon.svg';
import LMLogout           from 'assets/icons/light/logout.svg';
import LMSort             from 'assets/icons/light/sort.svg';
import LMSortAsc          from 'assets/icons/light/sort-asc.svg';
import LMSortDesc         from 'assets/icons/light/sort-desc.svg';
import LMComment          from 'assets/icons/light/comment.svg';
import LMCommentEnabled   from 'assets/icons/light/comment-enabled.svg';
import LMSentReqEnabled   from 'assets/icons/light/sent-req-enabled.svg';
import LMPollEnabled      from 'assets/icons/light/poll-enabled.svg';
import LMPlaylistEnabled  from 'assets/icons/light/playlist-enabled.svg';
import LMPlaylistDisabled from 'assets/icons/light/playlist-disabled.svg';
import LMCommentDisabled  from 'assets/icons/light/comment-disabled.svg';
import LMSentReqDisabled  from 'assets/icons/light/sent-req-disabled.svg';
import LMPollDisabled     from 'assets/icons/light/poll-disabled.svg';
import LMNotification     from 'assets/icons/light/notification.svg';
import LMCloseSmall       from 'assets/icons/light/close-small.svg';
import DMExpand           from 'assets/icons/dark/expand.svg';
import DMCapture          from 'assets/icons/dark/capture.svg';
import DMClose            from 'assets/icons/dark/close.svg';
import DMGallery          from 'assets/icons/dark/gallery.svg';
import DMRemoveEnabled    from 'assets/icons/dark/remove-enabled.svg';
import DMRemoveDisabled   from 'assets/icons/dark/remove-disabled.svg';
import DMBack             from 'assets/icons/dark/arrow-back.svg';
import DMSettings         from 'assets/icons/dark/settings.svg';
import DMUp               from 'assets/icons/dark/arrow-up.svg';
import DMRight            from 'assets/icons/dark/arrow-right.svg';
import DMDown             from 'assets/icons/dark/arrow-down.svg';
import DMScreenShare      from 'assets/icons/dark/screenShare.svg';
import DMScreenDisabled   from 'assets/icons/dark/screenShare-disabled.svg';
import DMMic              from 'assets/icons/dark/mic.svg';
import DMMicBig           from 'assets/icons/dark/mic-big.svg';
import DMMicBigDisabled   from 'assets/icons/dark/mic-big-disabled.svg';
import DMVideo            from 'assets/icons/dark/video.svg';
import DMVideoDisabled    from 'assets/icons/dark/video-disabled.svg';
import DMCallEnd          from 'assets/icons/dark/call-end.svg';
import DMPie              from 'assets/icons/dark/pie.svg';
import DMPieSelected      from 'assets/icons/dark/pie-selected.svg';
import DMBar              from 'assets/icons/dark/bar.svg';
import DMBarSelected      from 'assets/icons/dark/bar-selected.svg';
import DMVolumeOn         from 'assets/icons/dark/volume-on.svg';
import DMVolumeOff        from 'assets/icons/dark/volume-off.svg';
import DMFullScreen       from 'assets/icons/dark/fullscreen.svg';
import DMCloseFullScreen  from 'assets/icons/dark/close-fullscreen.svg';
import DMPlus             from 'assets/icons/dark/plus-icon.svg';
import DMMinus            from 'assets/icons/dark/minus-icon.svg';
import DMLogout           from 'assets/icons/dark/logout.svg';
import DMSort             from 'assets/icons/dark/sort.svg';
import DMSortAsc          from 'assets/icons/dark/sort-asc.svg';
import DMSortDesc         from 'assets/icons/dark/sort-desc.svg';
import DMComment          from 'assets/icons/dark/comment.svg';
import DMCommentEnabled   from 'assets/icons/dark/comment-enabled.svg';
import DMSentReqEnabled   from 'assets/icons/dark/sent-req-enabled.svg';
import DMPollEnabled      from 'assets/icons/dark/poll-enabled.svg';
import DMCommentDisabled  from 'assets/icons/dark/comment-disabled.svg';
import DMSentReqDisabled  from 'assets/icons/dark/sent-req-disabled.svg';
import DMPollDisabled     from 'assets/icons/dark/poll-disabled.svg';
import DMNotification     from 'assets/icons/dark/notification.svg';
import DMCloseSmall       from 'assets/icons/dark/close-small.svg';
import DMPlaylistEnabled  from 'assets/icons/dark/playlist-enabled.svg';
import DMPlaylistDisabled from 'assets/icons/dark/playlist-disabled.svg';

import 'styles/components/atoms/iconButton.css';

function CustomIconButton ({ icon, className, iconClassName, tooltip, placement, size, color, onClick, innerRef, id, badge, ...other }) {

	const theme = useTheme ().palette.type;
	
	const icons = {
		light : {
			Close                 : LMClose,
			Camera                : LMCamera,
			RemoveEnabled         : LMRemoveEnabled,
			RemoveDisabled        : LMRemoveDisabled,
			Gallery               : LMGallery,
			Capture               : LMCapture,
			Back                  : LMBack,
			Up                    : LMUp,
			Down                  : LMDown,
			Settings              : LMSettings,
			Mic                   : LMMic,
			MicBig                : LMMicBig,
			MicBigDisabled        : LMMicBigDisabled,
			Video                 : LMVideo,
			CallEnd               : LMCallEnd,
			ScreenShare           : LMScreenShare,
			ScreenDisabled        : LMScreenDisabled,
			Info                  : LMInfo,
			Right                 : LMRight,
			Expand                : LMExpand,
			Pie                   : LMPie,
			PieSelected           : LMPieSelected,
			Bar                   : LMBar,
			BarSelected           : LMBarSelected,
			VolumeOn              : LMVolumeOn,
			VolumeOff             : LMVolumeOff,
			FullScreen            : LMFullScreen,
			CloseFullScreen       : LMCloseFullScreen,
			Minus                 : LMMinus,
			Plus                  : LMPlus,
			Logout                : LMLogout,
			Sort                  : LMSort,
			SortAsc               : LMSortAsc,
			SortDesc              : LMSortDesc,
			Comment               : LMComment,
			CommentEnabled        : LMCommentEnabled,
			PollEnabled           : LMPollEnabled,
			SentReqEnabled        : LMSentReqEnabled,
			CommentDisabled       : LMCommentDisabled,
			PollDisabled          : LMPollDisabled,
			SentReqDisabled       : LMSentReqDisabled,
			Notification          : LMNotification,
			CloseSmall            : LMCloseSmall,
			VideoDisabled         : LMVideoDisabled,
			PlaylistEnabled       : LMPlaylistEnabled,
			PlaylistDisabled      : LMPlaylistDisabled,
		},
		dark : {
			Close                 : DMClose,
			Camera                : LMCamera,
			RemoveEnabled         : DMRemoveEnabled,
			RemoveDisabled        : DMRemoveDisabled,
			Gallery               : DMGallery,
			Capture               : DMCapture,
			Back                  : DMBack,
			Up                    : DMUp,
			Down                  : DMDown,
			Settings              : DMSettings,
			Mic                   : DMMic,
			MicBig                : DMMicBig,
			MicBigDisabled        : DMMicBigDisabled,
			Video                 : DMVideo,
			CallEnd               : DMCallEnd,
			ScreenShare           : DMScreenShare,         
			ScreenDisabled        : DMScreenDisabled,         
			Info                  : LMInfo,
			Right                 : DMRight,
			Expand                : DMExpand,
			Pie                   : DMPie,
			PieSelected           : DMPieSelected,
			Bar                   : DMBar,
			BarSelected           : DMBarSelected,
			FullScreen            : DMFullScreen,
			CloseFullScreen       : DMCloseFullScreen,
			Minus                 : DMMinus,
			Plus                  : DMPlus,
			Logout                : DMLogout,
			Sort                  : DMSort,
			SortAsc               : DMSortAsc,
			SortDesc              : DMSortDesc,
			CommentEnabled        : DMCommentEnabled,
			PollEnabled           : DMPollEnabled,
			SentReqEnabled        : DMSentReqEnabled,
			CommentDisabled       : DMCommentDisabled,
			PollDisabled          : DMPollDisabled,
			SentReqDisabled       : DMSentReqDisabled,
			VolumeOn              : DMVolumeOn,
			VolumeOff             : DMVolumeOff,
			Comment               : DMComment,
			Notification          : DMNotification,
			CloseSmall            : DMCloseSmall,
			VideoDisabled         : DMVideoDisabled,
			PlaylistEnabled       : DMPlaylistEnabled,
			PlaylistDisabled      : DMPlaylistDisabled,
		},
	};

	if (tooltip) {
		return (
			<Tooltip title = { tooltip } arrow = { true } TransitionComponent = { Fade } TransitionProps = {{ timeout: 600 }} placement = { placement || 'bottom' }>
				<span>
					<IconButton disableRipple className = { `${className} size-${size}` } onClick = { onClick } color = { color } ref = { innerRef } id = { id } { ...other }>
						<Badge variant = 'dot' color = 'primary' invisible = { !badge }>
							<img src={ icons[theme][icon] } className={ iconClassName } />
						</Badge>
					</IconButton>
				</span>
			</Tooltip>
		);
	}

	return (
		<IconButton disableRipple className = { `${className} size-${size}` } onClick = { onClick } color = { color } ref = { innerRef } id = { id } { ...other }>
			<Badge variant = 'dot' color = 'error' invisible = { !badge }>
				<img src = { icons[theme][icon] } className = { iconClassName }/>
			</Badge>
		</IconButton>
	);
}

CustomIconButton.propTypes = {
	icon          : PropTypes.string.isRequired,
	className     : PropTypes.string,
	iconClassName : PropTypes.string,
	tooltip       : PropTypes.string,
	onClick       : PropTypes.func,
	size          : PropTypes.oneOf ([15, 21, 25, 30, 35, 40, 45, 50]),
	color         : PropTypes.oneOf (['primary', 'secondary', 'inherit']) 
};

export default CustomIconButton;
