import React, { createContext, useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch }                              from 'react-redux';
import Backdrop                                                  from '@material-ui/core/Backdrop';
import Grid                                                      from '@material-ui/core/Grid';
import Typography                                                from '@material-ui/core/Typography';
import CircularProgress                                          from '@material-ui/core/CircularProgress';
            
import { updatePageLoader }                                      from 'redux/slices/common';

import 'styles/components/atoms/pageLoader.css';

const PageLoader = ({ open, options }) => {

	const dispatch    = useDispatch ();
	const commonStore = useSelector (state => state.common);

	const { pageLoader } = commonStore;
	/* 
		options : {
			loaderText : 'string'  text below the loader,
			showElements : 'array of elementId' to show elements above the loader overlay
		}
	*/

	useEffect (() => {
		/*Added style to showElements if open */
		if (options && options.showElements) {
			let elementIds = options.showElements;
			elementIds.forEach (id => {
				let el = document.getElementById (id);
				if (!el) {
					return;
				}
				el.style.position = 'relative';
				el.style.zIndex   = 10000;
			});
			dispatch (updatePageLoader ({showElements : options.showElements}));
		}
		/*Remove style to showElements once closed */
		if (!open && pageLoader.showElements.length) {
			let showElements = pageLoader.showElements;
			if (showElements.length) {
				showElements.forEach(id => {
					let el = document.getElementById(id);
					if (!el) {
						return;
					}
					el.style.position = 'none';
					el.style.zIndex = 'inherit';
				});
				dispatch (updatePageLoader ({showElements : []}));
			}
		}
	},[options?.showElements]);

	if (!open) {
		return <div></div>;
	}

	return (
		<Backdrop open = { open } className = 'pageLoader'>
			<Grid>
				<Grid container justify = 'center'>
					<CircularProgress color = "primary"/>
				</Grid>
				<Typography variant = 'h3' align = 'center' className = 'mt-xl'>{options?.loaderText}</Typography>
			</Grid>
		</Backdrop>
	);
};

const PageLoaderContext = createContext ({});

const PageLoaderProvider = ({ children }) => {

	const [open, setOpen]       = useState (false);
	const [options, setOptions] = useState ({});

	const openPageLoader = (isOpen, options) => {
		setOpen (isOpen);
		setOptions ({...options});
	};

	const closePageLoader = () => {
		setOpen (false);
		setOptions ({});
	};

	return (
		<PageLoaderContext.Provider value = {{ openPageLoader, closePageLoader }}>
			<PageLoader open = { open } options = { options }/>
			{ children }
		</PageLoaderContext.Provider>
	);
};

const usePageLoader = () => {
	const { openPageLoader, closePageLoader } = useContext (PageLoaderContext);
	const pageLoader = (isOpen, options) => {
		if (isOpen) {
			openPageLoader (isOpen, options);
			return;
		}
		closePageLoader ();
	};
	return { pageLoader };
};


export default PageLoader;
export {PageLoaderProvider, usePageLoader};