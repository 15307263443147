import React, { useState, useEffect } from 'react';
import { useTranslation }             from 'react-i18next';
import PropTypes                      from 'prop-types';
import { VictoryPie, VictoryTooltip}  from 'victory';

function PollDisplay ({ poll, resultShared }) {
	const localize = useTranslation().t;
	const { question, options, results, viewType } = poll;
	const localToken = 'display:poll';

	const [votes, setVotes] = useState ({});

	const getTotalVotes = (results) => {
		const totalResults = results?.length || 0;
		const answers = {};

		for (let i = 0; i < totalResults; i++) {

			const index = results[i]['index'];

			if (!answers[index]) answers[index] = 0;
			answers[index] += 1;
		}
		return answers;
	};

	/**
	 * @returns {Object} {optionIndex : percent}
	 */
	const getVotes = () => {
		const totalResults =  results.length || 0;
		const answers = getTotalVotes(results);
		let votes = {};

		votes = options.reduce((percentage, c, i) => {
			percentage[i] = answers[i] ? Number(((answers[i] / totalResults) * 100).toFixed(2)) : 0;
			return percentage;
		}, {});
		return votes;
	};

	useEffect (() => {
		setVotes (getVotes ());
	}, [results]);

	return (
		<div className = 'pollDisplay-container'>
			<div className = 'pollDisplay-top-container'>
				<div>
					<div className = 'pollDisplay-question-title'>{ localize (localToken + ':question') }</div>
					<div className = 'pollDisplay-question'> {question} </div>
				</div>
			</div>
			<div className = 'pollDisplay-graph-container'>
				{viewType === 'pie' && resultShared ?
					<Pie
						options  = { options }
						votes    = { votes }
						results  = { results }
					/> :
					<Bars
						options      = { options }
						votes        = { votes }
						resultShared = { resultShared }
					/>
				}
			</div>
		</div>
	);
}

function Bars ({options, resultShared, votes}) {
	const localize = useTranslation().t;
	const styles = (percent) => {
		percent = percent + "%";
		return {
			parent : {
				background : `linear-gradient(90deg, #FFFFFF1A ${percent || '0%'}, #1C1C1C 0)`
			},
		};
	};

	return (
		<div>
			{options.map ((option, index) => (
				<div
					key       = { index }
					className = 'pollDisplay-bars-container'
				>
					<div className = 'pollDisplay-bars-optionNo'>{ localize (`display:poll:option_${index + 1 }`) }</div>
					<div
						className = 'pollDisplay-bars-option-container'
						style     = { resultShared ? styles(votes[index]).parent : null }
					>
						<div className = 'pollDisplay-bars-option'> {option} </div>
						{resultShared ? <div className = 'pollDisplay-bars-percent'> {votes[index]}% </div> : null}
					</div>
				</div>
			))}
		</div>
	);
}

function Pie ({options, votes, results}) {
	const localize = useTranslation().t;
	const localToken = 'display:poll';
	const colors = ['#83D49D', '#C9ADFF', '#A2E9FF', '#FFE5B4', '#909191', '#3F8CFF', '#FFA238', '#5CA700', '#FE4D4F', '#AFBAC933'];

	let data = Object.keys (votes).map (v => {return {x : v, y : votes[v], label : options[v]};});

	const getText = (obj) => {
		return `${obj.datum?.y}% \n ${obj.datum?.label}`;
	};

	const tooltip = 
		<VictoryTooltip
			orientation        = "top"
			cornerRadius       = { 8 }
			flyoutStyle        = {{ fill: "#151515", stroke : '#313131'}}
			pointerOrientation = "bottom"
			flyoutPadding      = { 20 }
			text               = { getText }
			style              = { [
				{fill : '#FFF', stroke : '#FFF', strokeWidth : '0.1%', fontSize : '20px'},
				{fill : '#FFFFFF99', stroke : '#FFFFFF99', fontSize : '14px', dy : '20'}
			] }
		/>;

	return (
		<div className = 'pollDisplay-pie-container'>
			<div className = 'pollDisplay-pie-graph'>
				<VictoryPie
					colorScale     = { colors }
					responsive     = { true }
					data           = { data }
					innerRadius    = { 100 }
					labelRadius    = { 100 }
					labelComponent = { tooltip }
					labels         = { () => "" }
				/>
				<div className = 'pie-label'>
					<div className = 'pollDisplay-pie-total'> {results.length} </div>
					<div className = 'pollDisplay-pie-total-votes'>{ localize (localToken + ':total_votes') }</div>
				</div>
			</div>
			<div className = 'pollDisplay-pie-legends'>
				{options.map ((opt, index) => (
					<div
						key = { index }
						className = 'pollDisplay-pie-legend-opt'
					>
						<div className = 'pollDisplay-pie-option-container'>
							<span
								className = 'pollDisplay-pie-legend-icon'
								style     = {{backgroundColor : colors[index]}}
							> 
							</span>
							<div className = 'pollDisplay-pie-option'> {opt} </div>
						</div>
						<div className = 'pollDisplay-pie-percent'> {data[index]?.y}% </div>
					</div>
				))}			
			</div>
		</div>
	);
}

PollDisplay.propTypes = {
	poll : PropTypes.any.isRequired,
};

export default PollDisplay;