import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	name     : '',
	image    : '',
	loggedIn : false
};

const slice = createSlice ({
	name : 'login',
	initialState,
	reducers : {
		update (state, action) {
			state.name     = action.payload?.name;
			state.image    = action.payload?.image;
			state.loggedIn = action.payload?.loggedIn;
		},
		updateName (state, action) {
			state.name = action.payload;
		},
		updateImage (state, action) {
			state.image = action.payload;
		},
		updateLoginStatus (state, action) {
			state.loggedIn = action.payload;
		},
		logout () {
			return initialState;
		}
	}
});

export const { 
	update,
	updateName,
	updateImage,
	updateLoginStatus,
	logout,
} = slice.actions;

export default slice.reducer;
