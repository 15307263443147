/*This redux store contains all the settings informations of an event*/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	comment : {
		allowComments       : null,
		autoApproveComments : null,
		showOn              : ''
	},
	polling : {
		showResPriorSub : null,
		showResult      : null,
		showResultsOn   : null,
	},
	mic : {
		allowRequests           : true,
		autoApprove             : false,
		allowParticipantVideo   : true,
		allowParticipantContent : true,
		showOn                  : "MOBILE&DISPLAY"
	},
};

const slice = createSlice ({
	name : 'settings',
	initialState,
	reducers : {
		initCommentSettings (state, action) {
			let _currentSettings = state.comment; 
			let payload = action.payload;
			if (!payload) 
				return;
 
			_currentSettings.allowComments       = payload?.allowComments.allowed;
			_currentSettings.autoApproveComments = payload?.autoApproveComments.allowed;
			_currentSettings.showOn              = payload?.showOn.allowed;
		},

		initMicSettings (state, action) {
			const _currentSettings = state.mic;
			const payload          = action.payload;
			if (!payload) {
				return;
			}

			_currentSettings.allowRequests           = payload?.allowRequests.allowed;
			_currentSettings.autoApprove             = payload?.autoApprove.allowed;
			_currentSettings.allowParticipantVideo   = payload?.allowParticipantVideo.allowed;
			_currentSettings.allowParticipantContent = payload?.allowParticipantContent.allowed;
			_currentSettings.showOn                  = payload?.showOn.allowed;
		},

		initPollSettings (state, action) {
			let _currentSettings = state.polling; 
			let payload = action.payload;
			if (!payload) 
				return;
 
			_currentSettings.showResPriorSub     = payload?.showResPriorSub.allowed;
			_currentSettings.showResult          = payload?.showResult.allowed;
			_currentSettings.showResultsOn       = payload?.showResultsOn.allowed;
		},

		updateSettings (state, action) {
			let payload = action.payload;
			let __settings = '';
			switch (payload.settings) {
				case 'atom' :
					__settings = 'mic';
					break;
				case 'comment' :
					__settings = 'comment';
					break;
				case 'polling' :
					__settings = 'polling';
					break;
				default :
					return;
			}
			if (!payload || !state[__settings] || !payload.data) {
				return;
			}
			let __data = Object.keys (payload.data);
			__data.forEach (setting => {
				if (state[__settings][setting] !== undefined) {
					state[__settings][setting] = payload.data[setting].allowed;
				}
			});
		},

		clearSettingsStore () {
			return initialState;
		}
	},
});

export const {
	initCommentSettings,
	updateSettings,
	initPollSettings,
	initMicSettings,
	clearSettingsStore,
	updateMicSettings,
} = slice.actions;

export default slice.reducer;
