const path = {
	login          : '/',
	landing        : '/landing',
	language       : '/language',
	eventPin       : '/eventPin',
	connect        : '/connect',
	eventLanding   : '/eventLanding',
	poll           : '/poll',
	commentPending : '/commentPending',
	profilePhoto   : '/profilePhoto',
	sentRequest    : '/sentRequest',
	liveCall       : '/liveCall',
	goLiveApproved : '/goLiveApproved',
	pastLanding    : '/pastLanding',
	comment        : '/comment',
	onlineLanding  : '/onlineLanding',
};

export default path;
