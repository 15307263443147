/* eslint-disable no-console */

class Stella {

	/*
	 * Parameters are as follow :
	 * a. "url"        : endpoint for stella server
	 * b. "rest"       : rest library object for making get and post calls
	 * c. "store"      : local storage object for storing device secret and token
	 * d. "roomId"     : room to join on stella
	 * e. "deviceInfo" : device information is needed while 
	 *		{
	 *			deviceOS  : "iOS",
	 *			OSVersion : "14.0",
	 *			model     : "iPhone 12",
	 *		}
	 */

	/*
	 *	Store structure
	 *		{
	 *			get : async fn, parameters - (key)
	 *			set : asycn fn, parameters - (key, value)
	 *		}
	 */

	/*
	 *	Rest API structure
	 *		{
	 *			get  : async fn, parameters - (url, headers)
	 *			post : async fn, parameters - (url, payload, headers)
	 *			put  : async fn, parameters - (url, payload, headers)
	 *		}
	 *	Response structure should include complete response such as data and headers
	 */

	constructor (parameters) {
		if (!parameters)
			throw new Error ('required parameters are missing');

		if (!parameters.url)
			throw new Error ('url not provided');

		if (!parameters.rest?.get || !parameters.rest?.post || !parameters.rest?.put)
			throw new Error ('api call library not provided');

		if (!parameters.store?.get || !parameters.store?.set)
			throw new Error ('local store not provided');

		if (!parameters.roomId)
			throw new Error ('room id not provided');

		if (!parameters.deviceInfo?.deviceOS || !parameters.deviceInfo?.OSVersion || !parameters.deviceInfo?.model)
			throw new Error ('device information not provided');

		this.url        = parameters.url;
		this.rest       = parameters.rest;
		this.store      = parameters.store;
		this.deviceInfo = parameters.deviceInfo;
		this.roomId     = parameters.roomId;
		this.storeKey   = parameters.storeKey || 'stella-information';
		this.sessionId  = null;
	}

	async connect () {
		console.log ('Connecting Stella');
		try {
			// await this.ready();
			await this.login ();

			let response = await this.join ();

			return response;
		}
		catch (err) {
			throw new Error (err);
		}
	}

	async disconnect () {
		console.log ("Disconnecting from stella");
		try {
			await this.leave ();
		}
		catch (err) {
			/* throw new Error (err); */
			/*
			 * if session id has expired, login again using the same device secret 
			 * and try the disconnect with new session id
			 *
			 */

			try {
				await this.login ();
			}
			catch (err) {
				throw new Error (err);
			}
		}
	}

	async login () {
		console.log ("login into stella");

		try {
			let storedData = await this.store.get (this.storeKey);
			let payload    = {
				deviceInfo   : {
					deviceOS  : this.deviceInfo.deviceOS,
					OSVersion : this.deviceInfo.OSVersion,
					model     : this.deviceInfo.model
				},
				deviceSecret : storedData?.deviceSecret
			};
			let response   = await this.rest.post (`${this.url}/api/v1/login/anonymous`, payload);
			console.debug (response, 'anonymous login response from stella');

			if (!response?.data?.deviceId || !response?.data?.deviceSecret || !response?.headers?.sessionid)
				throw new Error ('response fields are missing');

			this.sessionId = response.headers.sessionid;
			/* Saving all this information in local store */
			await this.store.set (this.storeKey, {
				deviceId     : response.data.deviceId,
				deviceSecret : response.data.deviceSecret,
				sessionId    : this.sessionId
			});

			return this.sessionId;
		}
		catch (err) {
			throw new Error ('stella anonymous login failed', { err });
		}
	}

	async ready () {
		console.log ("Checking stella");

		try {
			let headers  = { sessionid : this.sessionId };
			let response = await this.rest.get (`${this.url}/api/v1/server/ready`, headers);

			if (!response.isReady)
				throw new Error ('stella server is not ready');

			return true;
		}
		catch (err) {
			throw new Error ('stella server is not ready', { err });
		}
	}

	async join () {
		console.log ('Joining room in stella');

		try {
			let headers = { sessionid : this.sessionId };
			let payload = { roomId    : this.roomId };

			let response = await this.rest.put (`${this.url}/api/v1/collaboration/room/session/join`, payload, headers);

			if (!response.ticket)
				throw new Error ('failed to join room on stella');

			return response;
		}
		catch (err) {
			throw new Error ('failed to join room on stella', { err });
		}
	}

	async leave () {
		console.log("Leaving room in stella");
		try {
			let storedData = await this.store.get (this.storeKey);
			let headers    = { sessionid: this.sessionId || storedData?.sessionId };
			let payload    = { roomId: this.roomId };

			let response   = await this.rest.put (`${this.url}/api/v1/collaboration/room/session/leave`, payload, headers);

			/* Removing saved information from local store */
			await this.store.set (this.storeKey, {});

			return response;
		}
		catch (err) {
			throw new Error('failed to leave room on stella ', { err });
		}
	}
}

export default Stella;
