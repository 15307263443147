import React, { useState, useEffect, useRef } from 'react';
import PropTypes                              from 'prop-types';
import { useSelector }                        from 'react-redux';
import { useTheme }                           from '@material-ui/core/styles';
import { useTranslation }                     from 'react-i18next';
import Grid                                   from '@material-ui/core/Grid';
import Typography                             from '@material-ui/core/Typography';

import Button                                 from 'components/atoms/Button';
import IconButton                             from 'components/atoms/IconButton';
import EventInfoModal                         from 'components/molecules/EventInfoModal';
import LMAskMicEnabled                        from 'assets/icons/light/ask-mic-enabled.svg';
import LMAskMicDisabled                       from 'assets/icons/light/ask-mic-disabled.svg';
import DMAskMicDisabled                       from 'assets/icons/dark/ask-mic-disabled.svg';
import DMAskMicEnabled                        from 'assets/icons/dark/ask-mic-enabled.svg';

import 'styles/components/molecules/eventLandingBottomBar.css';

function EventLandingBottomBar ({ live, askToSpeak, askLoading, isMicProcessing, isMicReqPending, handleSelectedTab, selectedTab, participant, isPast, isMicAllowed }) {

	const theme = useTheme ().palette.type;
	const { t } = useTranslation ();

	const commonStore = useSelector (state => state.common);

	const { badges } = commonStore;

	const infoRef = useRef (null);

	const [showInfo, setShowInfo]       = useState (false);
	const [askDisabled, setAskDisabled] = useState (false);

	useEffect (() => {
		if(isMicProcessing || isMicReqPending) {
			setAskDisabled (true);
			return;
		}
		setAskDisabled (false);
	}, [isMicProcessing, isMicReqPending]);


	const handleEventInfo = () => {
		setShowInfo (!showInfo);
	};

	const getIcon = () => {
		let isDisabled = askDisabled || !isMicAllowed;
		if (theme === 'light' && isDisabled) {
			return LMAskMicDisabled;
		}
		if (theme === 'dark' && isDisabled) {
			return DMAskMicDisabled;
		}
		if (theme === 'light') {
			return LMAskMicEnabled;
		}
		return DMAskMicEnabled;
	};

	const eventInfo = showInfo && 
		<EventInfoModal 
			open         = { showInfo }
			handleClose  = { handleEventInfo }
			eventInfo    = { live }
			innerRef     = { infoRef }
			participant  = { participant }
		/>;

	const askBtn = !isPast &&
		<Button 
			disabled  = { askDisabled || !isMicAllowed } 
			loading   = { askLoading } 
			onClick   = { askToSpeak } 
			variant   = 'contained' 
			size      = 'large' 
			className = 'eventLandingBottomBar-ask-btn'
			id        = 'eventLanding_ask_btn'
		>
			<img src={ getIcon() } />
			{t('in_event:speak.ask')}
		</Button>; 

	return (
		<Grid className = 'eventLandingBottomBar-container' container alignItems = 'center' wrap = 'nowrap'>
			<Grid item xs = { 5 } sm = { 4 }>
				<Typography variant = 'h1' color = 'secondary' className = 'eventLandingBottomBar-event-title'> {t('in_event:body.event_name')} </Typography>
				<Grid container wrap = 'nowrap' alignItems = 'center'>
					<Typography variant = 'subtitle1' className = 'mt-xs' noWrap> {live.name} </Typography>
					<IconButton
						size      = { 15 }
						icon      = 'Info'
						className = 'mr-m ml-m mt-xs'
						onClick   = { handleEventInfo }
						innerRef  = { infoRef }
					/>
				</Grid>
			</Grid>
			<Grid item xs = { 7 } sm = { 4 } container justify = 'center'>
				{askBtn}
			</Grid>
			<Grid item xs = { 12 } sm = { 4 } container justify = 'flex-end' wrap = 'nowrap'  className = 'eventLandingBottomBar-action-btn'>
				{isPast ? 
					<IconButton 
						size      = { 50 }
						icon      = { selectedTab === 'playlist' ? 'PlaylistEnabled' : 'PlaylistDisabled' }
						color     = { selectedTab === 'playlist' ? 'primary' : 'secondary' }
						onClick   = { () => handleSelectedTab ('playlist') }
						style     = {{display : 'none'}}
					/> 
					: null}
				<IconButton 
					size      = { 50 }
					className = { isPast ? 'ml-xl mr-xl' : '' }
					icon      = { selectedTab === 'comment' ? 'CommentEnabled' : 'CommentDisabled' }
					color     = { selectedTab === 'comment' ? 'primary' : 'secondary' }
					onClick   = { () => handleSelectedTab ('comment') }
					badge     = { true }
					badge     = { badges.comment }
				/>
				<IconButton 
					size      = { 50 }
					className = { isPast ? '' : 'ml-xl mr-xl' }
					icon      = { selectedTab === 'poll' ? 'PollEnabled' : 'PollDisabled' }
					color     = { selectedTab === 'poll' ? 'primary' : 'secondary' }
					onClick   = { () => handleSelectedTab ('poll') }
					badge     = { badges.poll }
				/>
				{!isPast ? 
					<IconButton 
						size      = { 50 }
						icon      = { selectedTab === 'sent' ? 'SentReqEnabled' : 'SentReqDisabled' }
						color     = { selectedTab === 'sent' ? 'primary' : 'secondary' }
						onClick   = { () => handleSelectedTab ('sent') }
						badge     = { badges.sent }
					/> 
					: null}
			</Grid>
			{eventInfo}
		</Grid>
	);
}

EventLandingBottomBar.propTypes = {
	selectedTab       : PropTypes.oneOf (['comment', 'sent', 'poll', 'playlist', '']).isRequired,
	handleSelectedTab : PropTypes.func.isRequired,
	live              : PropTypes.any.isRequired,
	askToSpeak        : PropTypes.func,
	isMicProcessing   : PropTypes.bool,
	isMicReqPending   : PropTypes.bool,
};

export default EventLandingBottomBar;
