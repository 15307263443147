import React, { useRef }             from 'react';
import { useTranslation }            from 'react-i18next';
import Typography                    from '@material-ui/core/Typography';
import { useTheme }                  from '@material-ui/core/styles';

import IconButton                    from 'components/atoms/IconButton';
import Log                           from 'common/log';

import 'styles/components/molecules/uploadPhoto.css';

const log  = Log ('UploadPhoto', 'info');

const i18nNamespaces = ["profile_photo"];

function UploadPhoto ({onUploadPhoto}) {

	const { t }    = useTranslation (i18nNamespaces);
	const theme    = useTheme ().palette.type;

	const uploadImg = useRef (null); 

	const handleFileOpen = () => {
		uploadImg.current.click();
	};

	const handleImgChange = (event) => {
		event.stopPropagation();
		event.preventDefault();
		let file = event.target.files[0];
		if (!file) {
			log.log ('no file selected');
			return;
		}

		let reader = new FileReader ();
		reader.readAsDataURL (file);
		reader.onloadend = () => {
			onUploadPhoto (reader.result);
		};
	};

	return (
		<React.Fragment>
			<IconButton
				color    = 'secondary'
				icon     = 'Gallery'
				onClick  = { handleFileOpen }
				size     = { 45 }
			/>
			<Typography variant = 'h2' align = 'center' color = { theme === 'light' ? 'primary' : 'textPrimary' } className = 'mt-s'> 
				{t('body.gallery')}
			</Typography>
			<input 
				type     = 'file' 
				id       = 'img-upload-input'
				ref      = { uploadImg } 
				onChange = { handleImgChange }
				accept   = 'image/*'
			/>
		</React.Fragment>
	);
}

export default UploadPhoto;
