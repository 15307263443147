import React, { useState, useCallback, useEffect } from 'react';
import PropTypes                                   from 'prop-types';
import Cropper                                     from 'react-easy-crop';
import Grid                                        from '@material-ui/core/Grid';
import Slider                                      from '@material-ui/core/Slider';

import IconButton                                  from 'components/atoms/IconButton';
import {getCroppedImg}                             from 'common/croppedImg';
import Log                                         from 'common/log';

import 'styles/components/molecules/imgCropModal.css'; 

const log = Log ('ImgCropModal', 'info');

function ImgCropModal ({ img, croppedImage }) {

	const [crop, setCrop]                           = useState ({ x: 0, y: 0 });
	const [zoom, setZoom]                           = useState (1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState (null);

	const onCropComplete = useCallback ((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels (croppedAreaPixels);
	}, []);

	useEffect (() => {
		onSaveCrop ();
	},[img, croppedAreaPixels]);

	const onSaveCrop = async () => {
		try {
			let cropped = await getCroppedImg (img, croppedAreaPixels);
			croppedImage (cropped);
		} 
		catch (error) {
			log.error ({error}, 'error saving cropped image');
			return;
		}
	};

	const handleZoomChange = (event, zoom) => {
		setZoom (zoom);
	};

	return (
		<Grid className = 'imgCropModal'>
			<Grid className = 'imgCropModal-crop-container'>
				<Cropper
					image           = { img }
					crop            = { crop }
					cropSize        = {{height : 300, width : 300}}
					zoom            = { zoom }
					onCropChange    = { setCrop }
					onCropComplete  = { onCropComplete }
					onZoomChange    = { setZoom }
					cropShape       = "round"
					showGrid        = { false }
					objectFit       = 'vertical-cover'
					aspect          = { 5/ 3 }
				/>
			</Grid>
			<Grid className = 'imgCropModal-zoom-container'>
				<IconButton icon = 'Minus' size = { 15 } onClick = { (e) => handleZoomChange (e, zoom - 0.1) }/>
				<Slider
					value        = { zoom }
					min          = { 1 }
					max          = { 3 }
					step         = { 0.1 }
					onChange     = { handleZoomChange }
					orientation  = 'horizontal'
					className    = 'ml-m mr-m'
				/>
				<IconButton icon = 'Plus' size = { 15 } onClick = { (e) => handleZoomChange (e, zoom + 0.1) }/>
			</Grid>
		</Grid>
	);
}

ImgCropModal.propTypes = {
	img          : PropTypes.string.isRequired,
	croppedImage : PropTypes.func.isRequired,
};

export default ImgCropModal;
