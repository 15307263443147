import React                                from 'react';
import PropTypes                            from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useTheme }                         from '@material-ui/core/styles';

import 'styles/components/atoms/circularProgress.css';

function CircularProgress ({ value, maxValue, minValue, hideLabel, checked }) {

	const theme = useTheme ().palette.type;

	return (
		<div className = 'circularProgress container'>
			<CircularProgressbar 
				value            = { value }
				maxValue         = { maxValue }
				minValue         = { minValue }
				strokeWidth      = { 2 }
				counterClockwise = { true }
				styles           = { buildStyles({
					pathColor              : theme === 'light' ? 'var(--blue-blue)': 'var(--dodger-blue)',
					trailColor             : theme === 'light' ? '#CECECEBF' : 'var(--white-10)',
					pathTransitionDuration : 1
				}) }
			/>
			{!hideLabel ? <span className = 'circularProgress-label'> {value} </span> : null}
			{checked ? <span className = 'circularProgress checked'> </span> : null}
		</div>
	);
}

CircularProgress.propTypes = {
	value     : PropTypes.number.isRequired,
	maxValue  : PropTypes.number.isRequired,
	minValue  : PropTypes.number,
	hideLabel : PropTypes.bool,
	checked   : PropTypes.bool,
};

export default CircularProgress;
