import React              from 'react';
import moment             from 'moment';
import PropTypes          from 'prop-types';
import { useTheme }       from '@material-ui/core/styles';
import Grid               from '@material-ui/core/Grid';
import Typography         from '@material-ui/core/Typography';
import Avatar             from '@material-ui/core/Avatar';

import LMClock            from 'assets/icons/light/clock.svg';
import DMClock            from 'assets/icons/dark/clock.svg';
import LMUserProfile      from 'assets/icons/light/userProfile.svg';
import DMUserProfile      from 'assets/icons/dark/userProfile.svg';

import 'styles/components/molecules/commentCard.css';

function CommentCard ({ name, comment, avatar, ts }) {

	const theme    = useTheme ().palette.type;

	return (
		<Grid className = 'commentCard'>
			<Grid container alignItems = 'center'>
				<Grid item xs = { 6 } container alignItems = 'center'>
					<Avatar src = { avatar ? avatar : theme === 'light' ? LMUserProfile : DMUserProfile } className = 'commentCard-avatar'/>
					<Typography variant = 'subtitle1' className = 'ml-m mr-m' className = 'commentCard-name'> {name} </Typography>
				</Grid>
				<Grid item xs = { 6 }>
					<Typography variant = 'body2' className = 'eventCard-time' align = 'right'>
						<img src = { DMClock } className ='mr-xs ml-xs' alt = "clock"/>
						{moment (ts).format ('h:mm a')}
					</Typography>
				</Grid>
			</Grid>
			<Grid className = 'mt-xl'>
				<Typography variant = 'h2' color = 'secondary'> {comment} </Typography>
			</Grid>
		</Grid>
	);
}	

CommentCard.propTypes = {
	name    : PropTypes.string.isRequired,
	comment : PropTypes.string.isRequired,
	ts      : PropTypes.string.isRequired,
};

export default CommentCard;
