import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector }                                    from 'react-redux';
import { useTranslation }                                 from 'react-i18next';
import ClickAwayListener                                  from '@material-ui/core/ClickAwayListener';
import Grow                                               from '@material-ui/core/Grow';
import Tooltip                                            from '@material-ui/core/Tooltip';
import Avatar                                             from '@material-ui/core/Avatar';
import Paper                                              from '@material-ui/core/Paper';
import Popper                                             from '@material-ui/core/Popper';
import MenuItem                                           from '@material-ui/core/MenuItem';
import MenuList                                           from '@material-ui/core/MenuList';

import { ThemeContext }                                   from 'styles/theme';
import Language                                           from 'components/molecules/Language';
import ChangeUserInfoModal                                from 'components/molecules/ChangeUserInfoModal';
import LMUserProfile                                      from 'assets/icons/light/userProfile.svg';
import DMUserProfile                                      from 'assets/icons/dark/userProfile.svg';

import 'styles/components/molecules/settings.css';

const i18nNamespaces = ['settings'];

function Settings () {

	const { t }      = useTranslation (i18nNamespaces);
	const loginStore = useSelector (state => state.login);

	const { image } = loginStore;

	const { currentTheme : theme, setTheme } = useContext (ThemeContext);

	const [open, setOpen]               = useState (false);
	const [profileOpen, setProfileOpen] = useState (false);

	const anchorRef = useRef (null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	const handleEditProfile = () => {
		setProfileOpen (!profileOpen);
	};

	const changeTheme = () => {
		if (theme ==='dark') {
			setTheme ('light');
			return;
		}
		setTheme ('dark');
	};

	return (
		<div className = 'settings'>
			<Tooltip title = 'Profile' arrow = { true } TransitionProps = {{ timeout: 600 }} placement = 'right'>
				<Avatar src = { image ? image : theme === 'light' ? LMUserProfile : DMUserProfile } className = 'landingLeftBar-profile' ref = { anchorRef } onClick = { handleToggle }/>
			</Tooltip>
			<Popper 
				open          = { open }
				anchorEl      = { anchorRef.current }
				role          = { undefined }
				transition    = { true }
				placement     = 'right-end'
				className     = 'settings-popper'
				modifiers     = {{
					offset : {
						enabled : true,
						offset  : '-16, 11'
					}
				}}>
				{({ TransitionProps }) => (
					<Grow
						{ ...TransitionProps }
						style = {{ transformOrigin: 'left bottom'}}
					>
						<Paper className = 'settings-container'>
							<ClickAwayListener onClickAway = { handleClose }>
								<MenuList onKeyDown = { handleListKeyDown }>
									<MenuItem > <Language direction = 'right' /> </MenuItem>
									<MenuItem onClick = { handleEditProfile }> {t('modal.change_user_info')} </MenuItem>
									<MenuItem onClick = { changeTheme }> {t('body.toggle_theme')} </MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
			<ChangeUserInfoModal open = { profileOpen } onClose = { handleEditProfile }/>
		</div>
	);
}

export default Settings;
