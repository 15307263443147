import Transport        from './transport';

let transport  = {};

export const Create = async (payload, options) => {
	if (!payload)
		throw new Error ('session join fail : payload undefined');

	if (!payload.uuid)
		throw new Error ('session join fail : no unique identification provided');

	transport = new Transport ({
		type           : options.type,
		socket_url     : options.socketUrl,
		on_req         : options.onReq,
		on_info        : options.onInfo,
		on_close       : options.onClose,
		ping_interval  : options.pingInterval,
		max_ping_drops : options.maxPingDrops
	});

	await transport.connect ();
	await transport.authenticate ({ 
		identity : {
			id          : payload.uuid,
			displayName : payload.user,
			avatar      : payload.avatar,
			entityType  : 'participant',
			event_id    : payload.event_id,
		}
	});
	if(process.env.NODE_ENV === 'development') {
		window.transport = transport;
	}
};

export const authenticate = async (payload) => {
	if (!payload)
		throw new Error ('authentication fail : payload undefined');

	if (!payload.uuid)
		throw new Error('session join fail : no unique identification provided');

	await transport.authenticate ({ identity : {
		id          : payload.uuid,
		displayName : payload.user,
		avatar      : payload.avatar,
		entityType  : 'participant'
	}});
};

export const sendRequest = (to, command, data, from) => {
	return transport.request (to, command, data, from);
};

export const sendInfo = (to, command, data, from) => {
	return transport.info (to, command, data, from);
};

export const Terminate = (code, reason) => {
	return transport.terminate (code, reason);
};
