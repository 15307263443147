/*keys for saving info in localstorage*/

export const BUILD_TYPE = 'DEV'; // 'DEV' or 'PROD'

export const storageKey = {
	lts        : 'pa_lts',
	profile    : 'pa_profile',
	onboarded  : 'pa_is_onboarded',
	stellaInfo : 'pa_stella_info'
};

export const TOAST_TIMEOUT = 4; //time in sec

export const isOnline = Number(process.env.REACT_APP_IS_ONLINE) && true;

export const CloudConfig = {
	PROD : {
		LmiAddress  : "https://crowdmics-api.azurewebsites.net",
		CMMSAddress : "https://biamp-prod-sw.antarx.tech",
	},
	DEV : {
		LmiAddress  : "https://crowdmics-api-antares.azurewebsites.net",
		CMMSAddress : "https://biamp-staging.antares-tech.com",
	},
};

export const CMMSAddress = CloudConfig[BUILD_TYPE].CMMSAddress;
