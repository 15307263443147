import orderBy from 'lodash/orderBy';

const utils = {};

utils.hostname = () => {
	let hostname;
 
	if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_ATOM_BASEURL) {
		hostname = process.env.REACT_APP_ATOM_BASEURL;
		return hostname;
	}                                                                                                                                                    
	hostname = `${window.location.hostname}`;
	return hostname;
};

utils.uniqueId = (length) => {
	var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var uid = '';
	for ( var i = 0; i < length; i++ ) {
		uid += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
	}
	return uid;
};

utils.getImageInfo = (image) => {
	if (typeof image !== 'string') {
		return;
	}
	let meme = image.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
	let result = {};
	if (meme && meme.length) {
		result.meme = meme[1];
	}
	let data = image.split (',');
	let contentType;
	if (data && data.length) {
		result.data = data[1];
		contentType = data[0].split (';');
	}
	if (contentType && contentType.length) {
		result.contentType = contentType[1];
	}
	return result;
};

utils.getImgSrc = (imgInfo) => {
	if (!imgInfo || !imgInfo.data) {
		return;
	}
	return `data:image/jpeg;base64,${imgInfo.data}`;
};


utils.getSortedEvent = (events, sortBy, order) => {
	return orderBy (events, [(event) => event[sortBy].toLowerCase()], order);
};

utils.sleep = (ms) =>  {
	return new Promise(r => setTimeout(r, ms));
};

export default utils;
