import React, { useState, useEffect } from 'react';
import Grid                           from '@material-ui/core/Grid';
import { useTheme }                   from '@material-ui/core/styles';

import IconButton                     from 'components/atoms/IconButton';
import DisplayVideo                   from 'components/molecules/DisplayVideo';
import useFullScreen                  from 'libs/hooks/useFullScreen';

import 'styles/components/molecules/videoPlayer.css';

function VideoPlayer () {

	const theme = useTheme().palette.type;

	const { fullScreen, toggle } = useFullScreen ('videoPlayer');

	const [isFullScreen, setIsFullScreen] = useState (false);
	const [muted, setMuted]               = useState (true);

	useEffect (() => {
		setIsFullScreen (fullScreen);
	}, [fullScreen]);

	const handleVolume = () => {
		setMuted (!muted);
	};

	const fullScreenIcon = !isFullScreen && 
		<IconButton 
			icon      = 'FullScreen'
			size      = { 15 }
			className = 'videoPlayer-icon fullscreen'
			onClick   = { toggle }
		/>;

	const closeFullScreenIcon = isFullScreen &&  
		<IconButton 
			icon      = 'CloseFullScreen'
			size      = { 15 }
			className = 'videoPlayer-icon close-fullscreen'
			onClick   = { toggle }
		/>;

	return (
		<Grid className = 'videoPlayer parent' id = 'videoPlayer'>
			<Grid>
				<DisplayVideo 
					muted = { muted }
					theme = { theme }
				/>
			</Grid>
			<IconButton 
				icon      = { muted ? 'VolumeOff' : 'VolumeOn' }
				size      = { 15 }
				className = 'videoPlayer-icon volume'
				onClick   = { handleVolume }
			/>
			{fullScreenIcon}
			{closeFullScreenIcon}
		</Grid>
	);
}

export default VideoPlayer;
