import React, { useEffect }        from 'react';
import { deviceDetect }            from 'react-device-detect';
import { Provider }                from 'react-redux';
import Grid                        from '@material-ui/core/Grid';

import store                       from 'redux/store';
import Navigations                 from 'navigations';
import { logContainer }            from 'libs/logger';
import { AlertProvider }           from 'components/atoms/Alert';
import { PageLoaderProvider }      from 'components/atoms/PageLoader';
import ThemeProvider               from 'styles/theme';
import Toast                       from 'components/atoms/Toast';

const log = logContainer ('App.js');

function App () {

	useEffect (() => {
		log.info (`pa-web started on browser`, deviceDetect ());
	}, []);

	return (
		<Provider store = { store }>
			<ThemeProvider>
				<PageLoaderProvider >
					<AlertProvider>
						<Grid className = 'root-parent'>
							<Navigations />
							<Toast />
						</Grid>
					</AlertProvider>
				</PageLoaderProvider>
			</ThemeProvider>
		</Provider>
	);
}

export default App;
