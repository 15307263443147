import React, { useState, useEffect } from 'react';
import { useTranslation }             from 'react-i18next';
import {useTheme}                     from '@material-ui/core/styles';
import Grid                           from '@material-ui/core/Grid';
import Typography                     from '@material-ui/core/Typography';
import Checkbox                       from '@material-ui/core/Checkbox';

import 'styles/components/atoms/bars.css';

const i18nNamespaces = ["polls"];

function Bars ({ title, percentage, index, isSelected, multiResponse, handlePollSubmit, resultShared, isSubmitted, selected, checkboxOptSel }) {

	const { t } = useTranslation (i18nNamespaces);
	const theme = useTheme().palette.type;
	
	const [percent, setPercent] = useState ('');

	useEffect (() => {
		if (typeof percentage !== 'number' || !resultShared) {
			return setPercent ('');
		}
		setPercent (`${percentage}%`);
	}, [percentage, resultShared]);

	const getSelected = () => {
		let _selected = selected?.find (op => op.index === index);
		return _selected;
	};

	const styles = () => {
		if (theme === 'light' && getSelected ()) {
			return {
				parent : {
					background : `linear-gradient(90deg, var(--dodger-blue-20) ${percent || '0%'}, var(--white) 0)`
				},
			};
		}
		else if (theme === 'light') {
			return {
				parent : {
					background : `linear-gradient(90deg, var(--light-periwinkle) ${percent || '0%'}, var(--white) 0)`
				},
			};

		}
		else if (theme === 'dark' && getSelected ()){
			return {
				parent : {
					background : `linear-gradient(90deg, var(--dodger-blue-32) ${percent || '0%'}, var(--black) 0)`
				},
			};
		}
		else {
			return {
				parent : {
					background : `linear-gradient(90deg, var(--white-10) ${percent || '0%'}, var(--black) 0)`
				},
			};
		}
	};

	const handleCheckbox = (event) => {
		handlePollSubmit (index, event.target.checked);
	};

	const showCheckbox = multiResponse && !isSubmitted && 
		<Checkbox 
			checked  = { checkboxOptSel } 
			size     = 'small'
			onChange = { handleCheckbox }
			disableRipple
		/>;

	if (multiResponse && !isSubmitted) {
		return (
			<Grid  className = { `bars-container checkbox ${resultShared ? 'shared' : ''}` }>
				<Typography className = 'bars-opt-no' variant = 'body2' color = 'secondary' className = 'mb-l'> {t('body.option', {counter: index+1 })} </Typography>
				<Grid container alignItems = 'center'>
					{showCheckbox}
					<Typography variant = 'subtitle1' className = { `bars-title ${resultShared ? 'shared' : ''} ${checkboxOptSel ? 'selected' : ''}` }> {title} </Typography>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid className = { `bars-container ${resultShared ? 'shared' : ''}` }>
			<Typography className = 'bars-opt-no' variant = 'body2' color = 'secondary'> {t('body.option', {counter: index+1 })} </Typography>
			<Grid 
				container 
				alignItems = 'center'
				style      = { resultShared ? styles().parent : null }
				className  = { `bars parent ${getSelected() || isSelected ? 'selected' : ''}` }
				onClick    = { () => handlePollSubmit (index, !isSelected) }
			>
				<Grid item container alignItems = 'center' xs = { 10 } sm = { 10 } md = { 10 } lg = { 10 } xl = { 10 }>
					{showCheckbox}
					<Typography variant = 'h1' color = 'secondary' className = { `bars-title ${resultShared ? 'shared' : ''}` }> {title} </Typography>
				</Grid>
				<Grid item xs = { 2 } sm = { 2 } md = { 2 } lg = { 2 } xl = { 2 }>
					<Typography variant = 'h1' color = 'secondary' className = 'bars-percent' align  = 'right'> {percent} </Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Bars;
