import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	streams: {}
};

const slice = createSlice({
	name: 'reconnection',
	initialState,
	reducers: {
		initReconnectionResource(state) {
			/*init layout*/
			state.streams = initialState.streams;
		},

		//expected payload {streamType : "presenter-audio", reconnection : true/false}
		editStream(state, action) {
			state.streams[action.payload.streamType] = action.payload.reconnection;
		},

		//expected payload {streamType : "presenter-audio"}
		deleteStream(state, action) {
			delete state.streams[action.payload.streamType];
		},

		resetReconnectionStore() {
			return initialState;
		}
	}
});

export const {
	initReconnectionResource,
	editStream,
	deleteStream,
	resetReconnectionStore
} = slice.actions;

export default slice.reducer;