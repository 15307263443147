import React, { createContext, useContext, useState } from "react";
import PropTypes                                      from 'prop-types';
import Dialog                                         from "@material-ui/core/Dialog";
import Typography                                     from "@material-ui/core/Typography";
import Grid                                           from "@material-ui/core/Grid";

import Button                                         from "components/atoms/Button";

import 'styles/components/atoms/alert.css';

const Alert = ({ open, title, message, onConfirm, onDismiss, confirmBtn, dismissBtn, dismissColor, dissmissVariant, confirmColor, confirmVariant, textAlign, btnAlign, loading, sideAlert}) => {

	const child = () => {
		return (
			<>
				<Typography variant = 'h3' align = { textAlign }> {title} </Typography>
				<Typography variant = 'h2' color = 'secondary' className = 'mt-m' align = { textAlign }> {message} </Typography>
				<Grid container className = 'mt-xxl' justify = { btnAlign }>
					{dismissBtn ? 
						<Button onClick = { onDismiss } variant = { dissmissVariant } className = 'alert-dismiss-btn' color = { dismissColor }> {dismissBtn} </Button> 
						: null
					}
					<Button color = { confirmColor } onClick = { onConfirm } variant = { confirmVariant } size = 'medium' loading = { loading }> {confirmBtn} </Button>
				</Grid>
			</>
		);
	};

	if (sideAlert && open) {
		return (
			<Grid className = 'sideAlert-backdrop'>
				<Grid className = 'alert-container p-xxl sideAlert'>
					{child ()}
				</Grid>
			</Grid>
		);
	}

	return (
		<Dialog open = { open } onClose = { onDismiss } maxWidth = 'sm' className = 'alert-container'>	
			{child()}
		</Dialog>
	);
};

Alert.propTypes = {
	confirmBtn      : PropTypes.string,
	onConfirm       : PropTypes.func,
	dismissBtn      : PropTypes.string,
	onDismiss       : PropTypes.func,
	title           : PropTypes.string,
	message         : PropTypes.string,
	confirmColor    : PropTypes.string,
	confirmVariant  : PropTypes.string,
	dismissColor    : PropTypes.string,
	dissmissVariant : PropTypes.string,
	textAlign       : PropTypes.oneOf (['left', 'center', 'right']),
	btnAlign        : PropTypes.oneOf (['flex-start', 'center', 'flex-end'])
};

Alert.defaultProps = {
	confirmColor    : 'primary',
	confirmVariant  : 'outlined',
	dismissColor    : 'secondary',
	dissmissVariant : 'outlined',
	btnAlign        : 'flex-end',
};

const AlertContext = createContext ({});

const AlertProvider = ({ children }) => {

	const [open, setOpen]               = useState (false);
	const [alertConfig, setAlertConfig] = useState ({});

	const openAlert = ({ title, message, confirmBtn, dismissBtn, onConfirm, onDismiss, ...other}) => {
		setOpen (true);
		setAlertConfig ({ title, message, confirmBtn, dismissBtn, onConfirm, onDismiss, ...other });
	};

	const closeAlert = () => {
		setOpen (false);
	};

	const onConfirm = () => {
		/*Close the alertbox and if there is any callback function call it*/
		if (alertConfig.onConfirm) {
			alertConfig.onConfirm ();
		}
		closeAlert ();
	};

	const onDismiss = () => {
		/*Close the alertbox and if there is any callback function call it*/
		if (alertConfig.onDismiss) {
			alertConfig.onDismiss ();
		}
		closeAlert ();
	};

	return (
		<AlertContext.Provider value = {{ openAlert }}>
			<Alert
				open            = { open }
				title           = { alertConfig?.title }
				message         = { alertConfig?.message }
				confirmBtn      = { alertConfig?.confirmBtn }
				dismissBtn      = { alertConfig?.dismissBtn }
				confirmColor    = { alertConfig?.confirmColor }
				confirmVariant  = { alertConfig?.confirmVariant }
				dismissColor    = { alertConfig?.dismissColor }
				dissmissVariant = { alertConfig?.dissmissVariant }
				textAlign       = { alertConfig?.textAlign }
				btnAlign        = { alertConfig?.btnAlign }
				onConfirm       = { onConfirm }
				onDismiss       = { onDismiss }
			/>
			{children}
		</AlertContext.Provider>
	);
};

const useAlert = () => {
	const { openAlert } = useContext (AlertContext);

	const okAlert = ({ ...options }) =>
		new Promise (() => {
			openAlert({ ...options });
		});

	return { okAlert };
};

export default Alert;
export { AlertProvider, useAlert };
