import React                from 'react';
import { useSelector }      from 'react-redux';

import DMProfileDefault     from 'assets/icons/dark/userProfile.svg';
import 'styles/components/molecules/displayPresenterAvatar.css';

function DisplayPresenterAvatar(){

	const eventStore         = useSelector (state => state.event);
	const presenterDetails     = eventStore.live.presenterDetails;

	const renderPresenterProfile = (presenter, key) => (
		<div
			className = "presenterLabel"
			key       = { presenter.name + key }
		>
			<img className = "presenterImg" src = { presenter.url || DMProfileDefault }/>
			<div className = "presenterName">{presenter.name}</div>
		</div>
	);

	const renderPresenters = () => {
		let presenters1 = [];
		let presenters2 = [];
		let splitIndex;

		if (presenterDetails.length < 4) {
			presenters1 = presenterDetails;
		} else if (presenterDetails.length === 4) {
			splitIndex = 2;
		} else if (presenterDetails.length > 4 && presenterDetails.length < 7) {
			splitIndex = 3;
		} else {
			splitIndex = 4;
		}

		if (splitIndex) {
			presenters1 = presenterDetails.slice (0, splitIndex);
			presenters2 = presenterDetails.slice (splitIndex);
		}

		return (
			<>
				<div className = "presenterRow">
					{
						presenters1.map((presenterDetails, index) => (
							renderPresenterProfile (presenterDetails, index)
						))
					}
				</div>
				{
					presenters2.length > 0 && 
					<div className = "presenterRow">
						{
							presenters2.map((presenterDetails, index) => (
								renderPresenterProfile (presenterDetails, index)
							))
						}
					</div>
				}
			</>
		);
	};

	return(
		<div className = "presenterInfo">
			{ renderPresenters () }
		</div>
	);
}

export default DisplayPresenterAvatar;
