import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector }    from 'react-redux';
import Typography         from '@material-ui/core/Typography';
import { useTheme }       from '@material-ui/core/styles';

import IconButton         from 'components/atoms/IconButton';

const i18nNamespaces = ["profile_photo"];

function RemovePhoto ({ onRemovePhoto }) {

	const { t }      = useTranslation (i18nNamespaces);
	const theme      = useTheme ().palette.type;
	const loginStore = useSelector (state => state.login);

	const { image } = loginStore;

	return (
		<React.Fragment>
			<IconButton 
				color    = 'secondary'
				disabled = { image ? false : true }
				icon     = { image ? 'RemoveEnabled' : 'RemoveDisabled' }
				onClick  = { onRemovePhoto }
				size     = { 45 }
			/>
			<Typography variant = 'h2' align = 'center' color = { image && theme === 'light' ? 'primary' : image && theme === 'dark' ? 'textPrimary' : 'inherit' } className = 'mt-s'>
				{t('body.remove')}
			</Typography>
		</React.Fragment>
	);
}

RemovePhoto.propTypes = {
	onRemovePhoto : PropTypes.func.isRequired,
};

export default RemovePhoto;
