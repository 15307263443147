/* This redux contains all common store like notifications, badges etc */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	notify : {
		open    : false, /* required */ 
		title   : '',   /* required */
		desc    : '',
		tab     : '',  /*enum [comment, poll, sent]*/
	},
	badges : {
		comment : false,
		poll    : false,
		sent    : false
	},
	pageLoader : {
		showElements : [], /* Show elements above loader*/
	}
};

const slice = createSlice ({
	name : 'common',
	initialState,
	reducers : {
		addNewNotify (state, action) {
			let payload = action.payload;
			if (!payload.open || !payload.title) {
				return;
			}
			state.notify = {...payload};
		},

		closeNotify  (state) {
			state.notify = {...initialState.notify};
		},

		addNewBadge  (state, action) {
			let payload = action.payload;
			if (!payload) {
				return;
			}
			state.badges[payload] = true;
		},

		updatePageLoader (state, action) {
			let payload = action.payload;
			if (!payload) {
				return;
			}
			let keys = Object.keys(payload);
			keys.forEach (key => {
				if (state.pageLoader[key]) {
					state.pageLoader[key]= payload[key];
				}
			});
		},

		clearBadge (state, action) {
			let payload = action.payload;
			if (!payload) {
				return;
			}
			state.badges[payload] = false;
		},

		clearCommonStore () {
			return initialState;
		}
	}
});

export const  {
	addNewNotify, 
	closeNotify,
	addNewBadge,
	updatePageLoader,
	clearBadge,
	clearCommonStore
} = slice.actions;

export default slice.reducer;