/*This redux store contains information regarding comments*/

import { createSlice } from '@reduxjs/toolkit';
import keyBy           from 'lodash/keyBy';

const initialState = {
	approved    : [],
	userPending : [],
};

const changeCommentKeys = (comments) => {
	return comments?.map (commentObj => ({
		comment   : commentObj.comment,
		ts        : commentObj.ts,
		updatedAt : commentObj.updatedAt,
		updatedBy : commentObj.updatedBy,
		user      : commentObj.user,
		uuid      : commentObj.uuid,
		vcId      : commentObj.vc_id,
	})) || [];
};

const slice = createSlice ({
	name : 'comment',
	initialState,
	reducers : {
		initCommentResource (state, action) {
			/*init approved comments*/
			state.approved = changeCommentKeys (action.payload?.comments_approved?.reverse());
			/*init user pending comments*/
			state.userPending = changeCommentKeys (action.payload?.comments_pending_user?.reverse());
		},

		updateApprovedComment (state, action) {
			let comments = changeCommentKeys (action.payload);
			if (!Array.isArray (comments) || !comments.length) {
				return;
			}
			let commentObj = keyBy (comments, 'uuid'); //converting array to object, so that filtering will be efficient

			state.approved = [...comments, ...state.approved];
			state.userPending = [...state.userPending.filter (comment => !commentObj[comment.uuid])];
		},

		sendComment (state, action) {
			let comment = action.payload;
			if (!comment || !comment.uuid) {
				return;
			}
			let autoApprove = comment.auto_approve;
			comment = changeCommentKeys ([comment])[0];

			if (autoApprove) {
				state.approved = [comment, ...state.approved];
				return;
			}
			state.userPending = [comment, ...state.userPending];	
		},

		updateRejectedComment (state, action) {
			let comments = changeCommentKeys (action.payload);
			if (!Array.isArray (comments) || !comments.length) {
				return;
			}
			let commentObj = keyBy (comments, 'uuid'); //converting array to object, so that filtering will be efficient

			state.userPending  = [...state.userPending.filter (comment => !commentObj[comment.uuid])];
			state.approved = [...state.approved.filter (comment => !commentObj[comment.uuid])];
		},

		updateUserPendingComment (state, action) {
			let payload = action.payload;
			if (!payload.uuid || !payload.comment) {
				return;
			}
			let __commentIndex = state.userPending.findIndex (comment => comment.uuid === payload.uuid);
			if (__commentIndex < 0) {
				return;
			}
			let __comment = state.userPending[__commentIndex];
			__comment =  changeCommentKeys([payload])[0];
			state.userPending[__commentIndex] =	__comment;
		},

		removeUserPendingComment (state, action) {
			let uuids    = action.payload;
			let uuidsObj = keyBy (uuids, 'uuid');
			let filtered = [];
			for (let i = 0; i < state.userPending.length; i++) {
				if (uuidsObj[state.userPending[i].uuid] && uuidsObj[state.userPending[i].uuid].taskInfo === 1) {
					continue;
				}
				filtered.push (state.userPending[i]);
			}
			state.userPending = [...filtered];
		},

		clearCommentStore () {
			return initialState;
		}
	}
});

export const { 
	initCommentResource,
	updateApprovedComment,
	sendComment,
	updateRejectedComment,
	updateUserPendingComment,
	clearCommentStore,
	removeUserPendingComment,
} = slice.actions;

export default slice.reducer;
