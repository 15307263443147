import React, { useState }  from 'react';
import { useTranslation }   from 'react-i18next';
import Grid                 from '@material-ui/core/Grid';
import TextField            from '@material-ui/core/TextField';
import Typography           from '@material-ui/core/Typography';

import Button               from 'components/atoms/Button';

function EditCommentModal ({value, updateComment, onCancel, uuid}) {
	
	const { t } = useTranslation ();

	const [comment, setComment] =  useState (value);
	const [error, setError]     =  useState (false);

	const handleCommentChange = (event) => {
		let { value } = event.currentTarget;
		setComment(value);
	};

	const onUpdateComment = () => {
		if (!comment || comment.trim () === '') {
			setError (true);
			return;
		}
		updateComment (uuid, comment);
		setComment ('');
	};

	const handleKeyPress = (event) => {
		if (event.keyCode === 13) {
			onUpdateComment ();
		}
	};

	return(
		<Grid className = 'sideAlert-backdrop'>
			<Grid className = 'alert-container p-xxl sideAlert'>
				<Grid className = 'mb-l'>
					<Typography variant = 'h3'> Edit Comment </Typography>
				</Grid>
				<TextField 
					className   = 'commentTextBox'
					fullWidth   = { true }
					variant     = 'outlined'
					multiline   = { true }
					error       = { error }
					value       = { comment }
					rows        = { 4 }
					onChange    = { handleCommentChange }
					onKeyUp     = { handleKeyPress }
					inputProps  = {{
						maxLength : 256
					}}
				/>
				<Grid container className = 'mt-xl'>
					<Grid item xs = { 6 }>
						<Button color = 'secondary' variant = 'outlined' onClick = { onCancel }> {t('button:action.cancel')} </Button>
					</Grid>
					<Grid item xs = { 6 } container justify = 'flex-end'>
						<Button color = 'primary' variant = 'outlined' onClick = { onUpdateComment }> {t('button:action.update')} </Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default EditCommentModal;