/*eslint-disable no-console*/

import { deviceDetect }  from 'react-device-detect';

import xhr               from 'libs/xhr';
import store             from 'libs/localStore';
import Stella            from './stella';
import utils             from 'common/utils';
import { storageKey }    from 'common/config';
import Store             from 'redux/store';
import { editStream, 
	deleteStream }       from 'redux/slices/reconnection';

const Owt = global.Owt;

let stella = null, identity = null, owtClient = null, owtResponse = null, streams = {}, publication = null;

const getDeviceInfo = () => {
	const info = deviceDetect ();
	return {
		deviceOS  : info.osName || info.os,
		OSVersion : info.osVersion,
		model     : info.browserName || info.model,
	};
};

export const connectMediaServer = async (config, isOnline = false) => {
	if (!config) {	
		throw new Error ('config not provided');
	}

	let stellaAPIHost, signalingOptions;
	const { proxy, iceServers = [] } = config;
	const clientConfig = {
		rtcConfiguration: {
			iceServers
		},
	};

	try {
		const deviceInfo = getDeviceInfo ();

		if (!isOnline) {
			const proxyHost = `https://${utils.hostname()}`;
			stellaAPIHost     = `${proxyHost}/stella-proxy`;
			signalingOptions  = { proxyHost, signalingOptions : { path : '/stella-proxy/socket.io'} };

		} else {
			stellaAPIHost     = `${config.proto}://${config.host}:${config.port}`;
			signalingOptions  = {};
		}

		const params = {
			url : stellaAPIHost,
			rest : {
				get  : (url, headers) => xhr.get (url, { headers }),
				post : (url, payload, headers) => xhr.post (url, payload, { headers }),
				put  : (url, payload, headers) => xhr.put (url, payload, { headers }),
			},
			store : {
				get : async (...args) => store.get (...args),
				set : async (...args) => store.set (...args)
			},
			roomId : config.roomId,
			deviceInfo : {
				deviceOS  : deviceInfo.deviceOS,
				OSVersion : deviceInfo.OSVersion,
				model     : deviceInfo.model,
			},
			storeKey : storageKey.stellaInfo,
		};

		stella  = new Stella (params);
		identity  = await stella.connect ();
		owtClient = await new Owt.Conference.ConferenceClient (clientConfig);
		
		let ticket  = identity.ticket;
		owtResponse = await owtClient.join (ticket, signalingOptions);

		identity.owt = owtResponse.self;

		let response = { owt: owtResponse, ticket: identity.ticket, deviceId: identity.deviceId };
		addStreams ();
		return response;
	}
	catch (err) {
		console.error (err, 'stella connect error');
		throw err;
	}
};

function streamAddedHandler(event) {
	let stream = event.stream;
	console.log("New Stream Received", stream);
	if (stream.attributes) {
		owtResponse.remoteStreams.push(event.stream);
		switch (stream.attributes.streamType) {
			case "participant-audio":
			case "screenshare":
			case "display-video-landscape":
			case "display-video-portrait":
			case "participant-screenshare":
			case "presenter-audio":
			case "presenter-video":
				// streamId = stream.attributes.streamType;
				streams[stream.attributes.streamType] = {
					stream: stream,
					subscribed: false,
					streamType : stream.attributes.streamType,
				};

				break;
			default:
				console.error("Invalid stream received");
		}
	}
}

export const addStreams = () => {
	if (!owtClient || !owtResponse) {
		return;
	}

	const len = owtResponse.remoteStreams.length;
	for (let i = len - 1; i >= 0; i--) {
		if (owtResponse.remoteStreams[i].attributes && !(owtResponse.remoteStreams[i].attributes.streamType in streams)) {
			console.log("Joining Stream", owtResponse.remoteStreams[i]);
			streams[owtResponse.remoteStreams[i].attributes.streamType] = {
				stream: owtResponse.remoteStreams[i],
				subscribed: false,
				streamType : owtResponse.remoteStreams[i].attributes.streamType,
			};
		}
	}

	owtClient.addEventListener("streamadded", streamAddedHandler);

};

export default {
	stellaClient : stella,
	owtClient    : owtClient,
};

export const leaveMediaServer = async () => {
	try {
		if (Object.keys(streams).length > 0){
			Object.entries(streams).forEach(([key, value]) => {
				let streamType = streams[key].stream.attributes?.streamType;
				removeStream( streamType );
			});
		}

		if (owtClient) {
			owtClient.removeEventListener("streamadded", streamAddedHandler);
			owtClient.leave().catch (() => console.warn ('error in leaving server'));
		}

		if (stella) {
			stella.leave().catch(() => console.warn("error leaving stellaClient"));
		}

	}
	catch (err) {
		console.error (err, 'error leaving media server');
		throw err;
	}
	owtClient = null;
	stella = null;
	streams = {};
	identity = null;
	publication = null;
	owtResponse = null;
};

export const getIdentity = () => identity;

export const publishStream = async (stream, custom) => {
	if (!owtClient) {
		return;
	}
	let localstream = new Owt.Base.LocalStream(stream, new Owt.Base.StreamSourceInfo('mic', 'camera'), { streamType: "participant-audio", ...custom});
	publication = await owtClient.publish(localstream);
	return publication;
};

const INIT_SCREENSHARE_STATE =  {
	sharing     : false,
	publication : null,
	stream      : null,
};

export const getScreenShareRef  = () => {
	return INIT_SCREENSHARE_STATE.stream;
};
	
let screenShareState = INIT_SCREENSHARE_STATE;

export const publishScreenShare = async (onEnd) => {
	if (!owtClient) {
		return;
	}
	try {
		let audioConstraints = new Owt.Base.AudioTrackConstraints(Owt.Base.AudioSourceInfo.SCREENCAST);
		let videoConstraints = new Owt.Base.VideoTrackConstraints(Owt.Base.VideoSourceInfo.SCREENCAST);

		let stream = await Owt.Base.MediaStreamFactory.createMediaStream(new Owt.Base.StreamConstraints(audioConstraints, videoConstraints));
		screenShareState.stream      = stream;

		let localStream = new Owt.Base.LocalStream(stream, new Owt.Base.StreamSourceInfo('mic', 'camera'), { streamType: 'participant-screenshare' });
		publication = await owtClient.publish(localStream);
		screenShareState.publication = publication;

		// Handler called when owt stops publication
		publication.addEventListener("ended", () => {
			console.warn("screenShare publication ended");
			stream.getTracks().forEach(track => track.stop());
			onEnd();
			screenShareState = INIT_SCREENSHARE_STATE;
		});

		// Handler called when user stops using browser's Stop button
		stream.getVideoTracks ()[0].addEventListener ("ended", () => {
			console.warn("screenShare steam ended");
			publication.stop()
				.then(() => console.log("stopped sceenshare publication"))
				.catch(() => console.error("error stopping screenshare publication"));
			onEnd();
			screenShareState = INIT_SCREENSHARE_STATE;
		});

		// set module level screen share states
		screenShareState.sharing     = true;

		return publication;
	}
	catch (err) {
		console.error (err, "startshareScreen (this is not typo)");
		throw err;
	}
};

export const unpublishScreenShare = async () => {
	let {sharing, stream, publication} = screenShareState;
	console.warn("stopShareScreen", {sharing, stream, publication});
	publication && publication.stop()
		.then(() => console.log("stopped sceenshare publication"))
		.catch(() => console.error("error stopping screenshare publication"));
	stream && stream.getTracks().forEach(function(track) {
		// browser's active share screen notification closes when you stop screen share tracks
		track.stop();
	});
	screenShareState = INIT_SCREENSHARE_STATE;
};

export const unpublishStream = async () => {
	if (!owtClient || !publication) {
		return;
	}
	publication?.stop();
	publication = null;
};

export const subscribe = async (screen, screens, streamType) => {
	if(streamType in streams){
		let allowAudio = false;
		let allowVideo = false;

		if (streams[streamType].stream?.source?.audio) {
			allowAudio = true;
		}

		if (streams[streamType].stream?.source?.video) {
			allowVideo = true;
		}
		let subscribeOpts = {
			audio : allowAudio,
			video : allowVideo,
		};

		console.log("Subscribing stream", streamType, subscribeOpts);

		await owtClient
			.subscribe(streams[streamType].stream, subscribeOpts)
			.then((subscription) => {
				streams[streamType].subscription = subscription;
				streams[streamType].subscribed = true;
				console.log(streamType, "subscribed");

				streams[streamType].subscription.addEventListener("start_reconnection",()=> {
					Store.dispatch(editStream({streamType : streamType, reconnection : true}));
				});

				streams[streamType].subscription.addEventListener("stop_reconnection",()=> {
					Store.dispatch(editStream({streamType : streamType, reconnection : false}));
				});
 
				streams[streamType].subscription.addEventListener(
					"ended",
					async () => {
						console.log("Stream ended: ", streamType);
						try{
							if (
								screens[screen] &&
								screens[screen].current !== null
							) {
								screens[screen].current.srcObject = null;
								console.log(
									streamType,
									"streams deleted from subscribed streams object"
								);
								const len = owtResponse.remoteStreams.length;
								let index = -1;
								for (let i = 0; i < len; i++) {
									if (
										owtResponse.remoteStreams[i].attributes
											?.streamType === streamType
									) {
										index = i;
										break;
									}
								}
								if (index >= 0 && streams[streamType].subscribed === true && typeof streams[streamType].subscription.stop === 'function') {
									owtResponse.remoteStreams.splice(index, 1);
									streams[streamType].subscription.stop();
								}
							}
						}
						catch(err){
							console.error(err);
						}
						finally{
							if(streamType in streams){
								delete streams[streamType];
							}
						}
					}
				);
			})
			.catch(() => {
				throw new Error("Error in subscribing ", streamType);
			});
	} else 
	{
		let message = `${streamType} is not present in streams`;
		console.error(message);
		throw message;
	}
};

export const getVideoStreams = async (streamType, screens, screen) => {
	var promise = new Promise(async (resolve, reject) => {
		let retries = 0;
		do {
			try{
				retries = retries + 1;
				if(!(streamType in streams)){
					throw new Error("waiting for stream");
				}
				if(streams[streamType].subscribed){
					console.log(streamType, "is already subscribed");
					resolve(streams[streamType]);
					break;
				}
				await subscribe(screens[screen], screens, streamType);
				resolve(streams[streamType]);
				break;
			}
			catch(err){
				if(retries < 3 ){
					await new Promise((r) => setTimeout(r, 2000));
					console.log("retrying to subscribe", streamType);
					continue;
				} 
				else{
					const len = owtResponse.remoteStreams.length;
					let index = -1;
					for (let i = 0; i < len; i++) {
						if (owtResponse.remoteStreams[i].attributes?.streamType === streamType) {
							index = i;
							break;
						}
					}
					if (index >= 0) {
						owtResponse.remoteStreams.splice(index, 1);
					}
					if(streamType in streams){
						delete streams[streamType];
					}
					reject(streamType);
				}
			}
		} while(retries < 3);
	});
	return promise;	
};

export const getAudioStreams = async (streamType, audio_screens) => {
	var promise = new Promise(async (resolve, reject) => {
		let retries = 0;
		do {
			try{
				retries = retries + 1;
				if(!(streamType in streams)){
					throw new Error("wait for sometime");
				}
				if(streams[streamType].subscribed){
					console.log(streamType, "is already subscribed");
					resolve(streams[streamType]);
					break;
				}
				await subscribe(audio_screens[streamType], audio_screens, streamType);
				resolve(streams[streamType]);
				break;
			}
			catch(err){
				if(retries < 3 ){
					await new Promise((r) => setTimeout(r, 2000));
					console.log("retrying to subscribe", streamType);
					continue;
				} 
				else{
					const len = owtResponse.remoteStreams.length;
					let index = -1;
					for (let i = 0; i < len; i++) {
						if (owtResponse.remoteStreams[i].attributes?.streamType === streamType) {
							index = i;
							break;
						}
					}
					if (index >= 0) {
						owtResponse.remoteStreams.splice(index, 1);
					}
					if(streamType in streams){
						delete streams[streamType];
					}
					reject(streamType);
				}
			}
		} while(retries < 3);
	});
	return promise;	
};

export const getSubscribedStreams = () => {
	let subscribedStreams = {};
	Object.keys(streams).forEach((streamType) =>{
		if (streams[streamType].subscribed)
			subscribedStreams[streamType] = streams[streamType];
	});
	return subscribedStreams;
};

export const removeStream = ( streamType ) => {
	console.log("Unsubscribing", streamType);
	if (streamType in streams && streams[streamType].subscribed === true && typeof streams[streamType].subscription.stop === 'function') {
		streams[streamType].subscribed = false;
		streams[streamType].subscription.stop();
		delete streams[streamType];
	}
};
