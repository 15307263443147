import React, { useState, useRef, createRef, useEffect } from 'react';
import PropTypes                                         from 'prop-types';
import times                                             from 'lodash/times';
import TextField                                         from '@material-ui/core/TextField';
import FormLabel                                         from '@material-ui/core/FormLabel';
import Grid                                              from '@material-ui/core/Grid';

import 'styles/components/molecules/pinInput.css';

function PinInput ({ onChange, length, error, inputType }) {

	const inputRef = useRef ([]);
	const [pin, setPin] = useState (Array(length).fill(''));

	/*creating ref of each input field*/

	inputRef.current = Array(length).fill('').map ((ref, index) => inputRef.current[index] = createRef ());

	useEffect (() => {
		onChange (pin.join (""));
	}, [pin]);

	const handleChange = (event) => {
		let {value, name} = event.target;

		let inputPos = Number (name);

		value = value[value.length - 1];

		if (inputType === 'num') {
			value = parseInt(value);
			if (isNaN (value) || value > 9) {
				return;
			}
		}

		if (inputType === 'alpha_num' && value) {
			let reg = /^[a-z0-9]+$/i;
			if (reg.test(value)) {
				value = value.toUpperCase();
			}
			else {
				return;
			}
		}
		/*when user press back button*/
		if (value === undefined) {
			value = '';
			let __pin = [...pin];
			__pin[inputPos] = value;
			setPin (__pin);
			return;
		}
		/*Change focus to next number*/
		if (inputPos + 1 !== length && value !== "") {
			inputRef.current[inputPos+1].current.focus();
		}
		let __pin = [...pin];
		__pin[inputPos] = value;
		setPin (__pin);
	};

	const handleKeyPress = (event) => {
		let {name} = event.target;
		let inputPos = Number (name);
		if (event.keyCode === 46 || event.keyCode === 8) {
			let __pin = [...pin];
			__pin[inputPos] = '';
			setPin (__pin);
		}
		/*handle backspace key*/
		if (event.keyCode === 8 && inputPos-1 !== -1) {
			inputRef.current[inputPos-1].current.focus();
		}
		/*handle left arrow key*/
		if (event.keyCode === 37 && inputPos-1 !== -1) {
			inputRef.current[inputPos-1].current.focus();
		}
		/*handle right arrow key*/
		if (event.keyCode === 39 && inputPos+1 !== length) {
			inputRef.current[inputPos+1].current.focus();
		}
	};

	const handlePaste = (event) => {
		/*Handle copy paste for code*/
		/*
			1.Check if the pin input is empty
			2.Check for regex
		*/

		let pasted = event.clipboardData.getData('Text');
		let reg;
		switch (inputType) {
			case 'alpha_num' :
				reg = /^[a-z0-9]+$/i;  
				break;
			case 'num' :
				reg = /[0-9]/; 
				break;
			default:
				return;
		}

		if (pasted.length !== length || !reg.test (pasted)) {
			return;
		}
		let __val = pasted.split('');
		setPin (__val);
		inputRef.current[length-1].current.focus();
	};

	/*autoComplete = new-password disables the chrome input suggestions*/

	let inputs = [];

	times(length, (index) => {
		inputs.push(
			<TextField 
				variant      = "outlined"
				color        = 'primary'
				value        = { pin[index] }
				onChange     = { handleChange }
				autoFocus    = { index === 0 ? true : false }
				autoComplete = 'new-password'  
				key          = { index }
				name         = { `${index}` }
				inputRef     = { inputRef.current[index] } 
				className    = { `pinInput-box length-${length}` }
				onKeyUp      = { handleKeyPress }
				placeholder  =  "—"
				error        = { error ? true : false }
				onPaste      = { handlePaste }
			/>	
		);
	});

	return (
		<Grid className = 'pinInput mt-20' container wrap = 'nowrap' justify = 'center'>
			<Grid>
				<form noValidate autoComplete = 'off'>
					{inputs}
				</form>
				{error ? <FormLabel className = "pinInput-invalid-pin" error = { error ? true : false }> *{error} </FormLabel> : null}
			</Grid>
		</Grid>
	);
}

PinInput.propTypes = {
	onChange : PropTypes.func.isRequired,
	length   : PropTypes.number.isRequired,
	error    : PropTypes.string,
	inputType: PropTypes.oneOf(['num', 'alpha_num']).isRequired,
}; 

export default PinInput;
