import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';
import { useTranslation }             from 'react-i18next';
import Grid                           from '@material-ui/core/Grid';
import Divider                        from '@material-ui/core/Divider';
import Typography                     from '@material-ui/core/Typography';
import Collapse                       from '@material-ui/core/Collapse';
            
import IconButton                     from 'components/atoms/IconButton';
import Count                          from 'components/atoms/Count';
import EventCard                      from 'components/molecules/EventCard';
import utils                          from 'common/utils';

import 'styles/components/molecules/allEvents.css';

const i18nNamespaces = ['landing'];

function AllEvents ({ ongoing, upcoming, completed, search, sortBy, order }) {

	const { t } = useTranslation (i18nNamespaces);

	const [ongoingOpen, setOngoingOpen]       = useState (true);
	const [upcomingOpen, setUpcomingOpen]     = useState (false);
	const [completedOpen, setCompletedOpen]   = useState (false);
	const [ongoingEvent, setOngoingEvent]     = useState (ongoing);
	const [upcomingEvent, setUpcomingEvent]   = useState (upcoming);
	const [completedEvent, setCompletedEvent] = useState (completed);
	const [sort, setSort]                     = useState ({sortBy, order});

	useEffect (() => {
		if (!search) {
			return;
		}
		if (completedEvent.length) {
			setCompletedOpen (true);
		}
		if (upcomingEvent.length) {
			setUpcomingOpen (true);
		}
		if (ongoingEvent.length) {
			setOngoingOpen (true);
		}
	}, [search]);

	useEffect (() => {
		if (!sort.sortBy) {
			setOngoingEvent (ongoing);
			setUpcomingEvent (upcoming);
			setCompletedEvent (completed);  
			return;
		}
		let sortedUpcoming  = utils.getSortedEvent (upcoming, sort.sortBy, sort.order);
		let sortedCompleted = utils.getSortedEvent (completed, sort.sortBy, sort.order);
		setUpcomingEvent (sortedUpcoming);
		setCompletedEvent (sortedCompleted);
	}, [ongoing, upcoming, completed, sort]);

	useEffect (() => {
		setSort ({sortBy, order});	
	}, [sortBy, order]);

	const handleOngoing = () => {
		setOngoingOpen (!ongoingOpen);
	};

	const handleUpcoming = () => {
		setUpcomingOpen (!upcomingOpen);
	};

	const handleCompleted = () => {
		setCompletedOpen (!completedOpen);
	};

	return (
		<Grid className = 'allEvents'>
			<Grid container>
				<Grid container alignItems = 'center'>
					<IconButton 
						icon    = { ongoingOpen ? 'Up' : 'Down' }
						size    = { 30 }
						color   = 'secondary'
						onClick = { handleOngoing }
					/>
					<Typography variant = 'h2' color = 'textSecondary' className = 'allEvents-header ml-m mr-s'> {t('accordian.ongoing_events')} </Typography>
					<Count number = { ongoingEvent.length } color = 'secondary'/>
				</Grid>
			</Grid>
			<Collapse in = { ongoingOpen } timeout = "auto" className = { `allEvents-list pt-xxl ${ongoingOpen ? 'pb-xxl' : ''}` }>
				{ongoingEvent.length ? 
					ongoingEvent.map ((event, index) => (
						<EventCard event = { event } key = { index }/>
					)) : 
					<Typography variant = 'h5' className = 'allEvents-no-event-text'> {t('body.no_ongoing_event')}</Typography>
				}
			</Collapse>
			<Divider className = 'mb-xxl'/>
			<Grid container>
				<Grid container alignItems = 'center'>
					<IconButton 
						icon    = { upcomingOpen ? 'Up' : 'Down' }
						size    = { 30 }
						color   = 'secondary'
						onClick = { handleUpcoming }
					/>
					<Typography variant = 'h2' color = 'textSecondary' className = 'allEvents-header ml-m mr-s'> {t('accordian.upcoming_events')} </Typography>
					<Count number = { upcomingEvent.length } color = 'secondary'/>
				</Grid>
			</Grid>
			<Collapse in = { upcomingOpen } timeout = "auto" className = { `allEvents-list pt-xxl ${upcomingOpen ? 'pb-xxl' : ''}` }>
				{upcomingEvent.length ? 
					upcomingEvent.map ((event, index) => (
						<EventCard event = { event } key = { index }/>
					)) : 
					<Typography variant = 'h5' className = 'allEvents-no-event-text'> {t('body.no_upcoming_event')}</Typography>
				}
			</Collapse>
			<Divider className = 'mb-xxl'/>
			<Grid container>
				<Grid container alignItems = 'center'>
					<IconButton 
						icon    = { completedOpen ? 'Up' : 'Down' }
						size    = { 30 }
						color   = 'secondary'
						onClick = { handleCompleted }
					/>
					<Typography variant = 'h2' color = 'textSecondary' className = 'allEvents-header ml-m mr-s'> {t('accordian.completed_events')} </Typography>
					<Count number = { completedEvent.length } color = 'secondary'/>
				</Grid>
			</Grid>
			<Collapse in = { completedOpen } timeout = "auto" className = { `allEvents-list pt-xxl ${completedOpen ? 'pb-xxl' : ''}` }>
				{completedEvent.length ? 
					completedEvent.map ((event, index) => (
						<EventCard event = { event } key = { index }/>
					)) :
					<Typography variant = 'h5' className = 'allEvents-no-event-text'> {t('body.no_completed_event')}</Typography>
				}
			</Collapse>
		</Grid>
	);
}

AllEvents.propTypes = {
	ongoing   : PropTypes.array.isRequired,
	upcoming  : PropTypes.array.isRequired,
	completed : PropTypes.array.isRequired,
};

export default AllEvents;
