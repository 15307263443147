import React     from 'react';
import PropTypes from 'prop-types';

import 'styles/components/atoms/count.css';

function Count ({variant, number, className, color}) {

	return (
		<span className = { `count ${variant} ${className} ${color}` }> {number} </span>
	);
}

Count.propTypes = {
	variant : PropTypes.oneOf(['circular', 'elliptical']).isRequired,
	number  : PropTypes.number.isRequired,
	color   : PropTypes.oneOf(['primary', 'secondary']),
};

Count.defaultProps = {
	variant : 'circular',
	color   : 'primary',
};

export default Count;
