import React               from 'react';
import { useTranslation }  from 'react-i18next';
import PropTypes           from 'prop-types';
import ClickAwayListener   from '@material-ui/core/ClickAwayListener';
import Grow                from '@material-ui/core/Grow';
import Paper               from '@material-ui/core/Paper';
import Popper              from '@material-ui/core/Popper';
import MenuItem            from '@material-ui/core/MenuItem';
import MenuList            from '@material-ui/core/MenuList';

import 'styles/components/atoms/dropdown.css';

function Dropdown ({ anchorRef, options, onClick, handleClose, open, direction, placement}) {

	const { i18n } = useTranslation ();
	const langDir = i18n.dir ();

	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			handleClose ();
		}
	};

	return (
		<Popper                                                                                                                                                                                              
			open          = { open }
			anchorEl      = { anchorRef.current }
			role          = { undefined }
			transition    = { true }
			placement     = { placement ? placement :  direction === 'right' ? langDir === 'ltr' ? 'right-end' : 'left-end' : 'bottom-end' }
			className     = 'dropdown-popper'
			modifiers     = {{
				offset : {
					enabled : true,
					offset  : direction === 'right' ? '-16, 11' : '0, 10'
				}
			}}>
			{({ TransitionProps }) => (
				<Grow
					{ ...TransitionProps }
					style = {{ transformOrigin: 'left bottom'}}
				>
					<Paper className = 'dropdown-container'>
						<ClickAwayListener onClickAway = { handleClose }>
							<MenuList onKeyDown = { handleListKeyDown }>
								{options.map (item => (
									<MenuItem onClick = { () => onClick(item.id) } key = { item.id }> {item.label} </MenuItem>
								))}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	);
}

Dropdown.propTypes = {
	anchorRef   : PropTypes.any.isRequired,
	options     : PropTypes.arrayOf(PropTypes.shape ({label   : PropTypes.string.isRequired, id : PropTypes.any.isRequired})).isRequired,
	onClick     : PropTypes.func,
	handleClose : PropTypes.func.isRequired,
	open        : PropTypes.bool.isRequired,
	direction   : PropTypes.oneOf (['left', 'right', 'top', 'bottom'])
};

export default Dropdown;
