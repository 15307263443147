import React, { useState, useRef } from 'react';
import PropTypes                   from 'prop-types';
import { useHistory }              from 'react-router-dom';
import moment                      from 'moment';
import { useTranslation }          from 'react-i18next';
import { useTheme }                from '@material-ui/core/styles';
import Grid                        from '@material-ui/core/Grid';
import Button                      from '@material-ui/core/Button';
import Avatar                      from '@material-ui/core/Avatar';
import AvatarGroup                 from '@material-ui/lab/AvatarGroup';
import Typography                  from '@material-ui/core/Typography';

import path                        from 'navigations/path';
import Dropdown                    from 'components/atoms/Dropdown';
import IconButton                  from 'components/atoms/IconButton';
import PinModal                    from 'components/molecules/PinModal';
import LMUserProfile               from 'assets/icons/light/userProfile.svg';
import DMUserProfile               from 'assets/icons/dark/userProfile.svg';

import 'styles/components/molecules/eventCard.css';

const i18nNamespaces = ['landing'];

function EventCard ({ event }) {

	const redirect = useHistory ();
	const { t }    = useTranslation (i18nNamespaces);
	const theme    = useTheme ().palette.type;

	const anchorRef = useRef (null);
	const [presenterListOpen, setPresenterListOpen] = useState (false);
	const [pinModal, setPinModal]                   = useState (false);

	const getStartTime = () => {
		let timeString = '';
		let currentDate = new Date ();
		let scheduledAt = new Date (event.scheduledDate);
		let timeDiff = scheduledAt.getTime () - currentDate.getTime ();

		switch (event.state) {
			case 'created' :
				timeString =  t('tag.starting', {timeString : moment (event.scheduledDate).fromNow ()});
				break;
			case 'stopped' :
				timeString =  t('tag.ended', {timeString : moment (event.endedAt).fromNow ()});
				break;
			case 'started' :
				timeString =  t('tag.started', {timeString : moment (event.startedAt).fromNow ()});
				break;
			default : 
				return;
		}

		if (event.state === 'created' && timeDiff < 0) {
			timeString =  t('tag.not_started');
		}

		return timeString;
	};

	const getStatus = () => {
		switch (event.state) {
			case 'created' : {
				const currentDate = new Date ();
				const scheduledAt = new Date (event.scheduledDate);
				const timeDiff = scheduledAt.getTime () - currentDate.getTime ();
				if (timeDiff < 0) {
					return 'NOT_STARTED'; // #5b5b5b
				} else {
					return timeDiff < 60 * 60 * 1000
						? 'UPCOMING_SOON' // #fe4d4f
						: 'UPCOMING'; // #ffa238
				}
			}
			case 'started' : {
				return 'ONGOING'; // #5ca700
			}
			case 'stopped' : {
				return 'COMPLETED'; // #5b5b5b
			}
		}
	};

	const onEventClick = () => {
		if (event.state !== 'started' && event.state !== 'stopped') {
			return;
		}
		if (event.state === 'stopped') {
			redirect.push (path.pastLanding, {eventId : event.id});
			return;
		}
		if (!event.attendeePin.length) {
			redirect.push (path.connect);
			return;
		}
		setPinModal (true);
	};

	const handlePresenterList = () => {
		setPresenterListOpen (!presenterListOpen);
	};

	const handlePinModal = () => {
		setPinModal (!pinModal);
	};

	const showPresenters = () => {
		return (
			<AvatarGroup max = { 3 }>
				{event.presenterDetails.map ((presenter, i) => (
					<Avatar src = { presenter.url ? presenter.url : theme === 'light' ? LMUserProfile : DMUserProfile } key = { i } className = 'eventCard-presenter-avatar'/>
				))}
			</AvatarGroup>
		);
	};

	const presenterList = () => {
		let presenters = event.presenterDetails.map ((presenter,i) => {
			return {
				label : presenter.name,
				id    : i,
			};
		});
		return (
			<Dropdown 
				options     = { presenters }
				anchorRef   = { anchorRef }
				open        = { presenterListOpen }
				handleClose = { handlePresenterList }
				placement   = 'bottom-start'
			/>
		);
	};

	const renderButton = () => {
		if (event.state === 'created') {
			return;
		}
		if (event.state === 'started') {
			return (
				<Button color = 'primary' variant = 'contained' size = 'small' onClick = { onEventClick } className = 'eventCard-join-btn'> {t('button:action.join')} </Button> 
			);
		}
		return (
			<Button color = 'primary' variant = 'outlined' size = 'small' onClick = { onEventClick } className = 'eventCard-view-btn'> {t('button:action.view')} </Button>
		);
	};

	return (
		<Grid container alignItems = 'center' className = 'eventCard'> 
			<Grid item xs = { 12 } sm = { 12 } md = { 3 }>
				<Typography variant = 'subtitle1'> {event.name} </Typography>
				<Typography variant = 'body2' color = 'secondary' className = 'eventCard-desc'> {event.description} </Typography>
			</Grid>
			<Grid container item xs = { 12 } sm = { 12 } md = { 3 } alignItems = 'flex-start' wrap = 'nowrap'>
				{showPresenters ()}
				{event.presenterDetails.length === 1 ? <Typography variant = 'h2' className = 'ml-m mr-m'> {event.presenterDetails[0].name} </Typography> :
					<Typography ref = { anchorRef } variant = 'h2' className = 'ml-m mr-m'> {t('body.presenter_count', {counter : event.presenterDetails.length})} 
						<IconButton 
							icon      = 'Down'
							size      = { 30 }                                              
							onClick   = { handlePresenterList }
							className = 'ml-xs mr-xs'
						/>
					</Typography>
				}
			</Grid>
			<Grid item xs = { 12 } sm = { 12 } md = { 3 }>
				<Typography variant = 'h2'> 
					{moment (event.scheduledDate).format ('LL')}|{moment (event.scheduledDate).format ('h:mm a')}
				</Typography>
			</Grid>
			<Grid item xs = { 12 } sm = { 12 } md = { 2 }>
				<Typography variant = 'h2' className = { `eventCard-timeago ${getStatus().toLowerCase()}` }> {getStartTime ()} </Typography>
			</Grid>
			<Grid item xs = { 12 } sm = { 12 } md = { 1 }>
				{renderButton ()}	
			</Grid>
			{presenterList()}
			{pinModal ? 
				<PinModal 
					open    = { pinModal }
					onClose = { handlePinModal }
				/> 
				: null}
		</Grid>
	);
}

EventCard.propTypes = {
	event : PropTypes.shape ({
		name          : PropTypes.string.isRequired,
		description   : PropTypes.string.isRequired,
		state         : PropTypes.string.isRequired,
		startedAt     : PropTypes.string,
		scheduledDate : PropTypes.string.isRequired,
		endedAt       : PropTypes.string,
	}).isRequired
};

export default EventCard;
