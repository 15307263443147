import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';
import { useSelector, useDispatch }   from 'react-redux';
import { useTranslation }             from 'react-i18next';
import { toast }                      from 'react-hot-toast';
import Grid                           from '@material-ui/core/Grid';
import Divider                        from '@material-ui/core/Divider';
import Typography                     from '@material-ui/core/Typography';

import Count                          from 'components/atoms/Count';
import Alert                          from 'components/atoms/Alert';
import CommentCard                    from 'components/molecules/CommentCard';
import CommentTextBox                 from 'components/molecules/CommentTextBox';
import EditCommentModal               from 'components/molecules/EditCommentModal';
import { sendComment }                from 'redux/slices/comment';
import { updateUserPendingComment }   from 'redux/slices/comment';
import { clearBadge, addNewBadge }    from 'redux/slices/common';
import { logContainer }               from 'libs/logger';
import utils                          from 'common/utils';
import useRequest                     from 'common/request';

import 'styles/pages/comments.css';

const log = logContainer ('pages/Comments');

function Comments ({isPast, pastComments}) {

	const dispatch      = useDispatch ();
	const { t }         = useTranslation ();
	const request       = useRequest ();
	const userStore     = useSelector (state => state.user);
	const loginStore    = useSelector (state => state.login);
	const commentStore  = useSelector (state => state.comment);
	const settingsStore = useSelector (state => state.settings);

	const comments        = commentStore.approved;
	const commentSettings = settingsStore.comment;

	const [sendAlert, setSendAlert]     = useState (false);
	const [editModal, setEditModal]     = useState (false);
	const [editComment, setEditComment] = useState ({uuid : '', comment : ''});

	const getAvatar = (vcId) => {
		/* const getAvatar: (vcId: any) => any  object*/
		let avatar =  userStore.avatar;
		let imgInfo = avatar[vcId];
		if (imgInfo) {
			return utils.getImgSrc (imgInfo);
		}
		let currentUser = userStore.currentUser;
		if (currentUser.vcId === vcId) {
			return loginStore.image;
		}
	};

	useEffect (() => {
		dispatch (clearBadge ('comment'));
	}, [commentStore]);

	const onSendComment = async (comment) => {
		let data = {
			comment,
			user : loginStore.name,
		};
		let result;
		try {
			result =  await request.sendComment (data);
			log.info ({comment : result}, 'comment send ok');
		}
		catch (err) {
			log.error ({err}, 'error sending comment');
			return;
		}
		if (!commentSettings.autoApproveComments) {
			handleSendAlert ();
			dispatch (addNewBadge ('sent'));
		}
		dispatch (sendComment (result));
		setEditComment ({comment : result.comment, uuid : result.uuid});
	};

	const onUpdateComment = async(uuid, comment) => {
		let __comment = comments.find (comment => comment.uuid === uuid);
		if (__comment) {
			toast.error(t('alert:desc.comment_edit_approved'));
			onCancelEditModal();
			return;
		}
		let result;
		let data = {
			comment,
			uuid,
		};
		try {
			result = await request.editComment (data);
			log.info ({updatedComment : result}, 'comment updated ok');
		}
		catch (err) {
			log.error ({err}, 'error updating comment');
			onCancelEditModal();
			return;
		}
		dispatch (updateUserPendingComment (result));
		onCancelEditModal ();
	};

	const handleSendAlert = () => {
		setSendAlert (!sendAlert);
	};

	const onCancelEditModal = () => {
		setEditComment ({comment : '', uuid : ''});
		setEditModal(false);
	};

	const handleEditModal = () => {
		if (!editComment.comment || !editComment.uuid) {
			return;
		}
		handleSendAlert();
		setEditModal(!editModal);
	};

	const showEditModal = editModal && 
		<EditCommentModal 
			onCancel      = { onCancelEditModal }
			value         = { editComment.comment }
			uuid          = { editComment.uuid }
			updateComment = { onUpdateComment }
		/>;

	if (isPast) {
		return (
			<Grid className = 'comments'>
				<Grid className = 'p-xxl' container>
					<Typography variant = 'h4'> {t('in_event:body.live_comments')} </Typography>
					<Count number = { pastComments.length } variant = 'elliptical' color = 'secondary' className = 'ml-m mr-m'/>
				</Grid>
				<Divider />
				<Grid className = 'comments-container isPast'>
					{pastComments.map (comment => (
						<CommentCard
							key     = { comment.uuid }
							name    = { comment.user }
							comment = { comment.comment }
							ts      = { comment.ts }
							uuid    = { comment.uuid }
							avatar  = { getAvatar (comment.vcId) }
						/>
					))}
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid className = 'comments'>
			<Grid className = 'p-xxl' container>
				<Typography variant = 'h4'> {t('in_event:body.live_comments')} </Typography>
				<Count number = { comments.length } variant = 'elliptical' color = 'secondary' className = 'ml-m mr-m'/>
			</Grid>
			<Divider />
			<Grid className = 'comments-container'>
				{commentSettings.showOn !== 'DISPLAY' && comments.map (comment => (
					<CommentCard
						key     = { comment.uuid }
						name    = { comment.user }
						comment = { comment.comment }
						ts      = { comment.ts }
						uuid    = { comment.uuid }
						avatar  = { getAvatar (comment.vcId) }
					/>
				))}
			</Grid>
			<Grid className = 'comments-commentBox-container'>
				<Divider className = 'comments-bottom-divider'/>
				<Grid className = 'p-xl'>
					<CommentTextBox
						sendComment = { onSendComment }
					/>
				</Grid>
			</Grid>
			<Alert 
				open       = { sendAlert }
				title      = { t('modal:title.comment_pending') }
				message    = { t('modal:body.comment_pending') }
				confirmBtn = { t('button:action.back_to_event') }
				dismissBtn = { t('button:action.edit_comment') }
				textAlign  = 'center'
				btnAlign   = 'center'
				sideAlert  = { true }
				onDismiss  = { handleEditModal }
				onConfirm  = { handleSendAlert }
			/>
			{showEditModal}	
		</Grid>
	);
}

Comments.propTypes = {
	isPast       : PropTypes.bool,
	pastComments : PropTypes.array,
};

export default Comments;
