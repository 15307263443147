import React, { createContext, useState}                            from 'react';
import { useTranslation }                                           from 'react-i18next';
import { create }                                                   from 'jss';
import rtl                                                          from 'jss-rtl';
import { ThemeProvider, createMuiTheme, StylesProvider, jssPreset } from '@material-ui/core/styles';

import localStore                                                   from 'libs/localStore';
import appTheme                                                     from './appTheme';

export const ThemeContext = createContext ();

const jss = create ({
	...jssPreset(),
	insertionPoint : document.getElementById('jss-insertion-point'),
	plugins        : [...jssPreset().plugins, rtl()],
});

function AppThemeProvider ({ children }) {

	const { i18n } = useTranslation ();

	const prefersDarkMode = window.matchMedia ('(prefers-color-scheme: dark)').matches;
	const mode = localStore.get ('theme');

	const currentTheme = mode ? mode : prefersDarkMode ? 'dark' : 'light';

	const [ themeName, setThemeName ] = useState (currentTheme);

	document.body.classList.add (themeName);

	const theme = createMuiTheme ({
		direction : i18n.dir (),
		...appTheme (themeName)
	});

	window.matchMedia('(prefers-color-scheme: dark)')
		.addEventListener('change', event => {
			if (mode) {
				/*
				 *Don't change if there is already in localstorage
				 * */
				return;
			}
			if (event.matches) {
				setThemeName ('dark');
				addThemeClass ('dark');
			} 
			else {
				setThemeName ('light');
				addThemeClass ('light');
			}
		});

	const addThemeClass = (theme) => {
		switch (theme) {
			case 'dark' :
				document.body.classList.add ('dark');
				document.body.classList.remove ('light');
				break;
			case 'light' :
				document.body.classList.add ('light');
				document.body.classList.remove ('dark');
				break;
			default : 
				return;
		}
	};

	const setTheme = (theme) => {
		addThemeClass (theme);
		localStore.set ('theme', theme);
		setThemeName (theme);
	};

	const contextValue = {
		currentTheme : themeName,
		setTheme     : setTheme,
	};

	return (
		<ThemeContext.Provider value = { contextValue }>
			<ThemeProvider theme = { theme }> 
				<StylesProvider jss = { jss }>
					{children} 
				</StylesProvider>
			</ThemeProvider>
		</ThemeContext.Provider>
	);
}

export default AppThemeProvider;
