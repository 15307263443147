const storage = localStorage;

const store = {};

store.set = (key, value) => {
	const jsonValue = JSON.stringify (value);
	return storage.setItem (key, jsonValue);
};

store.get = (key) => {
	const jsonValue = storage.getItem (key);
	return jsonValue !== null ? JSON.parse (jsonValue) : null;
};

store.getAll = () => {
	return storage;
};

store.getMultiple = (keys) => {
	let values = {};

	for (var key of keys) {
		let json  = storage.getItem (key);
		let value = json !== null ? JSON.parse (json) : null;
		
		values [key] = value;
	}

	return values;
};

store.remove = (key) => {
	return storage.removeItem (key);
};

store.removeMultiple = (keys) => {
	for (var key of keys)
		storage.removeItem (key);

	return;
};

store.clearAll = () => {
	return storage.clear();
};

export default store;
