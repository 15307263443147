const UserMedia = {};

let stream = null;

UserMedia.getDevices = async () => {
	let devices = await navigator.mediaDevices.enumerateDevices ();

	let audioInputDevices  = [];
	let audioOutputDevices = [];
	let videoInputDevices  = [];

	devices.forEach ((device) => {
		if (device.kind === 'audioinput') {
			audioInputDevices.push (device);
		}
		if (device.kind === 'audiooutput') {
			audioOutputDevices.push (device);
		}
		if (device.kind === 'videoinput') {
			videoInputDevices.push (device);	
		}
	});

	return {
		audioInputDevices,
		audioOutputDevices,
		videoInputDevices,
	};
},

UserMedia.checkPermissions = async () => {
	let devices =  await UserMedia.getDevices ();

	if (!devices) {
		throw 'Error getting devices list';
	}

	/*
	 *Check if there are audioinput devices and videoinput devices with permissions
	 * */

	let audioInputDevicesWithPerms = devices.audioInputDevices.filter (device => device.deviceId);
	let videoInputDevicesWithPerms = devices.videoInputDevices.filter (device => device.deviceId);

	return {
		audio : {
			permission : devices.audioInputDevices.length && audioInputDevicesWithPerms.length ?  true : false,
			devices    : audioInputDevicesWithPerms,
		},
		video : {
			permission : devices.videoInputDevices.length && videoInputDevicesWithPerms.length ? true : false,
			devices    : videoInputDevicesWithPerms,
		}
	};
};

UserMedia.getMediaConstraints = async () => {
	let mediaConstraints =  await navigator.mediaDevices.getSupportedConstraints ();
	return mediaConstraints;
};

UserMedia.askPermissions = async (constraints) => {
	let perms  = await UserMedia.getUserMedia (constraints);
	return perms;
};

UserMedia.getUserMedia = async (constraints) => {
	try {
		stream = await navigator.mediaDevices.getUserMedia (constraints);
		return stream;
	}
	catch (err) {
		let errorMsg   = "";
		let errorTitle = "";

		switch (err.name) {
			case 'NotAllowedError' :
				errorMsg = "It requires access to your camera and microphone. Click the camera blocked icon in your browser's address bar.";
				errorTitle = "Camera or Microphones are blocked";
				break;

			case 'NotFoundError' :
				errorTitle = "Microphone or camera not found.";
				errorMsg    = "Microphone or camera not found on your device";
				break;

			/*Handle more error name accordingly*/
			default :
				errorTitle = "Something went wrong";
				errorMsg = "Something went wrong with the microphone or camera devices";
		}

		throw {errorMsg, errorTitle};
	}
};

UserMedia.clearUserMedia = () => {
	if (stream) {
		let tracks = stream.getTracks();
		tracks.forEach (track => {
			track.stop();
		});
		stream = null;
	}
};

export default UserMedia;
