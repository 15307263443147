import colors from './colors';

const dark = {                                                                                                                                                                                                   
	primary : {
		main : colors.dodgerBlue,
	},
	secondary : {
		main : colors.blueBlue,
	},
	error : {
		main : colors.darkRed,
	},
	warning : {
		main : colors.mango,
	},
	info : {
		main : colors.blueGrey
	},
	success : {
		main : colors.kermitGreen,
	},
	background : {
		default : colors.black,
	},
};

const light = {
	primary : {
		main : colors.blueBlue,
	},
	secondary : {
		main : colors.dodgerBlue,
	},
	error : {
		main : colors.lightRed,
	},
	warning : {
		main : colors.mango,
	},
	info : {
		main : colors.blueGrey,
	},
	success : {
		main : colors.kermitGreen,
	},
	background : {
		default : colors.white,
	},
};

const pallete = {
	light,
	dark,
};

export default pallete;
