import React                          from 'react';
import {Route, Switch, MemoryRouter } from 'react-router-dom';

import path                           from 'navigations/path';
import PrivateRoute                   from 'navigations/PrivateRoute'; 
import Login                          from 'pages/Login';
import Landing                        from 'pages/Landing';
import Connect                        from 'pages/Connect';
import EventLanding                   from 'pages/EventLanding';
import Polls                          from 'pages/Polls';
import SentRequest                    from 'pages/SentRequest';
import LiveCall                       from 'pages/LiveCall';
import PastLanding                    from 'pages/PastLanding';
import Comments                       from 'pages/Comments';
import OnlineLanding                  from 'pages/OnlineLanding';

function Navigations () {
	
	return (
		<MemoryRouter>
			<Switch>
				<Route 
					exact     = { true } 
					path      = { path.login } 
					component = { Login }
				/>
				<PrivateRoute 
					exact     = { true }
					path      = { path.landing }
					component = { Landing }
				/>
				<PrivateRoute 
					exact     = { true }
					path      = { path.connect }
					component = { Connect }
				/>
				<PrivateRoute 
					exact     = { true }
					path      = { path.eventLanding }
					component = { EventLanding }
				/>
				<PrivateRoute 
					exact     = { true }
					path      = { path.poll }
					component = { Polls }
				/>
				<PrivateRoute 
					exact     = { true }
					path      = { path.sentRequest }
					component = { SentRequest }
				/>
				<PrivateRoute 
					exact     = { true }
					path      = { path.liveCall }
					component = { LiveCall }
				/>
				<PrivateRoute 
					exact     = { true }
					path      = { path.pastLanding }
					component = { PastLanding }
				/>
				<PrivateRoute 
					exact     = { true }
					path      = { path.comment }
					component = { Comments }
				/>
				<PrivateRoute 
					exact     = { true }
					path      = { path.onlineLanding }
					component = { OnlineLanding }
				/>
			</Switch>
		</MemoryRouter>
	);
}

export default Navigations;
