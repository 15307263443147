import React, { useEffect, useState }                         from 'react';
import { useSelector, useDispatch }                           from 'react-redux';
import { useTranslation }                                     from 'react-i18next';
import { toast }                                              from 'react-hot-toast';
import Grid                                                   from '@material-ui/core/Grid';
import Divider                                                from '@material-ui/core/Divider';
import Typography                                             from '@material-ui/core/Typography';
            
import Count                                                  from 'components/atoms/Count';
import Alert                                                  from 'components/atoms/Alert';
import RequestCard                                            from 'components/molecules/RequestCard';
import SpeakRequestModal                                      from 'components/molecules/SpeakRequestModal';
import EditCommentModal                                       from 'components/molecules/EditCommentModal';
import useRequest                                             from 'common/request';
import userMedia                                              from 'common/userMedia';
import WebrtcController                                       from 'libs/webrtc/webrtcController';
import { logContainer }                                       from 'libs/logger';
import { isMicPendingSelector, clearRequest }                 from 'redux/slices/mic';
import { updateUserPendingComment, removeUserPendingComment } from 'redux/slices/comment';
import { clearBadge }                                         from 'redux/slices/common';

import 'styles/pages/sentRequest.css';

const log = logContainer ('pages/SentRequest');

function SentRequest ({ cancelMic, onMicCanceled }) {

	const dispatch = useDispatch ();
	const { t }    = useTranslation ();
	const request  = useRequest ();

	const micStore      = useSelector (state => state.mic);
	const commentStore  = useSelector (state => state.comment);
	const userStore     = useSelector (state => state.user);

	const vcId = userStore.currentUser.vcId;

	const isMicReqPending = isMicPendingSelector(micStore);

	const [count, setCount]               = useState (0);
	const [cancelAlert, setCancelAlert]   = useState ({reqType : '', uuid : ''});
	const [requestModal, setRequestModal] = useState ({open : false, title : '', desc : '', approved : false});
	const [editModal, setEditModal]       = useState (false);
	const [editComment, setEditComment]   = useState ({uuid : '', comment : ''});

	useEffect (() => {
		if (!cancelMic) {
			return;
		}
		handleCancelReq ('mic');
	}, [cancelMic]);

	useEffect (() => {
		let _count = 0;
		_count = commentStore.userPending.length ? _count += commentStore.userPending.length : _count;
		isMicReqPending ? _count += 1 : _count;
		setCount (_count);
		dispatch (clearBadge ('sent'));
	}, [isMicReqPending, commentStore.userPending]);

	const onCancelMicRequest = async () => {
		let payload = { target: vcId };
		try {
			await request.cancelSpeakRequest (payload);
			userMedia.clearUserMedia ();
			WebrtcController.closeChannel ();
			dispatch (clearRequest());
		}
		catch (err) {
			log.error ('Failed to cancel mic request', err);
			return;
		}
		handleCancelReq ();
	};

	const onCancelCommentReq = async() => {
		if (!cancelAlert.reqType === 'comment' || !cancelAlert.uuid) {
			handleCancelReq();
			return;
		}

		let uuid = cancelAlert.uuid;
		let _isApproved = commentStore.approved.find (c => c.uuid === uuid);
		if (_isApproved) {
			toast.error(t('alert:desc.comment_cancel_approved'));
			handleCancelReq();
			return;
		}

		let result;
		let data = {
			uuids : [uuid]
		};
		try {
			result = await request.cancelComment (data);
			log.info ({cancelComment : result}, 'comment canceled ok');
		}
		catch (err) {
			log.error ({err}, 'error updating comment');
			toast.error(t('alert:desc.comment_cancel_approved'));
			handleCancelReq();
			return;
		}
		dispatch (removeUserPendingComment (result));
		handleCancelReq ();
	};

	const handleCancelReq = (reqType, uuid) => {
		if (cancelMic) {
			/*This func is used to clear the cancelMic state in the parent component*/
			onMicCanceled();
		}
		if (reqType !== 'comment' && reqType !== 'mic') {
			setCancelAlert ({reqType :'', uuid : ''});
			return;
		}
		handleReqModal ();
		setCancelAlert ({reqType, uuid});
	};

	const onCancelEditModal = () => {
		setEditComment ({comment : '', uuid : ''});
		setEditModal(false);
	};

	const handleEditModal = (uuid) => {
		let comment = commentStore.userPending.find (c => c.uuid === uuid);
		if (!comment) {
			return;
		}
		setEditComment ({comment: comment.comment, uuid : uuid});
		setEditModal(!editModal);
	};

	const onUpdateComment = async(uuid, comment) => {
		let _isApproved = commentStore.approved.find (c => c.uuid === uuid);
		if (_isApproved) {
			toast.error(t('alert:desc.comment_edit_approved'));
			onCancelEditModal();
			return;
		}
		let result;
		let data = {
			comment,
			uuid,
		};
		try {
			result = await request.editComment (data);
			log.info ({updatedComment : result}, 'comment updated ok');
		}
		catch (err) {
			log.error ({err}, 'error updating comment');
			toast.error(t('alert:desc.comment_edit_approved'));
			onCancelEditModal();
			return;
		}
		dispatch (updateUserPendingComment (result));
		onCancelEditModal ();
	};

	const getMicStatus = () => {
		let state = micStore.state;
		let pair = {};
		switch (state) {
			case 'call-approved' :
				pair['state'] = t('tag:status.accepted');
				pair['color'] = 'accepted';
				break;

			case 'call-in-queue' :
				pair['state'] = t('tag:status.pending');
				pair['color'] = 'pending';
				break;
			default :
				return;
		}
		return pair;
	};

	const handleReqModal = (isApproved) => {
		if (isApproved === undefined) {
			setRequestModal ({open : false, approve : false, title : '', desc : ''});
			return;
		}
		if (isApproved === false) {
			setRequestModal ({open : true, approve : false, title : t('go_live:body.request_in_progress'), desc : t('go_live:body.request_in_progress_subtext')});
			return;
		}
		setRequestModal ({open : true, approved : true, title : t('go_live:body.request_approved'), desc : t('go_live:body.request_approved_subtext')});
	};

	const showEditModal = editModal &&
		<EditCommentModal 
			onCancel      = { onCancelEditModal }
			value         = { editComment.comment }
			uuid          = { editComment.uuid }
			updateComment = { onUpdateComment }
		/>;

	const speakRequestAlert = requestModal.open && 
		<SpeakRequestModal
			open            = { requestModal.open }
			title           = { requestModal.title }
			desc            = { requestModal.desc }
			approved        = { requestModal.approved }
			onClose         = { handleReqModal }
			onCancelRequest = { () => handleCancelReq ('mic', '') }
		/>;

	return (
		<Grid className = 'sentRequest'>
			 <Grid className = 'p-xxl' container>
				<Typography variant = 'h4'> {t('in_event:body.sent_request')} </Typography>
				<Count number = { count } color = 'secondary' className = 'ml-m mr-m'/>
			</Grid>
			 <Divider />
			<Grid className = 'sentRequest-container'>
				{isMicReqPending && 
						<RequestCard 
							type           = "MIC"
							title          = { t('in_event:go_live.go_live') }
							status         = { getMicStatus().state }
							statusColor    = { getMicStatus().color }
							content        = "Go live with audio/video"
							cancelRequest  = { handleCancelReq }
							viewRequest    = { () => handleReqModal (micStore.state === 'call-in-queue' ? false : true) }
							time           = { micStore.permittedTs }
							position       = { micStore.position }
						/>
				}
				{commentStore.userPending.map (comment => (
					<RequestCard 
						type           = "COMMENT"
						title          = { t('in_event:go_live.comment') }
						status         = "Pending"
						statusColor    = 'pending'
						content        = { comment.comment }
						time           = { comment.ts }
						key            = { comment.uuid }
						uuid           = { comment.uuid }
						cancelRequest  = { () => handleCancelReq ('comment', comment.uuid) }
						editComment    = { handleEditModal }
					/>
				))}
			</Grid>
			<Alert 
				open       = { cancelAlert.reqType ? true : false }
				title      = { cancelAlert.reqType === 'comment' ? t('modal:title.cancel_comment_req') : 'Cancel Go live request' }
				message    = { t('modal:body.req_cancel') }
				dismissBtn = { t('button:action.go_back') }
				confirmBtn = { t('button:action.cancel_request') }
				sideAlert  = { true }
				onDismiss  = { handleCancelReq }
				onConfirm  = { cancelAlert.reqType === 'comment' ? onCancelCommentReq : onCancelMicRequest }
			/>
			{speakRequestAlert}
			{showEditModal}	
		</Grid>
	);
}

export default SentRequest;