import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	live      : {},
	upcoming  : [],
	completed : [],
	past      : {},
};

const changePresenterDetailsKeys = (presenters) => {
	return presenters?.map (presenterObj => ({
		name : presenterObj.name,
		url  : presenterObj.url,
	}));
};

const changeEventKeys = (events) => {
	return events?.map (eventObj => ({
		id               : eventObj._id,
		name             : eventObj.name,
		presenter        : eventObj.presenter,
		description      : eventObj.description,
		state            : eventObj.state,
		atomSerial       : eventObj.atomSelf,
		atomSelf         : eventObj.atomSelf,
		code             : eventObj.code,
		startedAt        : eventObj.startedAt,
		createdAt        : eventObj.createdAt,
		scheduledDate    : eventObj.scheduledDate,
		endedAt          : eventObj.endedAt,
		isOnline         : eventObj.isOnline,
		isSlaveAtom      : eventObj.isSlaveAtom,
		atomMaster       : eventObj.atomMaster,
		stellaConfig     : eventObj.stellaConfig,
		onlineId         : eventObj.id, //in case of online event we use this event id 
		presenterDetails : changePresenterDetailsKeys (eventObj.presenterDetails),
		attendeePin      : {
			length : eventObj.attendeePin?.length,
		},
	}));
}; 

const slice = createSlice ({
	name : 'event',
	initialState,
	reducers : {
		addCurrentEvent (state, action) {
			if (!action.payload) {
				return;
			}
			let _event = changeEventKeys([action.payload]);
			state.live = _event[0];
		},

		addUpcomingEvent (state, action) {
			let _events = action.payload;
			if (!Array.isArray(_events)) {
				return;
			}
			_events =  changeEventKeys (_events);
			state.upcoming = _events;
		},

		addCompletedEvent (state, action) {
			let _events = action.payload;
			if (!Array.isArray(_events)) {
				return;
			}
			_events =  changeEventKeys (_events);
			state.completed = _events;
		},

		addPastEvent (state, action) {
			if (!action.payload) {
				return;
			}
			state.past = action.payload;
		},

		clearEventStore () {
			return initialState;
		},
	},
});

export const { 
	addCurrentEvent,
	addUpcomingEvent,
	addCompletedEvent,
	addPastEvent,
	clearEventStore,
} = slice.actions;

export default slice.reducer;
