import React, { useEffect, useState }   from 'react';
import { useTranslation }               from 'react-i18next';
import Grid                             from '@material-ui/core/Grid';
import Popper                           from '@material-ui/core/Popper';
import Typography                       from '@material-ui/core/Typography';
import Grow                             from '@material-ui/core/Grow';
import MobileStepper                    from '@material-ui/core/MobileStepper';
                      
import path                             from 'navigations/path';
import CallOut                          from 'components/atoms/CallOut';
import Button                           from 'components/atoms/Button';

import 'styles/components/molecules/walkthrough.css';

const i18nNamespaces = ['onboarding'];

function Walkthrough ({ onboardingStep, onSkip, onNext }) {

	const { t, i18n } = useTranslation(i18nNamespaces);
	const langDir     = i18n.dir();

	const [step, setStep] = useState (0);

	useEffect (() => {
		setStep (onboardingStep-1);
	}, [onboardingStep]);

	const walkthroughSteps = [
		{
			parentId  : '',
			elementId : 'eventLanding_ask_btn',
			title     : t('title.mic_req'), 
			desc      : t('body.mic_req'),
			arrowLeft : '50%',
		},
		{
			parentId  : 'liveCall_liveControl',
			elementId : 'liveCall_mute',
			title     : t('title.mute'), 
			desc      : t('body.mute'),
			arrowLeft : '23%',
		},
		{
			parentId  : 'liveCall_liveControl',
			elementId : 'liveCall_video',
			title     : t('title.camera'), 
			desc      : t('body.camera'),
			arrowLeft : '43%',
		},
		{
			parentId  : 'liveCall_liveControl',
			elementId : 'liveCall_share',
			title     : t('title.share_screen'), 
			desc      : t('body.share_screen'),
			arrowLeft : '63%',
		},
		{
			parentId  : 'liveCall_liveControl',
			elementId : 'liveCall_exit',
			title     : t('title.exit_live'), 
			desc      : t('body.exit_live'),
			arrowLeft : '83%',
		},
	];

	useEffect (() => {
		styleElement ();	
		styleParent ();
	}, [step]);

	const handleSkip = () => {
		let element = document.getElementById(walkthroughSteps[step].elementId);
		if (!element) {
			return;
		}
		removeStyle (element);	
		onSkip ();
	};

	const removeStyle = (element) => {
		if (!element) {
			return;
		}
		element.style.position      = 'none';
		element.style.zIndex        = 'inherit';
		element.style.pointerEvents = 'auto';
	};

	const removePreviousStyle = () => {
		if (step === 0) {
			return;
		}
		let element = document.getElementById(walkthroughSteps[step-1].elementId);
		if (!element) {
			return;
		}
		removeStyle (element);
	}; 

	const styleElement = () => {
		let element = document.getElementById(walkthroughSteps[step].elementId);
		if (!element) {
			return;
		}
		element.style.position      = 'relative';
		element.style.zIndex        = 10000;
		element.style.pointerEvents = 'none';
		removePreviousStyle ();
	};

	const styleParent = () => {
		let element = document.getElementById(walkthroughSteps[step].parentId);
		if (!element) {
			return;
		}
		element.style.transform = 'none';
		element.style.zIndex    = 'inherit';
	};
	
	let element = document.getElementById(walkthroughSteps[step].elementId);
	if (!element) {
		return <div></div>;
	}

	return (
		<Grid className = 'walkthrough-backdrop'>
			<Popper 
				open       = { true } 
				anchorEl   = { document.getElementById (walkthroughSteps[step].parentId || walkthroughSteps[step].elementId) } 
				className  = 'walkthrough-popper'
				transition = { true }
				placement  = 'top'
				modifiers  = {{
					offset : {
						enabled : true,
						offset  : langDir === 'ltr' ? '0, 20' : '0 20'
					},
				}}
			>
		 {({ TransitionProps }) => (
					<Grow
						{ ...TransitionProps }
						style = {{ transformOrigin: 'left bottom'}}
					>
						<CallOut className = 'walkthrough-paper' placement = 'bottom-center' left = { walkthroughSteps[step].arrowLeft }>
							<MobileStepper variant = 'dots' steps = { 5 } activeStep = { step }/>
							<Grid className = 'mt-l'>
								<Typography variant = 'subtitle1' align = 'center' className = 'walkthrough-title'>{walkthroughSteps[step].title}</Typography>
								<Typography variant = 'h1' color = 'secondary' align = 'center' className = 'mt-xs'> {walkthroughSteps[step].desc}</Typography>
							</Grid>
							<Grid container className = 'mt-xs'>
								<Grid item xs = { 6 }>
									{step < 4 ? 
										<Button color = 'secondary' size = 'small' className = 'walkthrough-btn skip' onClick = { handleSkip }> Skip </Button> 
										: null}
								</Grid>
								<Grid item xs = { 6 } container justify = 'flex-end'>
									<Button color = 'primary' size = 'small' className = 'walkthrough-btn next' onClick = { step < 4 ? onNext : onSkip }>
										 {step < 4 ? 'Next' : 'Got it'} 
									 </Button>
								</Grid>
							</Grid>
						</CallOut>
					</Grow>
				)}
			</Popper>
		</Grid>
	);

}

export default Walkthrough;