import { useRef, useEffect } from "react";
function Audio(props){

	let { muted = false, stream } = props;
	const audio_ref = useRef(null);

	useEffect(()=>{
		console.log("attaching audio streams of ",stream);
		audio_ref.current.srcObject = stream.stream.mediaStream;
	},[]);

	return(
		<audio
			ref = { audio_ref }
			autoPlay
			muted = { muted }
		/>
	);
}

export default Audio;
