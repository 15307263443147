import React, { useEffect, useState }                           from 'react';
import { useDispatch, useSelector }                             from 'react-redux';
import { useTranslation }                                       from 'react-i18next';
import { toast }                                                from 'react-hot-toast';
import Grid                                                     from '@material-ui/core/Grid';
import Typography                                               from '@material-ui/core/Typography';

import AllEvents                                                from 'components/molecules/AllEvents';
import LandingLeftBar                                           from 'components/molecules/LandingLeftBar';
import LandingTableHeader                                       from 'components/molecules/LandingTableHeader';
import SearchField                                              from 'components/atoms/SearchField';
import { usePageLoader }                                        from 'components/atoms/PageLoader';
import useApis                                                  from 'common/apis';
import { logContainer }                                         from 'libs/logger';
import { addCurrentEvent, addUpcomingEvent, addCompletedEvent } from 'redux/slices/event';
import { clearEventStore }                                      from 'redux/slices/event';

import 'styles/pages/landing.css';

const log = logContainer('pages/Landing');

const i18nNamespaces = ['landing'];

function Landing () {

	const { t }          = useTranslation (i18nNamespaces);
	const dispatch       = useDispatch ();
	const apis           = useApis ();
	const { pageLoader } = usePageLoader ();    
	const eventStore     = useSelector (state => state.event);

	const [searchVal, setSearchVal] = useState ('');
	const [sort, setSort]           = useState ({sortBy : '', order : ''}); // enum : ['time', 'name', 'presenter']  [asc : ascending, desc: descending]

	useEffect (() => {
		pageLoader (true);
		getEventsList ().finally (() => pageLoader (false));
		const interval = setInterval (() => getEventsList(), 7000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	const getSearchedEvent = (events) => {
		let searched = [];
		for (let i = 0; i < events.length; i++)	{
			if (events[i].name.toLowerCase().includes (searchVal)) {
				searched.push (events[i]);
				continue;
			}
			if (events[i].description.toLowerCase().includes (searchVal)) {
				searched.push (events[i]);
				continue;
			}
			if (events[i].presenter.toLowerCase().includes (searchVal)) {
				searched.push (events[i]);
				continue;
			}
		}
		return searched;
	};

	const ongoing   = eventStore.live.id ? searchVal ? getSearchedEvent([eventStore.live]) : [eventStore.live] : [];
	const upcoming  = searchVal ? getSearchedEvent (eventStore.upcoming) : eventStore.upcoming;
	const completed = searchVal ? getSearchedEvent (eventStore.completed) : eventStore.completed;

	const getEventsList = async () => {

		let eventsList;
		try {
			eventsList =  await apis.getEvents ("started,stopped,created");
			log.info ({eventsList}, 'events list get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting events list');
			dispatch (clearEventStore ());
			return;
		}
		let _live      = eventsList.find   (event => event.state === 'started');
		let _completed = eventsList.filter (event => event.state === 'stopped');
		let _upcoming  = eventsList.filter (event => event.state === 'created');

		dispatch (addCurrentEvent (_live ? _live : {}));
		dispatch (addCompletedEvent (_completed));
		dispatch (addUpcomingEvent (_upcoming));
	};

	const handleSearchChange = (event) => {
		const { value } = event.currentTarget;
		setSearchVal (value);
	};

	const onSort = (sortBy, order) => {
		setSort ({ sortBy, order });
	};

	return (
		<Grid className = 'landing page-parent' container>
			<LandingLeftBar />
			<Grid className = 'landing-right-container'>
				<Grid className = 'landing-top-bar' container alignItems = 'center'>
					<Typography variant = 'h5'> {t('body.all_events_list')} </Typography>
				</Grid>
				<Grid className = 'landing-main-container'>
					<Grid>
						<SearchField value = { searchVal } onChange = { handleSearchChange }/>
					</Grid>
					<Grid className = 'mt-xxl'>
						<LandingTableHeader sortBy = { sort.sortBy } order = { sort.order } onSort = { onSort }/>
					</Grid>
					<Grid className = 'mt-xxl'>
						<AllEvents ongoing = { ongoing } upcoming = { upcoming } completed = { completed } search = { searchVal } sortBy = { sort.sortBy } order = { sort.order }/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Landing;
