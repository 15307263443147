import { useState, useEffect }          from 'react';
import { useTranslation }               from 'react-i18next';
import { toast }                        from 'react-hot-toast';
            
import xhr                              from 'libs/xhr';
import { CMMSAddress }                  from 'common/config';
import { isNetworkError, networkError } from 'common/error';

const i18nNamespaces = ['error'];

const useApis = () => {

	const { t } = useTranslation (i18nNamespaces);

	const [errMessage, setErrMessage] = useState ('');

	useEffect(() => {
		/*component to show error message;*/
		if (errMessage) {
			toast.error(errMessage);
			setErrMessage ('');
		}
	}, [errMessage]);

	const apis = {};

	apis.getLiveEvent = async () => {
		try {
			let result =  await xhr.get ('/atom/event/live');
			return result;
		}
		catch (err) {
			if (isNetworkError (err)) {
				setErrMessage (t (networkError));
			}
			throw err;
		}
	};

	apis.getEvents = async (stateQuery) => {
		let eventUrl = `/atom/moderator/event/list?state=${encodeURIComponent(stateQuery)}`;
		try {
			let result =  await xhr.get (eventUrl);
			return result;
		}
		catch (err) {
			if (isNetworkError (err)) {
				setErrMessage (t (networkError));
			}
			throw err;
		}
	};

	apis.getAtomInfo = async () => {
		let result = await xhr.get ('/atom/ping');
		return result;
	};

	apis.verifyPin = async (data, cmmsInfo) => {
		try {
			let path = '/event/verify/apin';
			let address = cmmsInfo ? `${cmmsInfo.proto}://${cmmsInfo.host}:${cmmsInfo.port}/router${path}` : `/atom${path}`;
			let result = await xhr.post(address, data);
			return result;
		}
		catch (err) {
			if (isNetworkError (err)) {
				setErrMessage (t (networkError));
			}
			throw err;
		}
	};

	apis.getEventDetails = async (eventId) => {
		let result = await xhr.get (`/atom/moderator/event/${eventId}`);
		return result;
	};

	apis.getCMMSInfo = async (eventCode) => {
		let result = await xhr.get(`/lb/router/${eventCode}`, {baseURL : CMMSAddress});
		return result;
	};

	apis.getOnlineEventInfo = async({ proto = "https", port = 443, host }, eventCode) => {
		let address = `${proto}://${host}:${port}`;
		let result = await xhr.get(address + `/router/v2/event/${eventCode}`);
		return result;
	};

	apis.getOnlineSessionInfo = async ({ proto = "https", port = 443, host }, eventCode) => {
		let address = `${proto}://${host}:${port}`;
		let result = await xhr.put(address + `/router/v2/event/pa/join/${eventCode}`);
		return result;
	};

	return apis;
};

export default useApis;
