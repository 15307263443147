import React, { useState, useRef, useCallback } from 'react';
import { useTranslation }                       from 'react-i18next';
import { useDispatch }                          from 'react-redux';
import Grid                                     from '@material-ui/core/Grid';
import Button                                   from '@material-ui/core/Button';

import { updateImage }                          from 'redux/slices/login';
import ImgCaptureModal                          from 'components/molecules/ImgCaptureModal';
import ImgCropModal                             from 'components/molecules/ImgCropModal';

const i18nNamespaces = ['profile_photo'];

import 'styles/components/molecules/profilePhoto.css';

function ProfilePhoto ({img, option, onClose, onCapture }) {

	const dispatch  = useDispatch ();
	const { t }     = useTranslation (i18nNamespaces);
	const webcamRef = useRef (null);

	const [croppedImage, setCroppedImage] = useState ('');

	const onSavePhoto = () => {
		dispatch (updateImage (croppedImage));
		onClose ();
	};

	const handleCropImage = (image) => {
		setCroppedImage (image);
	};

	const handleCaptureImage = useCallback (() => {
		const imageSrc = webcamRef.current.getScreenshot();
		onCapture(imageSrc);
	},[webcamRef]);

	const imgCropModal = option === 'crop' && <ImgCropModal img = { img } croppedImage = { handleCropImage }/>;

	const imgCaptureModal = option === 'capture' && <ImgCaptureModal webcamRef = { webcamRef } onClose = { onClose }/>;

	return (
		<Grid className = 'profilePhoto'>
			<Grid container justify = 'center' alignItems = 'center' className = 'profilePhoto-form-container'>
				{ imgCropModal }
				{ imgCaptureModal }
			</Grid>
			<Grid container justify = 'flex-end'>
				<Button variant = 'outlined' color = 'secondary' className = 'ml-xl mr-xl' onClick = { onClose }> {t('button:action.cancel')} </Button>
				<Button variant = 'contained' color = 'primary' size = 'medium' onClick = { option === 'crop' ? onSavePhoto : handleCaptureImage }>
					{option === 'crop' ? t('profile_crop:body.save_crop') : t('profile_capture:body.capture')}
				</Button>
			</Grid>
		</Grid>
	);
}

export default ProfilePhoto;
