import React, { useEffect, useRef, useState } from "react";
import { useSelector }                        from 'react-redux'; 
                    
import { getAudioStreams, getVideoStreams }   from "libs/stella/stellaConnect";
import { getSubscribedStreams }               from 'libs/stella/stellaConnect';
import poster                                 from "assets/images/loading.gif";
import utils                                  from 'common/utils';
import LMUserProfile                          from 'assets/icons/light/userProfile.svg';
import DMUserProfile                          from 'assets/icons/dark/userProfile.svg';
import Audio                                  from './Audio';
import CommentDisplay                         from './CommentDisplay';
import PollDisplay                            from './PollDisplay';
import DisplayPresenterAvatar                 from 'components/molecules/DisplayPresenterAvatar';

import './style.css';

function DisplayVideo ({ muted, theme }) {

	const layoutStore   = useSelector (state => state.layout);
	const userStore     = useSelector (state => state.user);
	const reconnection  = useSelector (state => state.reconnection);
	const commentStore  = useSelector (state => state.comment);
	const pollingStore  = useSelector (state => state.polling);
	const settingsStore = useSelector (state => state.settings);

	const [liveCustom, setLiveCustom ]                        = useState ({screenNo : '', avatarSrc : '', displayName: '', show : false});
	const [liveCustomPresenter, setLiveCustomPresenter ]      = useState ({screenNo : '', show : false});
	const [subscribedAudioStreams, setSubscribedAudioStreams] = useState ([]);
	const [htmlRendering,setHtmlRendering]                    = useState (null);
	
	const { layout, display, streams, audio_streams } = layoutStore;

	let screens = {
		screen1 : useRef(null),
		screen2 : useRef(null),
		screen3 : useRef(null),
		screen4 : useRef(null),
	};

	let approvedComments = commentStore.approved;
	let poll             = pollingStore.poll;
	let pollSettings     = settingsStore.polling;
	let isLandscape      = null;
	
	let audio_screens = {};

	if("display-video-landscape" in display){
		isLandscape = true;
	}
	else if("display-video-portrait" in display){
		isLandscape = false;
	}

	useEffect(() => {
		let subPromises = Object.keys(audio_streams).map((streamType) => {
			return getAudioStreams( streamType, audio_screens);
		});
		Promise.allSettled(subPromises)
			.then((data) => {
				let x = data.filter((e)=> e.status ==="fulfilled").map( e => e.value);
				setSubscribedAudioStreams(x);
			});

		Object.keys(display).forEach((streamType) => {
			let screen = display[streamType];
			if(streamType === "display-video-landscape" || streamType === "display-video-portrait"){
				setHtmlRendering(screen);
			}
			else{
				getVideoStreams(streamType, screens, screen)
					.then((subscribedStream) =>{
						if(subscribedStream && subscribedStream.stream.mediaStream && screens[screen].current !== null) {
							screens[screen].current.srcObject = subscribedStream.stream.mediaStream;
						}
					})
					.catch((err) => {
						console.error(err);
					});
			}
		});
	}, [display, audio_streams]);

	useEffect(() => {
		let streams = getSubscribedStreams();
		if (!Object.keys(streams).length) {
			return;
		}
		let reconnectionStreams = reconnection.streams;
		Object.keys(display).forEach((st) => {
			if (st in streams && streams[st].stream) {
				let screen = display[st];
				if (screen && streams[st].stream.mediaStream) {
					if (screens[screen].current !== null) {
						if(!(st in reconnectionStreams) || !reconnectionStreams[st]){
							screens[screen].current.srcObject = streams[st].stream.mediaStream;
							console.log("Reconnecting media stream for",st);
						}
						else{
							screens[screen].current.srcObject = null;
						}
					}
				}
			}
		});
	}, [reconnection]);

	useEffect (() => {
		let presenterVideo = streams['presenter-video'];
		if (!presenterVideo) {
			setLiveCustomPresenter ({screenNo : '', show : false});
			return;
		}
		let custom = presenterVideo?.custom;
		if (!custom) {
			return;
		}
		let isCameraShared = custom['share_camera'];
		let _screenNo      = display['presenter-video']; 
		if (isCameraShared) {
			setLiveCustomPresenter ({screenNo : _screenNo, show : false});
			return;
		}
		setLiveCustomPresenter ({screenNo : _screenNo, show : true});
	}, [streams]);

	useEffect (() => {
		let participantAudio = streams['participant-audio'];
		if (!participantAudio) {
			setLiveCustom ({screenNo : '', avatarSrc : '', show : false, displayName : ''});
			return;
		}
		let custom = participantAudio?.custom;
		if (!custom) {
			return;
		}
		let isCameraShared = custom['share_camera'];
		let displayName    = custom['displayName'];
		let _screenNo      = display['participant-audio']; 
		if (isCameraShared) {
			setLiveCustom ({screenNo : _screenNo, avatarSrc : '', show : false, displayName});
			return;
		}
		let vcId = custom['vc_id'];
		if (!vcId) {
			return;
		}
		let _avatar   = userStore.avatar[vcId];
		if (!_avatar) {
			setLiveCustom ({screenNo : _screenNo, avatarSrc : '', show : true, displayName});
			return;
		}
		setLiveCustom ({screenNo : _screenNo, displayName, avatarSrc : utils.getImgSrc(_avatar), show : true});
	}, [streams]);

	const video = (screenNo) => {
		if(htmlRendering === screenNo){
			let streamType = null;
			Object.keys(display).forEach((st) => {
				if(display[st] === screenNo){
					streamType = st;
				}
			});

			let stream = streams[streamType];
			let custom = stream?.custom;
			if(custom.polls){
				return (
					<div className = 'video-container poll-container'>
					  <PollDisplay 
							poll         = { poll }
							resultShared = { pollingStore.resultShared && pollSettings.showResultsOn !== 'MOBILE' ? true : false }
						/>
				  </div>
				);
			}
			else if(custom.comments){
				return (
					<div className = 'video-container comment-container'>
						<CommentDisplay
							comments = { approvedComments }
						/>
					</div>
				);
			}
		}
		if (liveCustom.screenNo === screenNo && liveCustom.show) {
			return(
				<div className = 'video-container'>
					{/*
                          No need to use a video tag as display style is set to none
                          Use audio tag instead.
                    */}
					<video
						ref      = { screens[screenNo] }
						poster   = { poster }
						autoPlay = { true }
						height   = "100%"
						width    = "100%"
						muted    = { muted }
						style    = {{display:'none'}}
					>
						<source type="video/mp4" />
					</video>
					<img src = { liveCustom.avatarSrc ? liveCustom.avatarSrc : theme === 'light' ? LMUserProfile : DMUserProfile } className = 'displayVideo-pa-avatar'/>
					<p className='displayVideo-pa-name'> {liveCustom.displayName} </p>
				</div>
			);
		}

		if (liveCustomPresenter.screenNo === screenNo && liveCustomPresenter.show) {
			return(
				<div className = 'video-container'>
					<audio
						ref = { screens[screenNo] }
						autoPlay
						muted = { muted }
						style = {{display:'none'}}
					/>
					<DisplayPresenterAvatar />
				</div>
			);
		}


		return (
			<div className = 'video-container'>
				<video
					ref      = { screens[screenNo] }
					poster   = { poster }
					autoPlay = { true }
					height   = "100%"
					width    = "100%"
					muted    = { muted }
				>
					<source type="video/mp4" />
				</video>
				{liveCustom.screenNo === screenNo ? <p className='displayVideo-pa-name'> {liveCustom.displayName} </p> : null}
			</div>
		);
	};

	const renderLayout = () => {

		switch (layout) {
			case "1_PANEL":
				return (
					<div className = 'displayVideo-container'>
						<div className = 'layout1-screen1'>
							{video ('screen1')}
						</div>
					</div>
				);
			case "2_PANEL":
				return (
					<div className = 'displayVideo-container'>
						<div className = 'layout2-screen1'>
							{video ('screen1')}
						</div>
						<div className = 'layout2-screen2'>
							{video ('screen2')}
						</div>
					</div>
				);
			case "3_PANEL_1":
				return (
					<div className = 'displayVideo-container'>
						<div className = 'layout3_1-screen1'>
							{video ('screen1')}
						</div>
						<div className = 'layout3_1-screen2-screen3'>
							<div className = 'layout3_1-screen2'>
								{video ('screen2')}
							</div>
							<div className = 'layout3_1-screen3'>
								{video ('screen3')}
							</div>
						</div>
					</div>
				);
			case "3_PANEL_2":
				return (
					<div className = 'displayVideo-container'>
						<div className = 'layout3_2-screen1-screen3'>
							<div className = "layout3_2-screen1">
								{video ('screen1')}
							</div>
							<div className = 'layout3_2-screen3'>
								{video ('screen2')}
							</div>
						</div>
						<div className = 'layout3_2-screen2'>
							{video ('screen3')}
						</div>
					</div>
				);
			case "4_PANEL":
				return (
					<div className = 'displayVideo-container'>
						<div className = 'layout4-screen1-screen3-screen4'>
							<div className = 'layout4-screen1'>
								{video ('screen1')}
							</div>
							<div className = 'layout4-screen3-screen4'>
								<div className = 'layout4-screen3'>
									{video ('screen3')}
								</div>
								<div className = 'layout4-screen4'>
									{video ('screen2')}
								</div>
							</div>
						</div>
						<div className = 'layout4-screen2'>
							{video ('screen4')}
						</div>
					</div>
				);
			default:
				return (
					<div className = 'displayVideo-container'>
						<div className = 'layout1-screen1'>
							<h2> No video streams available</h2>
						</div>
					</div>
				);
		}
	};

	const renderPresenterAudio = () => {
		return subscribedAudioStreams.map((stream, index)=>{
			return (
				<Audio
					key    = { index }
					muted  = { muted }
					stream = { stream }
				/>
			);
		});
	};

	return (
		<div className = 'displayVideo'>
			{renderLayout ()}
			{renderPresenterAudio ()}
		</div>
	);
}

export default DisplayVideo;
