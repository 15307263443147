import { combineReducers } from '@reduxjs/toolkit';

import login               from 'redux/slices/login';
import event               from 'redux/slices/event';
import comment             from 'redux/slices/comment';
import user                from 'redux/slices/user';
import settings            from 'redux/slices/settings';
import polling             from 'redux/slices/polling';
import mic                 from 'redux/slices/mic';
import layout              from 'redux/slices/layout';
import common              from 'redux/slices/common';
import reconnection        from 'redux/slices/reconnection';

const rootReducer = combineReducers ({
	login    : login,
	event    : event,
	user     : user,
	comment  : comment,
	settings : settings,
	polling  : polling,
	mic      : mic,
	layout   : layout,
	common   : common,
	reconnection : reconnection,
});

export default rootReducer;
