import { configureStore } from '@reduxjs/toolkit';
import { createLogger }   from 'redux-logger';

import rootReducer        from 'redux/reducers';

const logger = createLogger({
	colors : {
		title     : false,
		prevState : false,
		action    : false,
		nextState : false,
		error     : false,

		/* title     : () => '\u001b[34m',
		 * prevState : () => '\u001b[33m',
		 * action    : () => '\u001b[37m',
		 * nextState : () => '\u001b[32m',
		 * error     : () => '\u001b[31m', */
	}
});

const store = configureStore ({
	reducer    : rootReducer,
	middleware : getDefaultMiddleware => getDefaultMiddleware().concat(logger),
	devTools   : process.env.NODE_ENV !== 'production',
});

if (process.env.NODE_ENV === 'development' && module.hot) {
	module.hot.accept ('./reducers', () => {
		store.replaceReducer (rootReducer);
	});
}

export default store;
