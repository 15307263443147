import React              from 'react';
import { useTranslation } from 'react-i18next';
import Grid               from '@material-ui/core/Grid';
import Typography         from '@material-ui/core/Typography';

import IconButton         from 'components/atoms/IconButton';

import 'styles/components/molecules/landingTableHeader.css';

const i18nNamespaces = ["landing"];

function LandingTableHeader ({ sortBy, order, onSort }) {

	const { t } = useTranslation (i18nNamespaces);

	const handleSorting = (type) => {
		/*If no sortting applied set to descending order */
		if (!sortBy) {
			onSort (type, 'desc');
			return;
		}
		/*if order is -1 and same sort type, remove sorting */
		if (sortBy === type && order === 'asc') {
			onSort ('', '');
			return;
		}
		/* if sort by is already decending, change it to ascending*/
		if (sortBy === type && order === 'desc') {
			onSort (type, 'asc');
			return;
		}

		/*Sort by type and descending order */
		onSort (type, 'desc');
	};

	const getIcon = (type) => {
		if (!sortBy || sortBy !== type) {
			return 'Sort';
		}
		if (order === 'asc') {
			return 'SortAsc';
		}
		return 'SortDesc';
	};

	return (
		<Grid className = 'landingTableHeader-container' container alignItems = 'center' justify = 'center'>
			<Grid item xs = { 12 } sm = { 12 } md = { 3 } container alignItems = 'center'>	
				<Typography variant = 'body2' color = 'textSecondary'> {t('body.event_name_desc')} </Typography>
				<IconButton icon = { getIcon ('name') } size = { 21 } className = 'ml-s mr-s' onClick = { () => handleSorting ('name') }/>
			</Grid>
			<Grid item xs = { 12 } sm = { 12 } md = { 3 } container alignItems = 'center'>	
				<Typography variant = 'body2' color = 'textSecondary'> {t('body.presenter')} </Typography>
				<IconButton icon = { getIcon('presenter') } size = { 21 } className = 'ml-s mr-s' onClick = { () => handleSorting ('presenter') } />
			</Grid>
			<Grid item xs = { 12 } sm = { 12 } md = { 3 } container alignItems = 'center'>	
				<Typography variant = 'body2' color = 'textSecondary'> {t('body.date_time')} </Typography>
				<IconButton icon = { getIcon('scheduledDate') } size = { 21 } className = 'ml-s mr-s' onClick = { () => handleSorting ('scheduledDate') }/>
			</Grid>
			<Grid item xs = { 12 } sm = { 12 } md = { 2 }>	
				<Typography variant = 'body2' color = 'textSecondary'> {t('body.status')} </Typography>
			</Grid>
			<Grid item xs = { 12 } sm = { 12 } md = { 1 }>	
			</Grid>
		</Grid>
	);
}

export default LandingTableHeader;
