import React, { forwardRef } from 'react';
import PropTypes             from 'prop-types';
import Paper                 from '@material-ui/core/Paper';

import 'styles/components/atoms/callOut.css';

const CallOut = forwardRef((props, ref) => { 

	const { placement, className, left } = props;

	let el = document.getElementsByClassName(`callOut`);

	if (el.length && left) {
		el[0].style.setProperty('--arrow-left', left);
	}

	return (
		<Paper innerRef={ ref } { ...props } className = { `callOut ${placement} ${className}` }/>
	);}
);

CallOut.propTypes = {
	placement : PropTypes.oneOf(['bottom-start', 'bottom-center']) /*add aq to req*/
};

export default CallOut;
