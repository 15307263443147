import React            from 'react';
import MuiButton        from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

function Button ({loading, innerRef, children, disabled, ...props}) {

	return (
		<MuiButton { ...props } disabled = { disabled || loading } ref = { innerRef }>
			{loading ? <CircularProgress color = 'primary' size = { 20 }/> : children}
		</MuiButton>
	);
}

export default Button;
