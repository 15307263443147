import React, { useEffect }            from 'react';
import { useTranslation }              from 'react-i18next';
import { useDispatch }                 from 'react-redux';
import Grid                            from '@material-ui/core/Grid';
import Typography                      from '@material-ui/core/Typography';
import Divider                         from '@material-ui/core/Divider';
import Slide                           from '@material-ui/core/Slide';

import IconButton                      from 'components/atoms/IconButton'; 
import Button                          from 'components/atoms/Button'; 
import { closeNotify }                 from 'redux/slices/common';

import 'styles/components/atoms/notification.css';

function Notification ({open, title, desc, onView}) {

	const { t, i18n } = useTranslation ();
	const dispatch    = useDispatch();

	const dir = i18n.dir();

	const onClose = () => {
		dispatch (closeNotify ());
	};

	useEffect (() => {
		setTimeout (onClose, 5000);
	}, []);

	return (
		<Slide direction = { dir === 'ltr' ? 'left' : 'right' } in = { open } mountOnEnter unmountOnExit>
			<Grid className = 'notification'>
				<Grid container alignItems = 'center' className = 'notification-header'>
					<Grid item xs = { 8 } container alignItems = 'center'>
						<IconButton 
							icon  = 'Notification'
							size  = { 25 }
							color = 'secondary'
						/>
						<Typography variant = 'h3' className = 'ml-m mr-m'> {t('modal:title.notification')} </Typography>
					</Grid>
					<Grid item xs = { 4 } container justify = 'flex-end'>
						<IconButton 
							icon    = 'CloseSmall'
							size    = { 25 }
							color   = 'secondary'
							onClick = { onClose }
						/>
					</Grid>
				</Grid>
				<Divider />
				<Grid className = 'p-xl'>
					<Typography variant = 'subtitle1'> {title} </Typography>
					<Grid container className = 'mt-xs'>
						<Grid item xs = { 8 }>
							<Typography variant = 'h2' color = 'secondary'> {desc} </Typography>
						</Grid>
						<Grid item xs = { 4 } container justify = 'flex-end'>
							<Button variant = 'contained' color = 'primary' className = 'notification-view-btn' onClick = { onView }> {t('button:action.view')} </Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Slide>
	);
}

export default Notification;
