/*This redux store contains information regarding mic request feature AV*/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	state            : 'call-idle', /* ENUM: call-idle, call-in-queue, call-approved, call-initializing, call-active */
	isActive         : false,
	isSending        : false,
	requestMeta      : {
		rtc_vcid : null,
	},
	hasSpoken        : false,
	position         : null,        /*initially pos will be null*/
	permittedTs      : Date.now(),
	autoMicApprove   : false,       /* Auto approve config state global */
	atomMicConf      : {},          /* Request specific auto approve config */
	settings         : {},
};

const slice = createSlice ({
	name : 'mic',
	initialState,
	reducers : {
		initMicResource (state, action) {
			let { data } = action.payload;
			let callState;

			switch (data.info.state) {
				case 'in-queue' :
					callState = 'call-in-queue';
					break;
				case 'call-active':
				case 'call-initializing':
				case 'call-idle':
				default :
					callState = 'call-idle';
			}

			state.state          = callState;
			state.isActive       = data.info.is_enabled;
			state.hasSpoken      = false;
			state.autoMicApprove = data.info.autoMic || false;
			state.settings       = data.info.settings;
		},

		sending (state, action) {
			state.isSending = action.payload ?? true;
		},

		sentRequest (state, action) {
			let { rtc_vcid }           = action.payload;
			state.requestMeta.rtc_vcid = rtc_vcid;
			state.state                = 'call-in-queue';
			state.isSending            = false;
		},

		approveRequest (state) {
			state.state = 'call-approved';
		},

		processingRequest (state) {
			state.state     = 'call-initializing';
		},

		updateRequestPosition (state, action) {
			state.position = action.payload;
		},

		endRequest (state) {
			state.state     = 'call-in-queue';
			state.isSending = false;
		},

		clearRequest (state) {
			state.state     = 'call-idle';
			state.position  = null;
			state.isSending = false;
		},

		permitRequest (state, action) {
			state.state       = 'call-active';
			state.autoMicConf = action.payload.autoMicConf;
			state.hasSpoken   = false;
		},

		startedSpeaking (state) {
			state.hasSpoken = true;
		},

		clearMicStore () {
			return initialState;
		}
	}
});

/* Selectors */
export const isMicPendingSelector = function (store) {
	return ['call-in-queue', 'call-approved'].indexOf(store?.state) >= 0;
};
export const isMicQueuedSelector = function (store) {
	return store?.state === 'call-in-queue';
};

export const isMicApprovedSelector = function (store) {
	return store?.state === 'call-approved';
};

export const isMicIdleSelector = function (store) {
	return store?.state === 'call-idle';
};

export const isMicActiveSelector = function (store) {
	return store?.state === 'call-active';
};

export const isMicProcessingSelector = function (store) {
	return store?.state === 'call-initializing';
};

export const {
	sending,
	sentRequest,
	endRequest,
	clearRequest,
	processingRequest,
	initMicResource,
	permitRequest,
	approveRequest,
	updateRequestPosition,
	clearMicStore,
} = slice.actions;

export default slice.reducer;
