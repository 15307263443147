import React, { useState }  from 'react';
import { useHistory }       from 'react-router-dom';
import { useSelector }      from 'react-redux';
import { useTranslation }   from 'react-i18next';
import Grid                 from '@material-ui/core/Grid';

import PinInput             from 'components/molecules/PinInput';
import Popups               from 'components/atoms/Popups';
import Button               from 'components/atoms/Button';
import useApis              from 'common/apis';
import { logContainer }     from 'libs/logger';
import path                 from 'navigations/path';

import 'styles/components/molecules/eventPin.css';

const log = logContainer ('pages/EventPin');

const i18nNamespaces = ["pin_code"];

function PinModal ({ open, onClose, cmmsInfo }) {

	const { t }        = useTranslation (i18nNamespaces);
	const apis         = useApis ();
	const redirect     = useHistory ();
	const eventStore   = useSelector (state => state.event);

	const pinLength    = eventStore.live.attendeePin?.length;

	const [pin, setPin]         = useState ('');
	const [error, setError]     = useState ('');
	const [loading, setLoading] = useState (false);

	const onPinSubmit = async () => {
		let data = {
			eventId     : cmmsInfo ? eventStore.live.onlineId : eventStore.live.id,
			attendeePin : pin,
		};
		setLoading (true);
		try {
			await apis.verifyPin (data, cmmsInfo);
			log.info ('pin verified successfully');
			setLoading (false);
			redirect.push (path.connect);
		}
		catch (err) {
			log.error ({err}, 'error verifying attendee pin');
			setLoading (false);
			if (err.response && err.response.data.status_code === 400)
				setError (t('pin_code:error.invalid_pin'));
			return;
		}
	};

	const handlePinChange = (value) => {
		setPin (value);
		setError ('');
	};

	return (
		<Popups open = { open } title = { t('modal:title.enter_event_pin') }>
			<Grid container justify = 'center' alignItems = 'center'>
			 <PinInput
					length    = { pinLength }
					onChange  = { handlePinChange }
					error     = { error }
					inputType = 'num'
				/>
			 </Grid>
			<Grid container alignItems = 'center' className = 'mt-xxl'>
				<Grid item xs = { 6 }>
					<Button variant = 'outlined' color = 'secondary' onClick = { onClose }> {t('button:action.cancel')} </Button>
				</Grid>
				<Grid item xs = { 6 } container justify = 'flex-end'>
					<Button variant = 'contained' color = 'primary' loading = { loading } disabled = { pin.length !== pinLength } onClick = { onPinSubmit }> {t('button:action.enter_event')} </Button>
				</Grid>
			</Grid>
		</Popups>
	);
}

export default PinModal;
