import React, { useRef, useState } from 'react';
import { useTranslation }          from 'react-i18next';
import Grid                        from '@material-ui/core/Grid';
import Typography                  from '@material-ui/core/Typography';

import Dropdown                    from 'components/atoms/Dropdown';
import IconButton                  from 'components/atoms/IconButton';

import 'styles/components/molecules/language.css';

const i18nNamespaces = ["language", 'locale'];

function Language ({ direction }) {

	const anchorRef   = useRef (null);

	const { t, i18n } = useTranslation (i18nNamespaces);
	const { store }   = i18n;
	const lang        = i18n.getCurrentLocale ().toLowerCase().replace('-', '_');

	const [open, setOpen] = useState (false);

	const onChangeLanguage = (langCode) => {
		i18n.changeLanguage (langCode);
		handleOpen ();
	};

	const handleOpen = () => {
		setOpen (!open);
	};

	let langOption = Object.keys (store.data).map((langCode) => {
		let _langCode = langCode.toLowerCase().replace('-', '_');
		return {
			label : `${t(`locale:loc_keys.${_langCode}`)} (${store.data.en.locale.loc_keys[_langCode]})`,
			id    : langCode,
		};
	});

	const langMenu = 			
		<Dropdown 
			anchorRef   = { anchorRef }
			options     = { langOption }
			handleClose = { handleOpen }
			open        = { open }
			onClick     = { onChangeLanguage }
			direction   = { direction }
		/>;

	return (
		<Grid container className = 'language-container'>
			<Typography variant = 'body2' className = 'language-title' color = 'textSecondary'>{t('login:input.language_header')}:</Typography>
			<Typography variant = 'h1' className = 'language-name ml-m mr-xs' onClick = { handleOpen }> 
				{ `${t(`locale:loc_keys.${lang}`)} (${store.data.en.locale.loc_keys[lang]})`} 
			</Typography>
			<IconButton 
				icon      = { direction === 'right' ? 'Right' : 'Down' }
				color     = 'secondary'
				className = 'language-down-icon'
				size      = { 21 }
				innerRef  = { anchorRef }
				onClick   = { handleOpen }
			/>
			{langMenu}
		</Grid>
	);
}

export default  Language;
