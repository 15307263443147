import React, { useState }          from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation }           from 'react-i18next';
import { useTheme }                 from '@material-ui/core/styles';
import Grid                         from '@material-ui/core/Grid';
import Avatar                       from '@material-ui/core/Avatar';

import { updateImage }              from 'redux/slices/login';
import IconButton                   from 'components/atoms/IconButton';
import Popups                       from 'components/atoms/Popups';
import RemovePhoto                  from 'components/molecules/RemovePhoto';
import UploadPhoto                  from 'components/molecules/UploadPhoto';
import ProfilePhoto                 from 'components/molecules/ProfilePhoto';
import TakePhoto                    from 'components/molecules/TakePhoto';
import LMUserProfile                from 'assets/icons/light/userProfile.svg';
import DMUserProfile                from 'assets/icons/dark/userProfile.svg';

import 'styles/components/molecules/profile.css';

const i18nNamespaces = ['profile_photo'];

function Profile () {

	const theme      = useTheme ().palette.type;
	const { t }      = useTranslation (i18nNamespaces);
	const dispatch   = useDispatch ();
	const loginStore = useSelector (state => state.login);    

	const { image } = loginStore;

	const [profileOpt, setProfileOpt]     = useState (false);
	const [profilePhoto, setProfilePhoto] = useState ({open : false, img : '', option : ''});

	const handleProfile = () => {
		setProfileOpt (!profileOpt);
	};

	const onRemove = () => {
		dispatch (updateImage (''));
		setProfileOpt (false);
	};

	const onUploadPhoto = (image) => {
		setProfileOpt (false);
		setProfilePhoto ({open : true, img : image, option : 'crop'});
	};

	const onCapture = () => {
		setProfileOpt (false);
		setProfilePhoto ({open : true, option : 'capture'});
	};

	const closeProfilePhoto = () => {
		setProfilePhoto ({open : false, img : '', option : ''});
	};

	const camera = <IconButton 
		className = 'profile-camera-icon-container' 
		size      = { 35 } 
		onClick   = { handleProfile } 
		color     = 'primary' 
		icon      = 'Camera'
	/>;

	return (
		<Grid className = 'profile'>
			<Grid className = 'profile-container'>
				<Avatar src = { image ? image :  theme === 'light' ? LMUserProfile : DMUserProfile } className ='profile-avatar'/>
				{camera}
			</Grid>
			<Popups title = { t('header.profile_photo') } open = { profileOpt } titleAlign = 'left' onClose = { handleProfile } closeButton = { true }>
				<Grid container spacing = { 2 } className = 'profile-option-main'>
					<Grid item xs = { 4 } sm = { 4 } md = { 4 } lg = { 4 } xl = { 4 }>
						<RemovePhoto
							onRemovePhoto = { onRemove }
							onClose       = { handleProfile }
						/>
					</Grid>
					<Grid item xs = { 4 } sm = { 4 } md = { 4 } lg = { 4 } xl = { 4 }>
						<UploadPhoto
							onClose       = { handleProfile }
							onUploadPhoto = { onUploadPhoto }
						/>
					</Grid>
					<Grid item xs = { 4 } sm = { 4 } md = { 4 } lg = { 4 } xl = { 4 }>
						<TakePhoto
							onClose   = { handleProfile }
							onCapture = { onCapture }
						/>
					</Grid>
				</Grid>
			</Popups>
			<Popups open = { profilePhoto.open } title = { profilePhoto.option === 'crop' ? t('profile_crop:modal.crop_image') : t('profile_capture:modal.capture_image') } titleAlign = 'center'>
				<ProfilePhoto img = { profilePhoto.img } option = { profilePhoto.option } onClose = { closeProfilePhoto } onCapture = { onUploadPhoto }/>
			</Popups>
		</Grid>
	);
}

export default Profile;
