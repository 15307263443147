import React              from "react";
import { useTranslation } from 'react-i18next';
import PropTypes          from 'prop-types';
import Popper             from "@material-ui/core/Popper";
import Divider            from "@material-ui/core/Divider";
import Avatar             from "@material-ui/core/Avatar";
import AvatarGroup        from "@material-ui/lab/AvatarGroup";
import Typography         from "@material-ui/core/Typography";
import Grid               from "@material-ui/core/Grid";
import Grow               from '@material-ui/core/Grow';
import { useTheme }       from '@material-ui/core/styles';
import ClickAwayListener  from '@material-ui/core/ClickAwayListener';

import CallOut            from 'components/atoms/CallOut';
import LMUserProfile      from 'assets/icons/light/userProfile.svg';
import LMParticipants     from 'assets/icons/light/participants.svg';
import DMParticipants     from 'assets/icons/dark/participants.svg';
import DMUserProfile      from 'assets/icons/dark/userProfile.svg';

import 'styles/components/molecules/eventInfoModal.css';

function EventInfoModal ({ open, handleClose, eventInfo, innerRef, participant}) {

	const { t, i18n }  = useTranslation ();
	const theme        = useTheme().palette.type;

	const langDir = i18n.dir ();

	let presenterText, presenterCount = eventInfo.presenterDetails?.length;

	if (presenterCount === 1) {
		presenterText = t("modal:body.by", { presenter : eventInfo.presenter });
	} else if (presenterCount === 2) {
		presenterText = t("modal:body.by_duo", { presenter : eventInfo.presenterDetails[0].name, coPresenter : eventInfo.presenterDetails[1].name });
	} else {
		presenterText = t("modal:body.by_multiple", { presenter : eventInfo.presenterDetails[0].name, count : eventInfo.presenterDetails.length - 1 });
	}

	return (
		<Popper 
			open       = { open } 
			anchorEl   = { innerRef.current } 
			className  = 'eventInfoModal'
			transition = { true }
			placement  = 'top-start'
			modifiers  = {{
				offset : {
					enabled : true,
					offset  : langDir === 'ltr' ? '-20, 20' : '20 20'
				},
			}}
		>
			 {({ TransitionProps }) => (
				<ClickAwayListener onClickAway = { handleClose }>
					<Grow
						{ ...TransitionProps }
						style = {{ transformOrigin: 'left bottom'}}
					>
						<CallOut placement = 'bottom-start'>
							<Grid className = 'p-xxl pb-xl'>
								<Typography variant = 'h3'> {eventInfo.name} </Typography>
								<Typography variant = 'h2' color = 'secondary' className = 'mt-xs'> {eventInfo.description} </Typography>
							</Grid>
							<Divider />
							<Grid container>
								<Grid className = 'p-xxl eventInfoModal-bottom-left' xs = { 6 } item container wrap ='nowrap'>
									<Grid>
										<Avatar className = 'eventCard-presenter-avatar' src = { theme === 'light' ? LMParticipants : DMParticipants }/>
									</Grid>
									<Grid className = 'pl-m pr-m'>
										<Typography variant = 'subtitle1'> {participant} </Typography>
										<Typography variant = 'body2' color = 'secondary' className = 'mt-xs eventInfoModal-presenter'> {t('in_event:body.live_participant')} </Typography>
									</Grid>
								</Grid>
								<Grid  className = 'p-xxl eventInfoModal-bottom-right' item xs = { 6 } container>
									<Grid className = 'pb-xs'>
										<AvatarGroup max = { 3 }>
											{eventInfo.presenterDetails.map ((presenter, i) => (
												<Avatar 
													src       = { presenter.url ? presenter.url : theme === 'light' ? LMUserProfile : DMUserProfile } 
													key       = { i } 
													className = 'eventCard-presenter-avatar'
												/>
											))}
										</AvatarGroup>
									</Grid>
									<Grid className = 'pl-m pr-m'>
										<Typography variant = 'subtitle1'> {presenterText}</Typography>
										<Typography variant = 'body2' color = 'secondary' className = 'mt-xs eventInfoModal-presenter'> {t('landing:body.presenter')} </Typography>
									</Grid>
								</Grid>
							</Grid>
						</CallOut>
					</Grow>
				</ClickAwayListener>
			)}
		</Popper>
	);
}

EventInfoModal.propTypes = {
	handleClose : PropTypes.func.isRequired,
	open        : PropTypes.bool.isRequired,
};

export default EventInfoModal;
