import React              from 'react';
import { useTranslation } from 'react-i18next';
import Grid               from '@material-ui/core/Grid';
import Dialog             from '@material-ui/core/Dialog';
import Typography         from '@material-ui/core/Typography';

import CircularProgress   from 'components/atoms/CircularProgress';
import Button             from 'components/atoms/Button';

import 'styles/components/molecules/speakRequestModal.css';

function SpeakRequestModal ({ open, title, desc, onClose, onCancelRequest, approved}) {

	const { t } =  useTranslation ();

	return (
		<Dialog open = { open } PaperProps = {{className : 'speakRequestModal'}}>
			<Grid container justify = 'center' className = { `speakRequestModal-progress-container ${approved ? 'approved' : 'not-approved'}` }>
				<CircularProgress
					value     = { approved ? 100 : 40 }
					maxValue  = { 100 }
					minValue  = { 0 }
					hideLabel = { true }
					checked   = { approved }
				/>
			</Grid>
			<Grid>
				<Typography variant = 'h4' align = 'center'> {title} </Typography>
				<Typography variant = 'h2' align = 'center' color = 'secondary' className = 'mt-m'> {desc}</Typography>
			</Grid>
			<Grid className = 'mt-xxl' container justify = 'center'>
				<Grid item xs = { 6 } className = 'speakRequestModal-btn-cancel'>
					<Button variant = 'outlined' color = 'secondary' fullWidth onClick = { onCancelRequest }> {t('button:action.cancel_request')} </Button>
				</Grid>
				<Grid item xs = { 6 } className = 'speakRequestModal-btn-back'>
					<Button variant = 'outlined' color = 'primary' fullWidth onClick = { () => onClose () }> {t('button:action.back_to_event')} </Button>
				</Grid>
			</Grid>
		</Dialog>        
	);
}

export default SpeakRequestModal;