import React, { useState, useEffect }             from 'react';
import { useSelector, useDispatch , shallowEqual} from 'react-redux';
import { useHistory }                             from 'react-router-dom';
import { useTranslation }                         from 'react-i18next';
import Grid                                       from '@material-ui/core/Grid';
import Dialog                                     from '@material-ui/core/Dialog';
import Typography                                 from '@material-ui/core/Typography';
import Button                                     from '@material-ui/core/Button';
import Link                                       from '@material-ui/core/Link';

import { clearRequest }                           from 'redux/slices/mic';
import { logContainer }                           from 'libs/logger';
import { sendInfo }                               from 'libs/socket'; 
import webrtcController                           from 'libs/webrtc/webrtcController';
import useRequest                                 from 'common/request';
import CircularProgress                           from 'components/atoms/CircularProgress';
import path                                       from 'navigations/path';

import 'styles/components/molecules/goLiveApprovedModal.css';

const log = logContainer ('pages/EventLanding');

const i18nNamespaces = ["go_live"];

function GoLiveApproved ({open, onClose, onReqTimeout }) {

	const redirect    = useHistory ();
	const dispatch    = useDispatch ();
	const request     = useRequest ();
	const { t }       = useTranslation (i18nNamespaces);

	const mic  = useSelector (state => state.mic, shallowEqual);
	const vcId = useSelector (state => state.user?.currentUser?.vcId);

	const timer =  mic?.autoMicConf?.idle_time_sec;
	const isAutoMic = mic.autoMicConf?.is_enabled;

	const [progress, setProgress] = useState (timer);

	const onCancel = async () => {
		let payload = { target : vcId };
		try {
			await request.endSpeakRequest (payload);
		}
		catch (err) {
			log.error ({err}, 'error cancel speak request');
		}
		dispatch(clearRequest());
		onClose ();
	};

	const handleStartTalking = () => {
		if (mic.state === 'call-idle') {
			return;
		}
		sendInfo('atom', 'pa-is-speaking');
		webrtcController.toggleAudio(true);
		redirect.push (path.liveCall);
	};

	useEffect (() => {
		if (!isAutoMic) {
			return;
		}
		
		if (progress === 0) {
			return;
		}

		let timerProgress = setInterval(() => {
			setProgress((prevProgress) => (prevProgress <=0 ? timer : prevProgress - 1));
		}, 1000);

		return () => {
			clearInterval(timerProgress);
		};
	}, []);

	useEffect (() => {
		if (progress === 0) {
			onReqTimeout ();
		}
	}, [progress]);

	return (
		<Dialog open = { open } PaperProps = {{className : 'speakRequestModal'}}>
			<Grid>
				<Typography variant = 'h4' align = 'center'> {t('body.going_live')} </Typography>
				{isAutoMic ? <Typography variant = 'h2' align = 'center' color = 'secondary' className = 'mt-m'> {t('body.going_live_subtext')} </Typography> : null}
			</Grid>
			<Grid container justify = 'center' alignItems = 'center'>
				<Grid className = 'goLiveApproved-progress-container'>
					{isAutoMic ? 
						<CircularProgress 
							value     = { progress }
							maxValue  = { timer }
							minValue  = { 0 }
						/>
						: 
						<CircularProgress 
							value     = { 100 }
							maxValue  = { 100 }
							checked   = { true }
							hideLabel = { true }
						/>}
				</Grid> 
			</Grid>
			<Grid>
				<Button variant = 'outlined' color = 'primary' fullWidth onClick = { handleStartTalking } className = 'goLiveApproved-start-btn'> 
					{t('button:action.start_talking')} 
				</Button>
			</Grid>
			<Grid container justify = 'center' className = 'mt-xl'>
				<Link color = 'secondary' onClick = { onCancel } underline = 'always' component = 'button'> {t('button:action.cancel')} </Link>
			</Grid>
		 </Dialog>
	);
}	

export default GoLiveApproved;
