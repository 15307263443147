import React               from 'react';
import { useTranslation }  from 'react-i18next';
import PropTypes           from 'prop-types';
import Webcam              from 'react-webcam';
import { toast }           from 'react-hot-toast';
import Grid                from '@material-ui/core/Grid';
        
import { useAlert }        from 'components/atoms/Alert';

import 'styles/components/molecules/imgCaptureModal.css'; 

function ImgCaptureModal ({ webcamRef, onClose }) {

	const { t } = useTranslation ();

	const { okAlert }   = useAlert ();

	const videoConstraints = {
		width      : 1920,
		height     : 1080,
		facingMode : "user"
	};

	const onError = (err) => {
		onClose();
		switch (err.name) {
			case 'NotAllowedError':
				okAlert ({
					title      : t('alert:title.camera_perm'),
					message    : t('alert:desc.camera_perm'),
					confirmBtn : 'OK', 
				});
				break;
			default:
				toast.error (err);
		}
	};

	return (
		<Grid className = 'imgCaptureModal'>
			<Grid className = "imgCaptureModal-video-container">
				<Webcam
					height            = '100%'
					width             = '100%'
					screenshotFormat  = "image/jpeg"
					onUserMediaError  = { onError }
					audio             = { false }
					ref               = { webcamRef }
					videoConstraints  = { videoConstraints }
					mirrored          = { true }
					screenshotQuality = { 1 }
				/>
			</Grid>
		</Grid>
	);
}

ImgCaptureModal.propTypes = {
	webcamRef : PropTypes.object.isRequired,
};

export default ImgCaptureModal;
