import React                        from 'react';
import { useDispatch }              from 'react-redux';
import { useHistory }               from 'react-router-dom';
import { useTheme }                 from '@material-ui/core/styles';
import Grid                         from '@material-ui/core/Grid';

import { logout }                   from 'redux/slices/login';
import path                         from 'navigations/path';
import localStore                   from 'libs/localStore';
import { storageKey }               from 'common/config';
import IconButton                   from 'components/atoms/IconButton';
import Settings                     from 'components/molecules/Settings';
import LMBiampLogo                  from 'assets/icons/light/biamp-logo.png';
import DMBiampLogo                  from 'assets/icons/dark/biamp-logo.png';

import 'styles/components/molecules/landingLeftBar.css';

function LandingLeftBar () {

	const redirect    = useHistory ();
	const theme       = useTheme ().palette.type;
	const dispatch    = useDispatch ();

	const logo = theme === 'light' ? LMBiampLogo : DMBiampLogo;

	const onLogout = () => {
		dispatch(logout ());

		/*Clear localstorage on logout except theme*/
		localStore.removeMultiple ([storageKey.profile, storageKey.lts, storageKey.onboarded]);
		redirect.push (path.login);
	};

	return (
		<Grid className = 'landingLeftBar' container direction = 'column'>
			<Grid item xs = { 2 }>
				<img src = { logo } width = { 70 } height = { 27 }/>
			</Grid>
			<Grid item xs = { 10 } container justify = 'center' alignItems = 'flex-end' className = 'landingLeftBar-bottom-container'>
				<Grid>
					<Settings />
					<IconButton tooltip = 'Logout' placement = 'right' icon = 'Logout' color = 'inherit' size = { 40 } onClick = { onLogout }/>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default LandingLeftBar;
