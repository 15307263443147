/*This redux store contains information regarding display layouts*/

import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	layout  : 'NO_PANEL',
	streams : {},
	display : {},
	audio_streams: {},
};

const slice = createSlice({
	name: 'layouts',
	initialState,
	reducers: {
		initLayoutResource(state, action) {
			/*init layout*/
			state.layout  = action.payload.layout;
			state.streams = action.payload.streams;
			state.display = action.payload.display;
			state.audio_streams = action.payload.audio_streams;
		},

		updateLayout(state, action) {
			state.layout  = action.payload.layout;
			state.streams = action.payload.streams;
			state.display = action.payload.display;
			state.audio_streams = action.payload.audio_streams;
		},

		resetLayoutStore () {
			return initialState;
		}
	}
});

export const {
	initLayoutResource,
	updateLayout,
	resetLayoutStore,
} = slice.actions;

export default slice.reducer;
