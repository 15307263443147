import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	currentUser : {
		displayName : '',
		id          : '',
		vcId        : '',
		ssId        : '', 
	},
	participant : {},
	moderator   : {},
	avatar      : {},
};

const slice = createSlice ({
	name : 'user',
	initialState,
	reducers : {
		addCurrentUser (state, action) {
			let currentUser = state.currentUser;
			currentUser.displayName = action.payload?.displayName;
			currentUser.id          = action.payload?.id;
			currentUser.vcId        = action.payload?.vc_id;
			currentUser.ssId        = action.payload?.ss_id;
		},

		initAllUser (state, action) {
			let allUsers = action.payload;
			if (!Array.isArray (allUsers) || !allUsers.length) {
				return;
			}
			allUsers.forEach(user => {
				let entityType = user.entityType;
				if (entityType !== 'participant' && entityType !== 'moderator') {
					return;
				}
				if (!state[entityType][user.vc_id]) {
					state[entityType][user.vc_id] = {
						displayName : user.displayName,
						id : user.id,
						vcId : user.vc_id,
						ssId : user.ss_id,
					};
				}
			});		
		},

		removeAttendee (state, action) {
			let entityType = action.payload.entityType;
			let vcId       = action.payload.vc_id;
			if (state[entityType])
				delete state[entityType][vcId];
		},

		updateAvatar (state, action) {
			let avatars = action.payload;
			if (!avatars) {
				return;
			}

			state.avatar = {...state.avatar, ...avatars};
		},

		initMicRequestAvatar (state, action) {
			/*TODO
			 *Needs to handle avatars for mic requests
			 * */
			if (!action.payload) {
				return;
			}
		},

		clearUserStore () {
			return initialState;
		}
	}
});

export const { 
	addCurrentUser,
	initAllUser,
	removeAttendee,
	updateAvatar,
	clearUserStore,
} = slice.actions;

export default slice.reducer;
